import { CenterTypeMember } from './center-type-member.model';

// import { LogicObject } from './logic-object.model';

export class TypeTreeRelation {

  // typeGuidId: string;
  typeTreeRelationGuidId: string;
  name: string;
  isRootRelation: boolean;
  parentTypeGuidId: string;
  childTypeGuidId: string;
  collectionRelation: boolean;
  relationMembers: CenterTypeMember[];
  logicTypeTreeRelationUi: any;
  systemOnly: boolean;

  // helper members for UI components
  $parentObject?: any; // LogicObject;
  $childGuidId?: string;
  $childObject?: any; // LogicObject;

  constructor(item?: Partial<TypeTreeRelation>) {
    Object.assign(this, item);
  }

}
