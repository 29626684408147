import { GuidUtils } from 'app/shared/utils';
import * as go from 'gojs';
import { ConnectionData, Flow, FlowPoint, FlowPointType, FlowPort, NodeData, ServerDeviceSolution } from '../models';


export class GoModelUtils {

  static buildModel(flow: Flow, serverDeviceSolutions: ServerDeviceSolution[]): go.Model {
    let modelJSON = {
      class: 'go.GraphLinksModel',
      nodeCategoryProperty: 'type',
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      nodeDataArray: [],
      linkDataArray: []
    };

    // separate the IN points from the OUT / EXIT points so the latter
    // show up on the right of the diagram
    const inPoints = flow.points.filter((p: FlowPoint) => {
      return p.pointType === FlowPointType.In;
    });
    for (let point of inPoints) {
      if (!point.isDeleted) {
        modelJSON.nodeDataArray.push(new NodeData(point, flow.ports));
      }
    }

    // add entryPorts
    for (let entryPort of flow.entryPorts || []) {
      if (!entryPort.isDeleted) {
        let associatedPort = flow.ports.find((fp: FlowPort) => {
          return fp.originalPortGuidId === entryPort.originalPortGuidId ||
            fp.guidId === entryPort.originalPortGuidId;
        });

        if (associatedPort) {
          entryPort.$serverPortCollectionName = 'Entry Port';
          modelJSON.nodeDataArray.push(new NodeData(entryPort, flow.ports));
          modelJSON.linkDataArray.push({
            from: associatedPort.parentGuidId,
            fromPort: entryPort.originalPortGuidId,
            to: entryPort.guidId,
            category: 'entryport',
          });
        }
      }
    }

    // add modules
    for (let node of flow.modules || []) {
      if (!node.isDeleted) {
        modelJSON.nodeDataArray.push(new NodeData(node, flow.ports));
      }
    }

    // add serverPorts
    for (let serverDeviceSolution of serverDeviceSolutions || []) {
      for (let serverPort of serverDeviceSolution.serverPort2s || []) {
        if (!serverPort.isDeleted) {
          const originalPortGuidId = serverPort.getFieldValue('OriginalPortGuidId');
          let fromPort = originalPortGuidId;
          const associatedPort = flow.ports.find((fp: FlowPort) => {
            fromPort = GuidUtils.isEqual(fp.originalPortGuidId, originalPortGuidId) ?
              fp.originalPortGuidId :
              GuidUtils.isEqual(fp.guidId, originalPortGuidId) ?
              fp.guidId : undefined;
            return !!fromPort;
          });

          if (associatedPort) {
            serverPort.$serverDeviceSolutionName = serverDeviceSolution.name;
            modelJSON.nodeDataArray.push(new NodeData(serverPort, flow.ports));
            modelJSON.linkDataArray.push({
              from: associatedPort.parentGuidId,
              fromPort: fromPort,
              to: serverPort.guidId,
              category: 'serverport',
            });
          }
        }
      }
    }

    // add OUT / EXIT points
    const outAndExitPoints = flow.points.filter((p: FlowPoint) => {
      return p.pointType !== FlowPointType.In;
    });
    for (let point of outAndExitPoints) {
      if (!point.isDeleted) {
        modelJSON.nodeDataArray.push(new NodeData(point, flow.ports));
      }
    }

    for (let connection of flow.connections || []) {
      if (!connection.isDeleted) {
        modelJSON.linkDataArray.push(new ConnectionData(connection));
      }
    }

    return go.Model.fromJson(modelJSON);
  }

}
