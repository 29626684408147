import { FlowBase } from './flow/flow-base.model';
import { FlowModule } from './flow/flow-module.model';
import { FlowPoint } from './flow/flow-point.model';
import { FlowPort, FlowPortType } from './flow/flow-port.model';
import { ServerDeviceSolutionType } from './server-device-solution.model';
import { ServerPort } from './server-port.model';
import { ServerPort2 } from './server-port2.model';


export class NodeData {

  key: string;
  name: string;
  originalObject: FlowBase | ServerPort | ServerPort2;

  type: string;
  typeLabel: string;
  inPorts: FlowPort[];
  outPorts: FlowPort[];
  actionPorts: FlowPort[];
  mapperPorts: FlowBase;

  flowGuidId: string;
  flowItemGuidId: string;

  solutionDeviceControlGuidId: string;
  deviceControlTypeGuidId: string;

  solutionCommandGuidId: string;
  commandTypeGuidId: string;
  commandTypeName: string;

  loadGuidId: string;
  notifyGuidId: string;

  serverDeviceSolutionType: ServerDeviceSolutionType;
  serverDeviceSolutionGuidId: string;

  constructor(
    node: FlowBase | ServerPort | ServerPort2,
    ports?: FlowPort[],
  ) {
    this.key = node.guidId;
    this.name = node.name;
    this.originalObject = node;

    ports = (ports || []).filter((port: FlowPort) => {
      return port.parentGuidId === node.guidId;
    });

    if (node instanceof FlowPoint) {
      this.type = 'point_' + node.pointType;
      this.inPorts = ports.filter((fp: FlowPort) => { return fp.portType === FlowPortType.In; });
      this.outPorts = ports.filter((fp: FlowPort) => { return fp.portType === FlowPortType.Out; });
    } else if (node instanceof FlowModule) {
      this.type = 'module_' + node.moduleType;
      this.typeLabel =  node.getTypeLabel();
      this.inPorts = ports.filter((fp: FlowPort) => { return fp.portType === FlowPortType.In; });
      this.outPorts = ports.filter((fp: FlowPort) => { return fp.portType === FlowPortType.Out; });
      this.actionPorts = ports.filter((fp: FlowPort) => { return fp.portType === FlowPortType.Action; });

      this.flowGuidId = node.flowGuidId;
      this.flowItemGuidId = node.flowItemGuidId;
      this.solutionDeviceControlGuidId = node.solutionDeviceControlGuidId;
      this.deviceControlTypeGuidId = node.deviceControlTypeGuidId;
      this.solutionCommandGuidId = node.solutionCommandGuidId;
      this.commandTypeGuidId = node.commandTypeGuidId;
      this.commandTypeName = node.commandTypeName;
      this.loadGuidId = node.loadGuidId;
      this.notifyGuidId = node.notifyGuidId;
    } else if (node instanceof ServerPort) {
      this.type = 'entryport';
      this.typeLabel = node.$serverPortCollectionName;
    } else if (node instanceof ServerPort2) {
      this.type = 'serverport';
      this.typeLabel = node.$serverDeviceSolutionName;
    } else { // Mapper
      console.warn(node);
      this.type = 'mapper';
      this.mapperPorts = ports as any;
    }
  }
}
