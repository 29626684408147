export const QUERYTYPE_GUIDIDS = 'GuidIds';
export const QUERYTYPE_TYPESINPROFILE = 'TypesInProfile';

export interface DataRequest {
  includeData: boolean;
  resolveDataFromTree: boolean;
  includeTree: boolean;
  includeDesignTree: boolean;
  includeSiteTree: boolean;
  includeTypes: boolean;
  includeSubTypes: boolean;
  includeTrace: boolean;
  includeComment: boolean;
  queryType: typeof QUERYTYPE_GUIDIDS | typeof QUERYTYPE_TYPESINPROFILE;
  guidIds: string[];
  treeGuidId: string;
  typeGuidIds: string[];
  treeLevels: number;
  permissions: string[];
}
