import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';
import { AppService } from '../../services';


@Component({
  selector: 'lc-info-dialog',
  templateUrl: './info.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialog extends BaseDialog {

  buttonText: string;
  message: string;
  pre: boolean;
  title: string;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.buttonText = data.buttonText || this.translateService.instant('Ok');
    this.message = data.message;
    this.pre = data.pre;
    this.title = data.title;
  }

  submit() {
    this.close(true);
  }

}
