
<p-dialog
  [header]="('New Solution Feature' | translate) + ':  '"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="isVisible">
  <form #form="ngForm"
    class="flex-col gap-2"
    (ngSubmit)="submit(form)"
    novalidate>
    <lc-form-field class="input"
      [editMode]="true"
      [label]="'Name' | translate"
      name="name"
      [(ngModel)]="name"
      [required]="true"
      type="text">
    </lc-form-field>

    <lc-form-field class="input"
      [editMode]="true"
      [label]="'Feature Name' | translate"
      name="featureName"
      [(ngModel)]="featureName"
      [required]="true"
      type="text">
    </lc-form-field>

    <lc-form-field
      [editMode]="true"
      [label]="'Default Value' | translate"
      name="defaultValue"
      [(ngModel)]="defaultValue"
      [options]="defaultValueItems"
      [required]="true"
      type="dropdown">
    </lc-form-field>
  </form>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>
    <lc-button [label]="'Cancel' | translate" (click)="dismiss()" type="secondary"></lc-button>
    <lc-button [label]="'OK' | translate" [disabled]="form.invalid" (click)="form.ngSubmit.emit()" type="primary"></lc-button>
  </p-footer>
</p-dialog>
