import { CenterType, DataSource, Permission, TreeSource } from '.';
import { CommentSource } from './comment-source.model';

export class LogicObject {

  constructor(
    public webDataSource: DataSource,
    public webTreeSource: TreeSource,
    public webCenterTypes?: CenterType[],
    public webCommentSources?: CommentSource[],
    public webSessionPermissions?: Permission[],
  ) {

  }

  // DataSource getters / setters
  getGuidId(): string {
    return this.webDataSource ? this.webDataSource.guidId : undefined;
  }

  getTypeGuidId(): string {
    return this.webDataSource ? this.webDataSource.typeGuidId : undefined;
  }

  getValue(key: string, defaultValue?: any);
  getValue(key: string, defaultValue?: any, typeGuidId?: string);
  getValue(key: string, defaultValue?: any, typeGuidId?: string) {
    typeGuidId = typeGuidId || this.webDataSource.typeGuidId;
    return this.webDataSource.getValue(key, defaultValue, typeGuidId);
  }

  setValue(key: string, value: any): void;
  setValue(key: string, value: any, typeGuidId?: string): void;
  setValue(key: string, value: any, typeGuidId?: string): void {
    return this.webDataSource.setValue(key, value, typeGuidId);
  }

  // TreeSource getters / setters
  getSiteGuidId(): string {
    return this.webTreeSource ? this.webTreeSource.siteGuidId : undefined;
  }
  setSiteGuidId(siteGuidId: string) {
    this.webTreeSource.siteGuidId = siteGuidId;
  }
  getTreeGuidId(): string {
    return this.webTreeSource ? this.webTreeSource.treeGuidId : undefined;
  }
  setTreeGuidId(treeGuidId: string) {
    this.webTreeSource.treeGuidId = treeGuidId;
  }

}
