import { Tag } from '../tag.model';
import { EnrollmentProfile } from './enrollment';
import { Environment } from './environment.model';
import { Integration } from './integration';
import { SolutionEnvironment } from './solution-environment.model';
import { SolutionProfileEvent } from './solution-profile-event.model';
import { SolutionProfileTemplateSolution } from './solution-profile-template-solution.model';
import { Solution } from './solution.model';

export enum SolutionProfileEnum {
  TopFuel = '5b38d326-50bc-43a6-b026-601dd51ba895',
}

export class SolutionProfile {

  guidId: string;
  name: string;
  comments: string;
  allowDelete: boolean;
  hasActiveSystemTask: boolean;
  systemEvents: any[];
  solutionProfileEvents: SolutionProfileEvent[];
  activeSolutions: Solution[];
  solutionEnvironments: SolutionEnvironment[];
  environments: Environment[];
  integrations: Integration[];
  solutionProfileTemplateSolutions: SolutionProfileTemplateSolution[];
  enrollmentProfiles: EnrollmentProfile[];
  tags: Tag[];

  constructor(item?: Partial<SolutionProfile>) {
    Object.assign(this, item);
  }

}
