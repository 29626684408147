import { FieldUtils } from '../../utils/field.utils';
import { Field } from '../field/field.model';
import { VariableToolbox } from '../variable-toolbox';

export enum DesignStyleTypes {
  VisualStyle = 0,
  ListStyle = 1,
  HeadStyle = 2,
  MapStyle = 3,
  UIGroupStyle = 4,
}

export class DesignStyle {

  guidId: string;
  name: string;
  tick: number;
  isNew: boolean;
  isDirty: boolean;
  newFlowCoreGuidId: string;
  fields: Field[];
  originalGuidId: string;

  $variableToolbox: VariableToolbox;

  constructor(item: any) {
    Object.assign(this, item);

    this.fields = FieldUtils.castFields(this.fields);
  }

  getField(fieldName: string): Field {
    let field = (this.fields || []).find((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    return FieldUtils.castField(field);
  }

}
