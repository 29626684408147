
<p-dialog appendTo="body"
  styleClass="prompt-dialog"
  [header]="data?.title"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <form #form="ngForm"
    (ngSubmit)="submit(form)"
    novalidate>

    <lc-form-field *ngIf="!!data && isVisible"
      appendTo="body"
      [autoFocus]="true"
      [editMode]="true"
      [label]="data.label"
      name="prompt"
      [ngModel]="data?.value"
      (ngModelChange)="data ? (data.value = $event) : null;"
      [options]="data.options || []"
      [optionLabel]="data.optionLabel"
      [required]="data.required"
      [type]="data.type || 'text'">
    </lc-form-field>

  </form>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      [label]="'Cancel' | translate"
      (click)="dismiss()"
      type="secondary">
    </lc-button>

    <lc-button
      [disabled]="form.invalid"
      [isFormSubmit]="true"
      [label]="'Ok' | translate"
      (click)="form.ngSubmit.emit()"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
