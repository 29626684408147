import { Injectable } from '@angular/core';
import { SelectItemGroup } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiStudioService } from '../api/api-studio.service';



@Injectable({
  providedIn: 'root'
})
export class CommandService {

  private urlSuffixPlaceholder = 'api/command/{what}';

  constructor(private apiService: ApiStudioService) { }

  listAsSIG(): Observable<SelectItemGroup[]> {
    return this.apiService.post<SelectItemGroup[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      { }
    ).pipe(
      map((response: any) => {
        if (response && response.commandGroups) {
          return this.buildSIGResponse(response.commandGroups);
        } else {
          return [];
        }
      })
    );
  }

  private buildSIGResponse(groups: any[]): SelectItemGroup[] {
    const groupedOptions = [];

    groups = (groups || []).sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    for (const cg of groups) {
      const group = {
        value: cg.guidId,
        label: cg.name,
        icon: cg.icon,
        items: []
      };
      cg.commands = (cg.commands || []).sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
      for (const c of cg.commands) {
        if (!c.obsolete) {
          group.items.push({
            value: c.guidId,
            label: c.name,
            icon: c.icon
          });
        }
      }

      if (group.items.length) {
        groupedOptions.push(group);
      }
    }

    return groupedOptions;
  }

}
