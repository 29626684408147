import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FlowAffectedResponse, FlowBase, VariableToolbox } from '../../models';



@Injectable({
  providedIn: 'root'
})
export class AffectedObjectsService {

  private affectedStudioObjects: Subject<FlowBase[]>;
  private affectedToolboxes: Subject<VariableToolbox>;

  constructor() {
    this.affectedStudioObjects = new Subject();
    this.affectedToolboxes = new Subject();
  }

  listenToAffectedStudioObjects(): Observable<FlowBase[]> {
    return this.affectedStudioObjects.asObservable();
  }

  listenToAffectedToolbox(guidId: string, toolboxName: string): Observable<VariableToolbox> {
    return this.affectedToolboxes.asObservable()
    .pipe(
      filter((variableToolbox: VariableToolbox) => {
        return variableToolbox.guidId === guidId && variableToolbox.toolboxName === toolboxName;
      })
    );
  }

  emit(far: FlowAffectedResponse) {
    if (far) {
      this.affectedStudioObjects.next(far.affectedStudioObjects);

      for (const variableToolbox of far.affectedToolboxes || []) {
        this.affectedToolboxes.next(new VariableToolbox(variableToolbox));
      }
    }
  }

}
