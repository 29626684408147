<p-dialog appendTo="body"
  styleClass="info-dialog"
  [header]="title"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <div [class.pre]="pre"
    [innerHTML]="message">
  </div>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      [label]="buttonText"
      (click)="submit()"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
