import { GuidUtils } from 'app/shared/utils';
import { Observable, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FlowAffectedResponse } from '../models';
import { Field, FieldType, FieldValueType, PropertyField, RelationField, SolutionField, SolutionTypeField } from '../models/field';
import { FieldService } from '../services';

export class FieldUtils {

  static castFields(fields: any[]): Field[] {
    let index = 0;
    for (const field of fields || []) {
      fields.splice(index, 1, this.castField(field));
      index++;
    }
    return fields;
  }

  static castField(field: any): Field {
    if (field && !(field instanceof Field)) {
      switch (field.webFieldType) {
        case FieldType.Property:
          return new PropertyField(field);
        case FieldType.Relation:
          return new RelationField(field);
        case FieldType.Solution:
          return new SolutionField(field);
        case FieldType.SolutionType:
          return new SolutionTypeField(field);
        default:
          return new Field(field);
      }
    } else if (field) {
      return field;
    } else {
      return null;
    }
  }

  static fetchFieldsObjects(fieldService: FieldService, field: RelationField, parentGuidId: string): Observable<void> {
    field.$fieldsObjectsFieldMap = {};

    const request = field.isList ?
      fieldService.relationGetFieldsObjects(
        parentGuidId,
        field.fieldGuidId,
      ) :
      fieldService.relationGetFieldObject(
        parentGuidId,
        field.fieldGuidId,
      ).pipe(
        map((fieldObject: any) => {
          return fieldObject ? [fieldObject] : fieldObject
        })
      );

    return request.pipe(
      map((fieldObjects: any[]) => {
        field.$fieldsObjects = fieldObjects || [];
        // template doesn't support ngFor where the declared item is passed as a parameter to the fields-component...
        for (const fieldObject of field.$fieldsObjects || []) {
          const sortedFields = (fieldObject.fields || [])
          .sort((a, b) => {
            return -(a.name.localeCompare(b.name));
          });
          for (const f of sortedFields) {
            f.readOnly = field.readOnly;
            field.$fieldsObjectsFieldMap[fieldObject.guidId + f.name] = f;
          }
        }
        return;
      })
    );
  }

  static saveRemotely(fieldService: FieldService, field: Field, parentGuidId: string): Observable<FlowAffectedResponse> {
    let request = null;
    if (field.webFieldType === FieldType.Solution) {
      const castedField = (field as SolutionField);
      if (castedField.webFieldValueType === FieldValueType.NotSet) {
        request = fieldService.solutionSetNotSet(parentGuidId, castedField.fieldGuidId);
      } else if (castedField.webFieldValueType === FieldValueType.Resource) {
        request = fieldService.solutionSetResource(parentGuidId, castedField.fieldGuidId, castedField.resourceId);
      } else if (castedField.webFieldValueType === FieldValueType.Static) {
        let hybridVars = null;
        if (castedField.staticValue && typeof castedField.staticValue === 'string') {
          hybridVars = (castedField.staticValue.match(/{{([^}]*)}}/g) || [])
          .map((v: string) => {
            v = v.replace(/{{/g, '').replace(/}}/g, '');
            return v;
          });
        }

        if (hybridVars?.length) {
          castedField.isHybrid = true;
          request = fieldService.solutionClearHybridVariables(parentGuidId, castedField.fieldGuidId)
          .pipe(
            mergeMap(() => {
              return zip(
                ...hybridVars.map((v: string) => {
                  return fieldService.solutionAddHybridVariable(
                    parentGuidId,
                    castedField.fieldGuidId,
                    v.split('|')[1],
                    GuidUtils.emptyGuid(),
                  );
                })
              );
            }),
            mergeMap(() => {
              return fieldService.solutionSetStatic(parentGuidId, castedField.fieldGuidId, castedField.staticValue);
            })
          );
        } else if (castedField.staticValue != null) {
          request = fieldService.solutionSetStatic(parentGuidId, castedField.fieldGuidId, castedField.staticValue);
        } else {
          request = fieldService.solutionSetNull(parentGuidId, castedField.fieldGuidId);
        }
      } else if (castedField.webFieldValueType === FieldValueType.Text) {
        request = fieldService.solutionSetText(parentGuidId, castedField.fieldGuidId, castedField.textId);
      } else if (
        castedField.webFieldValueType === FieldValueType.Variable ||
        castedField.webFieldValueType === FieldValueType.SolutionType
      ) {
        request = fieldService.solutionSetVariable(parentGuidId, castedField.fieldGuidId, (castedField.variablePointer || {}).stateVariableGuidId, (castedField.variablePointer || {}).subMemberGuidId);
      } else if (
        castedField.webFieldValueType === FieldValueType.FeatureString ||
        castedField.webFieldValueType === FieldValueType.ScannerEnabledType
      ) {
        request = fieldService.solutionSetStatic(parentGuidId, castedField.fieldGuidId, castedField.staticValue);
      }
    } else if (field.webFieldType === FieldType.Property) {
      const castedField = (field as PropertyField);
      request = fieldService.propertySetValue(parentGuidId, castedField.fieldGuidId, castedField.value != null ? castedField.value : '');
    } else if (field.webFieldType === FieldType.Relation) {
      const castedField = (field as RelationField);
      request = null; // this.fieldService.propertySetValue(this.parentGuidId, castedField.fieldGuidId, castedField.value);
    } else if (field instanceof SolutionTypeField) {
      const castedField = (field as SolutionTypeField);
      request = fieldService.solutionTypeSet(parentGuidId, castedField.fieldGuidId, castedField.valueSolutionTypeGuidId, castedField.valueSubMemberGuidId);
    }

    return request;
  }

}
