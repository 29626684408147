import { Injectable } from '@angular/core';
import { GuidUtils } from 'app/shared/utils';
import { SelectItemGroup } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Flow, FlowAffectedResponse, FlowModule, NewModuleRequest } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class FlowItemService {

  private urlSuffixPlaceholder = 'api/flowitem/{what}';

  constructor(private apiService: ApiStudioService) { }

  listAsSIG(): Observable<SelectItemGroup[]> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
    { })
    .pipe(
      map((response: any) => {
        if (response) {
          const items = [{ label: '-- New --', value: GuidUtils.emptyGuid() }];
          items.push(...(response.flowItems || []).map((flow: Flow) => {
            return { label: flow.name, value: flow.guidId };
          }));
          return [{
            label: 'FlowItem',
            items: items
          }];
        } else {
          return [];
        }
      })
    );
  }

  newModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'newmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

}
