import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ChipsModule } from 'primeng/chips';
import { FormFieldEmailsComponent } from './form-field-emails.component';


@NgModule({
  declarations: [
    FormFieldEmailsComponent,
  ],
  exports: [
    FormFieldEmailsComponent,
  ],
  imports: [
    AppSharedV2Module,
    ChipsModule,
  ],
})
export class FormFieldEmailsModule { }
