import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { FormFieldJsonComponent } from './form-field-json.component';


@NgModule({
  declarations: [
    FormFieldJsonComponent,
  ],
  exports: [
    FormFieldJsonComponent,
  ],
  imports: [
    AppSharedV2Module,
  ],
})
export class FormFieldJsonModule { }
