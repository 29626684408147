<div class="flex-col gap-2 cell-wrapper">
  <lc-form-field style="min-width: 200px;"
    appendTo="body"
    [editMode]="true"
    [(ngModel)]="preValue"
    (ngModelChange)="preValueChanged($event)"
    [options]="preValueItems"
    [placeholder]="'Hidden?' | translate"
    required
    type="dropdown">
  </lc-form-field>

  <lc-filter-string-editor *ngIf="preValue === 'on-condition' && params?.webCenterTypeGuidId && params?.webCenterTypes"
    class="filter-string-editor"
    [(filterStringFilter)]="params.value"
    [typeGuidId]="params?.webCenterTypeGuidId"
    [webCenterTypes]="params?.webCenterTypes">
  </lc-filter-string-editor>
</div>
