import { FlowBase } from './flow';
import { ServerPort2 } from './server-port2.model';


export enum ServerDeviceSolutionType {
  Custom = 0,
  Event = 1,
  Performance = 2,
  TemplateSolutionSystem = 3,
}

export class ServerDeviceSolution extends FlowBase {

  serverDeviceSolutionType: ServerDeviceSolutionType;
  serverPort2GuidIds: string[];
  serverPort2s: ServerPort2[];

  constructor(item?: any) {
    super(item);

    Object.assign(this, item);

    this.serverPort2s = (this.serverPort2s || []).map((sp: ServerPort2) => {
      return new ServerPort2(sp);
    });
  }

}
