import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GeoJSON } from 'app/center-v2/shared/models';
import { PluginLoaderService } from 'app/shared/services';
import { GuidUtils } from 'app/shared/utils';
import { LeafletUtils } from 'app/shared/utils/leaflet.utils';
import { environment } from 'environments/environment';

declare var L: any;

@Component({
  selector: 'lc-form-field-geojsongeometry',
  templateUrl: 'form-field-geojsongeometry.component.html',
  styleUrls: ['./form-field-geojsongeometry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldGeoJsonGeometryComponent),
    multi: true
  }],
})
export class FormFieldGeoJsonGeometryComponent implements ControlValueAccessor {

  readonly defaultMarkerColor = '#70B0E0';
  readonly mapId = 'map_' + GuidUtils.new();

  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;

  onChange: (x: any) => {};
  onTouched: () => {};

  isFocused = false;
  isLeafletLoaded: boolean;
  layerGroupMarkers: any;
  map: any;
  value: GeoJSON;

  constructor(
    private cdr: ChangeDetectorRef,
    private pluginLoaderService: PluginLoaderService,
    private translateService: TranslateService,
  ) {

  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {
    this.pluginLoaderService.load('leaflet')
    .subscribe((pluginLoaded: boolean) => {
      this.isLeafletLoaded = pluginLoaded;

      this.initMap();

      this.refresh();
    });
  }

  refresh(options?: any) {
    if (!this.isLeafletLoaded) return;

    this.initLayers();
    this.cdr.markForCheck();
  }

  private initMap() {
    try {
      const swedenCoords = [58.0082, 16.4435];
      this.map = L.map(this.mapId).setView(swedenCoords, 6);

      if (!environment.googleApiKey) {
        L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', maxZoom: 18 }).addTo(this.map);
      } else {
        LeafletUtils.addGoogleMutantLayers(this.map);
      }
    } catch(ex) {
      console.warn(`Trying to initialize map but couldn't find container with ID: '${this.mapId}'...it may have been disposed.`);
    }
  }

  initLayers() {
    if (!this.map) return;

    if (!this.layerGroupMarkers) {
      this.layerGroupMarkers = new L.FeatureGroup();
      this.map.addLayer(this.layerGroupMarkers);
    }

    this.addMarkers();
  }

  private addMarkers() {
    this.layerGroupMarkers.clearLayers();

    const geoJsonPosition = this.value;
    if (!geoJsonPosition?.geometry?.coordinates?.length) return;

    const color = this.defaultMarkerColor;
    const iconOptions = {
      color: color,
      fillColor: color,
      fillOpacity: 0.8,
      fontSize: 12,
      circleColor: color,
      circleFillColor: 'white',
      circleRatio: 0.65,
      circleText: '',
      weight: 1,
    };
    const latlng = L.latLng(geoJsonPosition?.geometry?.coordinates?.slice(0)?.reverse());
    const marker = L.marker.svgMarker(latlng, { iconOptions: iconOptions });
    marker.data = geoJsonPosition;

    marker.addTo(this.layerGroupMarkers);

    if (!this.layerGroupMarkers.getBounds()?.isValid()) return;

    this.map.fitBounds(this.layerGroupMarkers.getBounds());
  }

}
