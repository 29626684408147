import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { GuidUtils } from 'app/shared/utils';
import { MenuItem, Message, TreeNode } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { Commit, CommitResponse, LogicObject, NewResponse, Permission, TypeEnum, UserSession } from '../../models';
import { PermissionUtils, TreeUtils, TypeUtils } from '../../utils';
import { ObjectService, SessionService } from '../app';
import { AppService } from '../app/app.service';
import { NotificationService } from '../app/notification.service';
import { AuthService } from '../app/auth.service';
import { CommitService } from '../commit/commit.service';
import { LookupService } from '../lookup/lookup.service';



@Injectable({
  providedIn: 'root'
})
export class TreeContextMenuService {

  treeNodes: TreeNode[];
  rootObjectGuidId: string;

  private nodeSelectSubject: Subject<TreeNode>;
  nodeSelect$: Observable<TreeNode>;
  private fullRefreshSubject: Subject<TreeNode>;
  fullRefresh$: Observable<TreeNode>;
  private refreshSubject: Subject<TreeNode>;
  refresh$: Observable<TreeNode>;
  private deleteTankSubject: Subject<string>;
  deleteTank$: Observable<string>;

  permissions: Permission[];
  confirmDialog: BaseDialog;
  infoDialog: BaseDialog;
  selectSiteDialog: BaseDialog;
  addToWorkspaceDialog: BaseDialog;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private commitService: CommitService,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private objectService: ObjectService,
    private router: Router,
    private sessionService: SessionService,
    private translateService: TranslateService,
  ) {
    this.nodeSelectSubject = new Subject();
    this.nodeSelect$ = this.nodeSelectSubject.asObservable();
    this.fullRefreshSubject = new Subject();
    this.fullRefresh$ = this.fullRefreshSubject.asObservable();
    this.refreshSubject = new Subject();
    this.refresh$ = this.refreshSubject.asObservable();
    this.deleteTankSubject = new Subject();
    this.deleteTank$ = this.deleteTankSubject.asObservable();
  }

  buildMenuItems(
    treeNode: TreeNode,
    treeNodes: TreeNode[],
    rootObjectGuidId: string,
    permissions: Permission[],
    confirmDialog: BaseDialog,
    infoDialog: BaseDialog,
    selectSiteDialog: BaseDialog,
    addToWorkspaceDialog: BaseDialog,
  ): MenuItem[] {
    this.treeNodes = treeNodes;
    this.rootObjectGuidId = rootObjectGuidId;
    this.permissions = permissions;
    this.confirmDialog = confirmDialog;
    this.infoDialog = infoDialog;
    this.selectSiteDialog = selectSiteDialog;
    this.addToWorkspaceDialog = addToWorkspaceDialog;

    let contextMenuItems = [];
    if (treeNode.data.typeGuidId === TypeEnum.Site) {
      let siteGuidId = treeNode.data.guidId;
      if (this.hasWriteAccess('Structural|' + TypeEnum.Site + '|Add')) {
        contextMenuItems.push({
          label: 'Add Site',
          icon: 'fas fa-home',
          command: () => { this.addItem(treeNode, TypeEnum.Site, siteGuidId); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.Group + '|Add')) {
        contextMenuItems.push({
          label: 'Add Group',
          icon: 'fas fa-object-group',
          command: () => { this.addItem(treeNode, TypeEnum.Group, siteGuidId); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.User + '|Add')) {
        contextMenuItems.push({
          label: 'Add User',
          icon: 'fas fa-user-circle',
          command: () => { this.addItem(treeNode, TypeEnum.User, siteGuidId); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.GpsDevice + '|Add')) {
        contextMenuItems.push({
          label: 'Add GPS Device',
          icon: 'fas fa-map-marker-alt',
          command: () => { this.addItem(treeNode, TypeEnum.GpsDevice, siteGuidId); }
        });
      }
      if (true /*this.hasWriteAccess('Structural|' + TypeEnum.Site + '|Move')*/) {
        contextMenuItems.push({
          label: 'Move Site',
          icon: 'fas fa-plane',
          command: () => { this.moveItem(treeNode); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.Site + '|Remove')) {
        contextMenuItems.push({
          label: 'Remove Site',
          icon: 'fas fa-minus',
          disabled: !treeNode.parent,
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
    } else if (treeNode.data.typeGuidId === TypeEnum.Group) {
      if (this.hasWriteAccess('Structural|' + TypeEnum.Group + '|Remove')) {
        contextMenuItems.push({
          label: 'Remove Group',
          icon: 'fas fa-minus',
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
    } else if (treeNode.data.typeGuidId === TypeEnum.User) {
      if (true) {
        contextMenuItems.push({
          label: 'Set as Admin User',
          icon: 'fas fa-star',
          command: () => { this.setAsAdminUser(treeNode); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.User + '|LoginAsUser')) {
        contextMenuItems.push({
          label: 'Logon as user',
          icon: 'fas fa-sign-in-alt',
          command: () => { this.loginAsUser(treeNode); }
        });
      }
      if (this.hasWriteAccess(PermissionEnum.Workspaces + '|UserAdmin')) {
        contextMenuItems.push({
          label: 'Add to Workspace',
          icon: 'fas fa-th-large',
          command: () => { this.addToWorkspace(treeNode); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.User + '|Move')) {
        contextMenuItems.push({
          label: 'Move User',
          icon: 'fas fa-plane',
          command: () => { this.moveItem(treeNode); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.User + '|Remove')) {
        contextMenuItems.push({
          label: 'Remove User',
          icon: 'fas fa-minus',
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
    } else if (treeNode.data.typeGuidId === TypeEnum.GpsDevice) {
      if (this.hasWriteAccess('Structural|' + TypeEnum.GpsDevice + '|Remove')) {
        contextMenuItems.push({
          label: 'Remove GPS Device',
          icon: 'fas fa-minus',
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.GpsDevice + '|Move')) {
        contextMenuItems.push({
          label: 'Move GPS Device',
          icon: 'fas fa-plane',
          command: () => { this.moveItem(treeNode); }
        });
      }
    } else if (treeNode.data.typeGuidId === TypeEnum.Tank) {
      if (this.hasWriteAccess('Structural|' + TypeEnum.Tank + '|Move')) {
        contextMenuItems.push({
          label: 'Move Tank',
          icon: 'fas fa-plane',
          command: () => { this.moveItem(treeNode); }
        });
      }
      if (this.hasWriteAccess('Structural|' + TypeEnum.Tank + '|SpecialRemove')) {
        contextMenuItems.push({
          label: 'Remove Tank',
          icon: 'fas fa-minus',
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
    } else if (treeNode.data.typeGuidId === GuidUtils.emptyGuid()) {
      if (this.hasWriteAccess('TopFuelProduct.Structural|' + TypeEnum.ProductGroup + '|Add')) {
        contextMenuItems.push({
          label: 'Add ProductGroup',
          icon: 'fas fa-plus',
          command: () => { this.addItem(treeNode, TypeEnum.ProductGroup, this.sessionService.instant().siteGuidId); }
        });
      }
    } else if (treeNode.data.typeGuidId === TypeEnum.ProductGroup) {
      if (this.hasWriteAccess('TopFuelProduct.Structural|' + TypeEnum.Product + '|Add')) {
        contextMenuItems.push({
          label: 'Add Product',
          icon: 'fas fa-plus',
          command: () => { this.addItem(treeNode, TypeEnum.Product, this.sessionService.instant().siteGuidId, treeNode.data.guidId); }
        });
      }
      if (this.hasWriteAccess('TopFuelProduct.Structural|' + TypeEnum.ProductGroup + '|Remove')) {
        contextMenuItems.push({
          label: 'Remove Item',
          icon: 'fas fa-minus',
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
    } else if (treeNode.data.typeGuidId === TypeEnum.Product) {
      if (this.hasWriteAccess('TopFuelProduct.Structural|' + TypeEnum.Product + '|Remove')) {
        contextMenuItems.push({
          label: 'Remove Item',
          icon: 'fas fa-minus',
          command: () => { this.removeItemAttempt(treeNode); }
        });
      }
    } else {
      contextMenuItems.push({
        icon: 'fas fa-ban',
        label: this.translateService.instant('No options available for this node'),
        disabled: true,
      });
    }
    return contextMenuItems;
  }

  private addItem(parentNode: TreeNode, typeGuidId: string, siteGuidId: string, parentGuidId?: string): void {
    parentNode.expanded = true;
    this.appService.setBusy(true);

    this.objectService.new(typeGuidId, siteGuidId, parentGuidId)
    .subscribe((nr: NewResponse) => {
      const ds = this.lookupService.getDataSource(nr.webSources.webDataSources[0].guidId);
      const ts = this.lookupService.getTreeSource(nr.webSources.webDataSources[0].defaultTreeGuidId);
      const lo = new LogicObject(ds, ts);
      const newNode = this.makeTreeNodeFromLogicObject(lo, nr.designTreeNodeIconUri, nr.designTreeNodeMember);
      parentNode.children.push(newNode);

      this.handleCommitResponse(nr, newNode);
    }, (error: string) => {

      this.appService.setBusy(false);
    });
  }

  private makeTreeNodeFromLogicObject(lo: LogicObject, icon: string, member: string): TreeNode {
    const node: TreeNode = {
      data: {
        guidId: lo.getGuidId(),
        typeGuidId: lo.getTypeGuidId(),
        defaultTreeGuidId: lo.getTreeGuidId(),
      },
      label: lo.getValue((member || 'name').toLowerCase()),
      icon: icon,
      leaf: true,
      children: []
    };

    return node;
  }


  private setAsAdminUser(userNode: TreeNode) {
    userNode.parent.data.adminUserGuidId = userNode.data.guidId;

    const userAddress = TreeUtils.getAddressTreeNode(userNode);
    const siteAddress = TreeUtils.getAddressTreeNode(userNode.parent);

    const userDS = this.lookupService.getDataSource(userNode.data.guidId);
    const siteDS = this.lookupService.getDataSource(userNode.parent.data.guidId);
    const siteTS = this.lookupService.getTreeSource(userNode.parent.data.defaultTreeGuidId);

    // update the tree with new address objects
    const index = userNode.parent.children.findIndex((item: TreeNode) => {
      return item.data.typeGuidId === TypeEnum.Address;
    });
    if (index) {
      userNode.parent.children.splice(index, 1);
    }
    userNode.parent.children.push(userAddress);

    // update the server
    const commit = Commit.buildCommitForSetAsAdminUser(
      new LogicObject(siteDS, siteTS),
      siteAddress.data.guidId,
      new LogicObject(userDS, null),
      userAddress.data.guidId,
    );
    this.appService.setBusy(true);
    this.objectService.update(commit)
    .subscribe((cr: CommitResponse) => {
      this.handleCommitResponse(cr, null);
    }, (error: string) => {

      this.appService.setBusy(false);
    });
  }

  private removeItemAttempt(treeNode: TreeNode) {
    if (!treeNode.parent) {
      this.showError('Can\'t delete the root node.' );
    } else if (
      treeNode.data.typeGuidId === TypeEnum.Site &&
      (treeNode.children || []).some((tn: TreeNode) => {
        return TypeUtils.VISIBLE_TYPE_GUIDS.indexOf(tn.data.typeGuidId) >= 0;
      })
    ) {
      this.showError('Can\'t delete items with child nodes.' );
    } else if (TypeUtils.GENERICGROUP_TYPE_GUIDS.indexOf(treeNode.data.typeGuidId) >= 0 && treeNode.children && treeNode.children.length > 0) {
      this.showError('Can\'t delete items with child nodes.' );
    } else {
      this.removeItemConfirm(treeNode);
    }
  }

  private removeItemConfirm(treeNode: TreeNode) {
    this.confirmDialog.show({
      title: 'Remove Item?',
      message: 'Are you sure you want to remove this Item?'
    });
    this.confirmDialog.onClose = (result: boolean) => {
      if (result) {
        this.removeItem(treeNode);
      }
    };
  }

  private removeItem(treeNode: TreeNode, updateRemotely = true) {
    const parentNode = treeNode.parent;
    const indexToDelete = parentNode.children.findIndex((item) => {
      return item.data.guidId === treeNode.data.guidId;
    });
    parentNode.children.splice(indexToDelete, 1);

    if (updateRemotely) {
      this.appService.setBusy(true);

      this.objectService.delete(treeNode.data.guidId)
      .subscribe((cr: CommitResponse) => {
        this.handleCommitResponse(cr, parentNode);
      },
      (error: string) => {

        this.appService.setBusy(false);
      });
    }
  }

  private moveItem(treeNode: TreeNode) {
    this.selectSiteDialog.show({
      rootObjectGuidId: this.rootObjectGuidId,
      title: this.translateService.instant('Where to?'),
    });
    this.selectSiteDialog.onClose = (siteNode: TreeNode) => {
      if (!siteNode) return;

      this.appService.setBusy(true);

      this.objectService.move(treeNode.data.guidId, treeNode.parent.data.guidId, siteNode.data.guidId)
      .subscribe((cr: CommitResponse) => {
        siteNode = TreeUtils.findOrAddToTree(this.treeNodes, siteNode);
        // update the tree with removing the item from its current location and adding it to the new one
        this.removeItem(treeNode, false);
        treeNode.parent = siteNode;
        TreeUtils.expandParentSites(siteNode);
        siteNode.children = siteNode.children || [];
        siteNode.children.push(treeNode);

        this.refreshSubject.next(treeNode);

        this.handleCommitResponse(cr, treeNode);
      },
      (error: string) => {
        this.appService.setBusy(false);
      });
    };
  }


  commitChanges(commit: Commit, selectNode?: TreeNode) {
    if (commit.webDataSources.length || commit.webTreeSources.length) {
      console.log(JSON.stringify(commit, null, 2));
      // commit.webDataSources = []; commit.webTreeSources = [];

      this.appService.setBusy(true);
      this.objectService.update(commit)
      .subscribe((cr: CommitResponse) => {
        this.handleCommitResponse(cr, selectNode);
      },
      (error: string) => {

        this.appService.setBusy(false);
      });
    }
  }

  private handleCommitResponse(cr: CommitResponse, selectNode?: TreeNode, followUpCommit?: Commit) {
    if (cr.hasSystemTask && cr.$systemTaskComplete && cr.systemTaskReason === 'DesignTree') {
      if (followUpCommit) {
        this.commitChanges(followUpCommit);
      } else {
        this.fullRefreshSubject.next(selectNode);

        this.appService.showToast({ severity: 'success', summary: 'Success', detail: 'Change saved successfully.' });
        this.appService.setBusy(false);
      }
    } else if (!cr.hasSystemTask) {
      if (cr && cr.commited) {
        if (selectNode) {
          setTimeout(() => {
            this.nodeSelectSubject.next(selectNode);
          }, 100);
        }

        this.appService.showToast({ severity: 'success', summary: 'Success', detail: 'Change saved successfully.' });
        if (followUpCommit) {
          this.commitChanges(followUpCommit);
        } else {
          this.appService.setBusy(false);
        }
      } else {
        this.appService.showToast({ severity: 'error', summary: 'Error', detail: 'Failed to update object on server.' });
      }
    }
  }

  private loginAsUser(tn: TreeNode) {
    this.authService.loginAsUser(tn.data.guidId)
    .subscribe((session: UserSession) => {
      this.router.navigate(['/']);
    }, (error: any) => {

    });
  }

  showError(msg: string) {
    this.appService.showToast(<Message>{ severity: 'error', summary: 'Error', detail: msg });
  }

  private hasReadAccess(path: string) {
    return PermissionUtils.hasReadAccess(this.permissions, path) ||
           PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  private hasWriteAccess(path: string) {
    return PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  private addToWorkspace(tn: TreeNode) {
    this.addToWorkspaceDialog.show({
      userGuidId: tn.data.guidId,
    });
    this.addToWorkspaceDialog.onClose = (result: any) => {
      if (result) {
        this.notificationService.success(
          this.translateService.instant('Success'),
          this.translateService.instant('Item saved successfully.'),
        );
      }
    }
  }

}
