import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebArticleGroup extends WebObject {

  static typeGuidId = '5ad29cf7-6c0f-4f4e-90f3-52244299afe0';

  members: {
    name: string;
    hasArticleGroups: boolean;
    rootArticleGroup: boolean;
    parentArticleGroupGuidId: string;
    fullName: string;
    fullNameTick: number;
    reference: string;
  };

  relations: {
    articles?: WebRelationPointer[];
    articleGroups?: WebRelationPointer[];
    smartImages?: WebRelationPointer[];
    supplier?: WebRelationPointer;
    articleType?: WebRelationPointer;
    parentArticleGroup?: WebRelationPointer;
    onNewSubTypes?: WebRelationPointer[];
  };

  constructor(item?: Partial<WebArticleGroup>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebArticleGroup.typeGuidId;
  }

}