<div class="form-field"
  (click)="onTouched()">
  <label *ngIf="label"
    class="label"
    [innerHTML]="label + ' (' + ('click on the calendar to add items; drag/drop to rearrange' | translate) + ')'">
  </label>

  <div *ngIf="!editMode"
    class="value">
    <div class="flex-row middle">
      <div *ngIf="iconLeft" [ngClass]="iconLeft"></div>

      <div [innerHTML]="value != null ? value : noValueHtml">
      </div>

      <div *ngIf="iconRight" [ngClass]="iconRight"></div>
    </div>
  </div>

  <div>
    <mwl-calendar-week-view class="flex-1"
      [daysInWeek]="7"
      (eventClicked)="eventClicked($event)"
      [events]="events || []"
      (eventTimesChanged)="eventTimesChanged($event)"
      [headerTemplate]="headerTemplate"
      (hourSegmentClicked)="emptySlotClicked($event.date)"
      [refresh]="refreshCalendar"
      [tooltipTemplate]="tooltipTemplate"
      [viewDate]="viewDate"
      [weekStartsOn]="1">
    </mwl-calendar-week-view>

    <ng-template #headerTemplate
      let-days="days"
      let-locale="locale"
      let-dayHeaderClicked="dayHeaderClicked"
      let-eventDropped="eventDropped"
      let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
      let-dragEnter="dragEnter">
      <div class="cal-day-headers" role="row">
        <div *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          class="cal-header"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          [ngClass]="day.cssClass"
          (mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })"
          mwlDroppable
          dragOverClass="cal-drag-over"
          (drop)="
            eventDropped.emit({
              event: $event.dropData.event,
              newStart: day.date
            })
          "
          (dragEnter)="dragEnter.emit({ date: day.date })"
          tabindex="0"
          role="columnheader">
          <b>{{ day.date | calendarDate: 'weekViewColumnHeader':locale | translate }}</b>
          <br />
          <span>
            {{ day.date | calendarDate: 'weekViewColumnSubHeader':locale }}
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template #tooltipTemplate let-event="event" let-placement="placement" let-contents="contents">
      <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
        <div class="cal-tooltip-arrow"></div>
        <div class="cal-tooltip-inner">
          <div>
            <p class="title">{{ event.title }}</p>
            <!-- <p class="info"><b>{{ 'Last Run' | translate }}:</b> {{ event.meta.members.$lastRun | dfnsFormat: 'yyyy-MM-dd HH:mm' }}</p>
            <p class="info"><b>{{ 'Interval' | translate }}:</b> {{ event.meta.members.$interval }}</p>
            <p *ngIf="event.meta.members.runTimeDefined" class="info"><b>{{ 'Start' | translate }}:</b> {{ event.meta.members.runTimeHourStart }}</p>
            <p *ngIf="event.meta.members.runTimeDefined" class="info"><b>{{ 'End' | translate }}:</b> {{ event.meta.members.runTimeHourEnd }}</p> -->
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<lc-edit-work-schedule-day-dialog></lc-edit-work-schedule-day-dialog>
