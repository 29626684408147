import { ChangeDetectorRef, Component } from '@angular/core';
import { GridUtils } from 'app/shared/utils';


@Component({
  template: '<div></div>'
})
export abstract class BaseGridCellRenderer {

  params: any;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {

  }

  getParamValue(paramField: any): any {
    return GridUtils.getParamValue(this.params, paramField);
  }

}

