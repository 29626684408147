import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { ApplicationInsights, IDependencyTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'environments/environment';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DateFnsModule } from 'ngx-date-fns';
import { AppErrorHandler } from './app-error-handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from './shared/services/translate/translate-http.loader';
import { TranslateJsonParser } from './shared/services/translate/translate-json.parser';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastModule } from 'primeng/toast';
import { InfoDialogModule } from './shared/dialogs/info/info.dialog.module';
import { MessageService, TreeDragDropService } from 'primeng/api';
const { name, version } = require('../../package.json');


if (environment.appInsightsKey) {
  initAppInsights();
}
if (environment.sentryDNS) {
  initSentry();
}

function initAppInsights() {
  const appInsights = new ApplicationInsights({ config: {
    appId: name + '@' + version,
    enableAutoRouteTracking: true,
    instrumentationKey: environment.appInsightsKey,
    maxAjaxCallsPerView: -1,
  } });

  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {
    if (
      telemetryItem?.baseData &&
      [0, 401].indexOf((telemetryItem.baseData as IDependencyTelemetry).responseCode) >= 0
    ) {
      return false;
    }
  });

  (window as any).appInsights = appInsights;
}

function initSentry() {
  Sentry.init({
    dsn: environment.sentryDNS,
    environment: environment.name,
    integrations: [Sentry.browserTracingIntegration()],
    release: name + '@' + version,
    tracesSampleRate: 0.2,
  });
}


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/l10n/',
    '.json?r=' + version
  );
}

export function createTranslateParser() {
	return new TranslateJsonParser();
}

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const urlSplit = (url || '').split('?');
    return super.parse(urlSplit[0].toLowerCase() + (urlSplit[1] ? '?' + urlSplit[1] : ''));
  }
}

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DateFnsModule.forRoot(),
    InfoDialogModule,
    NgCircleProgressModule.forRoot({
      animation: false,
      maxPercent: 100,
      outerStrokeWidth: 12,
      outerStrokeColor: 'green',
      outerStrokeLinecap: 'butt',
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 12,
      radius: 6,
      space: -12,
      showBackground: false,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      responsive: false,
    }),
    NgxLoadingModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useFactory: createTranslateParser,
        deps: []
      }
    }),
    LoadingBarHttpClientModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    // { provide: LOCALE_ID, useValue: navigator.language },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    provideHttpClient(withInterceptorsFromDi()),

    MessageService,
    TreeDragDropService,
  ],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
