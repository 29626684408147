import { DictNumber } from '../..';
import { SolutionProfile } from '../../solution/solution-profile.model';
import { SolutionProfilePhaseItem } from './solution-profile-phase-item.model';


export enum SolutionProfilePhaseType {
  Configuration = 1,
  Test = 2,
  Pilot = 3,
  Production = 4,
  Decomissioned = 5,
}

export class SolutionProfileOverview {

  solutionProfile: SolutionProfile;
  phases: DictNumber<SolutionProfilePhaseItem>; // the dict key is one of SolutionProfilePhaseType

  constructor(item?: Partial<SolutionProfileOverview>) {
    Object.assign(this, item);
  }

}
