import { GuidUtils } from '../utils/guid.utils';
import { DictString } from './dict.model';
import { SiteTree } from './site-tree.model';



export class DataSource {

  guidId: string;
  // profileGuidId: string;
  values: DictString<any>;
  collections: DictString<DictString<DictString<DictString<any>>>>;
  // binary: any[];
  // logicUpdateChangeGuidId: string;
  tick: number;
  active: boolean;
  isNew: boolean;
  isDirty: boolean;

  exists: boolean;
  typeGuidId: string;
  defaultTreeGuidId: string;
  isSubcast: boolean;
  subCastTypeGuidIds: string[];
  accessSubCastTypes: any[];
  accessSpecialTabs: any[];
  siteOnlySiteTree: SiteTree;


  constructor(item?: Partial<DataSource>) {
    Object.assign(this, item);
  }

  static build(guidId: string, typeGuidId: string, defaultTreeGuidId?: string): DataSource {
    const newItem = new DataSource();
    newItem.guidId = guidId || GuidUtils.new();
    newItem.exists = true;
    newItem.typeGuidId = typeGuidId;
    newItem.defaultTreeGuidId = defaultTreeGuidId ? defaultTreeGuidId : newItem.guidId;
    newItem.subCastTypeGuidIds = [];
    newItem.accessSubCastTypes = [];
    newItem.accessSpecialTabs = [];
    newItem.values = {};
    newItem.values[typeGuidId] = {};
    newItem.tick = 0;
    newItem.active = true;
    newItem.isNew = true;
    newItem.isDirty = false;

    return newItem;
  }

  getValuesFlattened() {
    let result: any = {};
    if (this.values && this.isObject(this.values[Object.keys(this.values)[0]])) {
      Object.keys(this.values).forEach((k1) => {
        Object.keys(this.values[k1]).forEach((k2) => {
          result[k2] = this.values[k1][k2];
        });
      });
    } else {
      result = this.values || {};
    }
    return result;
  }

  private isObject(value: any) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
  }

  /**
   * @deprecated
   */
  setNewFlags() {
    this.active = true;
    this.isNew = true;
    this.isDirty = true;
  }

  /**
   * @deprecated
   */
  setDirtyFlags() {
    this.isDirty = true;
  }


  getValue(key: string, defaultValue?: any): any;
  getValue(key: string, defaultValue?: any, typeGuidId?: string): any;
  getValue(key: string, defaultValue?: any, typeGuidId?: string): any {
    typeGuidId = typeGuidId || this.typeGuidId;

    if (this.values && this.values[typeGuidId]) {
      return this.values[typeGuidId][key] !== null && this.values[typeGuidId][key] !== undefined ?
        this.values[typeGuidId][key] :
        defaultValue;
    }
  }

  setValue(key: string, value: any): void;
  setValue(key: string, value: any, typeGuidId?: string): void;
  setValue(key: string, value: any, typeGuidId?: string): void {
    typeGuidId = typeGuidId || this.typeGuidId;

    if (!this.values) {
      this.values = {};
    }
    if (!this.values[typeGuidId]) {
      this.values[typeGuidId] = {};
    }

    this.values[typeGuidId][key] = value;

    this.isDirty = true;
  }

  deactivate(): void {
    this.active = false;
    this.isDirty = true;
  }

}
