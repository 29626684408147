import { Tag } from '../../../shared/models/tag.model';
import { WorkspaceWidgetSetting } from './workspace-widget-setting.model';
import { WorkspaceWidget } from './workspace-widget.model';
import { WebObject } from './web-base/web-object.model';

export class WorkspaceWidgetTemplate extends WebObject {

  guidId: string;
  // TODO: remove these as they'll be replaced by the new web object "members" field
  tags: Tag[];
  name: string;
  settings: WorkspaceWidgetSetting[];
  isCore: boolean;
  sysWidgetTemplateName: string;
  sysWidgetName: string;
  activeSysVersion: number;

  members: {
    tags: Tag[];
    name: string;
    settings: WorkspaceWidgetSetting[];
    isCore: boolean;
    sysWidgetTemplateName: string;
    sysWidgetName: string;
    activeSysVersion: number;
  }

  $widgetTemplateModuleName: string;

  constructor(item?: Partial<WorkspaceWidgetTemplate>) {
    super(item);

    this.members = this.members || {} as any;

    this.settings = (this.settings || []).map((x: WorkspaceWidgetSetting) => {
      return new WorkspaceWidgetSetting(x);
    });
    this.members.settings = (this.members.settings || []).map((x: WorkspaceWidgetSetting) => {
      return new WorkspaceWidgetSetting(x);
    });

    this.$widgetTemplateModuleName = WorkspaceWidget.WidgetTemplateMap[this.guidId] || '';
  }

  getSettingValueByName(name: string) {
    const setting = this.getSettingByName(name) || {} as WorkspaceWidgetSetting;
    return setting.$value;
  }

  getSettingByName(name: string) {
    const setting = (this.settings || []).find((s: WorkspaceWidgetSetting) => {
      return s.name === name;
    });
    return setting;
  }

}
