import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebSystem extends WebObject {

  static typeGuidId = 'a279b9be-7ba8-4872-a7ba-7ec7c113f578';

  members: {
    name: string;
    isLogicCenter: boolean;
    isRunSystem: boolean;
    environmentSystemQueueGuidId: string;
    environmentSystemTaskServiceGuidId: string;
    environmentManagementSchedulerGuidId: string;
  }

  relations: {
    systemVersions: WebRelationPointer[];
  }

  constructor(item?: Partial<WebSystem>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebSystem.typeGuidId;
  }

}