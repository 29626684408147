import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { Events, IStatusPanelParams } from 'ag-grid-community';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter } from '@angular/core';
import { GridUtils } from 'app/shared/utils';


@Component({
  selector: 'lc-grid-statusbar-update',
  templateUrl: 'grid-statusbar-update.component.html',
  styleUrls: ['grid-statusbar-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridStatusBarUpdateComponent implements IStatusPanelAngularComp {

  params: any;

  lastUpdateDate: Date;
  lastUpdateFailed: boolean;
  nextUpdateDate: Date;
  refreshClick: EventEmitter<void>;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  // The init(params) method is called on the status panel once upon component initialisation.
  agInit(params: IStatusPanelParams): void {
    this.params = params;

    // this.lastUpdateDate = GridUtils.getParamValue(this.params, this.params.lastUpdateDate);
    // this.nextUpdateDate = GridUtils.getParamValue(this.params, this.params.nextUpdateDate);
    // this.lastUpdateFailed = GridUtils.getParamValue(this.params, this.params.lastUpdateFailed);
    // this.refreshClick = GridUtils.getParamValue(this.params, this.params.refreshClick);

    this.params.api.addEventListener(Events.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));
  }

  private onDataChanged() {
    this.lastUpdateDate = GridUtils.getParamValue(this.params, this.params.lastUpdateDate);
    this.nextUpdateDate = GridUtils.getParamValue(this.params, this.params.nextUpdateDate);
    this.lastUpdateFailed = GridUtils.getParamValue(this.params, this.params.lastUpdateFailed);
    this.refreshClick = GridUtils.getParamValue(this.params, this.params.refreshClick);

    this.cdr.markForCheck();
  }

}