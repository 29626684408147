export enum TypeEnum {
  // center
  Site = '5706dd4e-87f9-4e04-8a23-353d10554302',
  Group = '7e51c5e6-13f7-4d84-ae92-5dc2cdc112b6',
  User = '544d4ccc-b086-4588-8756-38ab373105e7',
  ContactUser = 'de6e38c4-12b0-494d-b48f-697696bf9d26',
  WebUser = '168ed81f-24d8-40dd-ac21-c569fd3db28e',
  Device = '58921c17-226f-4513-92d0-8a49a999f7d9',
  Tank = '781b0a44-0125-418c-9893-b332e8094571',
  TankModel = 'd55cb0c3-8385-4e6d-a42a-644300020133',
  Address = 'b27da86e-ecc6-4add-85a6-0a614f8bee24',
  Permission = '1c541720-730b-4dfd-a2a4-2ca1c0a5d9fd',
  ProductGroup = '6bd043d1-9983-48f8-be15-7de2b0226844',
  Product = 'cf9fb677-357d-4937-a965-91bee1a0abe4',
  GpsDevice = 'e5e78bf3-874a-4e15-b713-bd813ba52bd7',
  TopFuelTask = '29be2511-21c3-4872-ab4e-df00c86b00fc',
  // AlarmUser = '95981acc-65ed-45a6-9161-851875c80f90',
  // Preem
  PreemUserInfo = '6e983777-026b-4ddc-9c61-47a1c5dd2f41',
  PreemOrder = '93c75cf8-7827-44df-92a1-a4ccb3543dd5',

  // TopFuel
  TopFuelForecast = 'd04fee64-4c22-4270-958c-b2c8c88d3498',
  TopFuelForecastPerstorp = '2b00fbee-2374-473f-ad9c-dc91926b9b25',
  TopFuelOrder = 'd491aef3-777b-474e-9358-0d43d080a980',
  TopFuelOrderPerstorp = '0d63b460-a5b1-4f4f-8ace-0e7e3b94f569',
  TopFuelProductShipper = 'a932db35-81ae-4747-ba3a-659a1b078511',
  TopFuelService = '3f75fe82-af3c-4f24-950d-30b4f47eec46',
  TopFuelTankForecast = '95667927-433d-4e3c-a4f8-c091dce6630d',
  TopFuelTankOrder = 'f5f8286d-32bd-479a-9af3-69e30be3e990',



  // studio related
  SolutionProfile = '246f8d88-46fe-4e2c-8815-b3fbbcee7d26',
  SolutionProfileTemplateSolution = 'cd71b401-cede-4f75-a33e-8cdc97ce9881',
  SolutionEnvironment = '2cfb18e0-d9a5-4165-b125-b94910ab97a9',
  SolutionIntegration = '16edffa0-a987-4578-bd9e-a70500c6f1bb',
  SolutionQueue = '341bc198-37c1-4457-8bf9-4bb312d98bfd',
  SolutionSet = '6875c67f-f9a5-416c-b4bc-c9e159b272cb',
  Solution = '5894a01e-5dd6-49a1-8684-18c8c6b941e4',
  RunEngine = '2a659749-318c-429d-a1ea-17364967fd1c',

  // center v2
  ArticleGroup = '5ad29cf7-6c0f-4f4e-90f3-52244299afe0',
  ArticleRecipeIngredient = '9f44a936-1949-41fc-82c3-ab737b727289',
  BackOfficePlatformReport = '9a66ff68-699c-4c3c-baf4-1ce6805d6a6c',
  BackOfficeRunSystemAction = '76a39dfd-b3d1-4d1f-b116-ec7b58e99ae2',
  BackOfficeRunSystemReport = '15dd5969-59b6-4054-b72c-1ef41be28bb5',
  CustomerOrder = '96e8ba45-5597-429e-b038-6869f2f78b01',
  CustomerOrderLine = '0e1e6025-b980-465a-aee7-e0812f2c9560',
  DaySegment = 'a430c333-8c58-4b18-b2f2-51dd179c375a',
  DeviationItem = '62b26ca8-4c0d-4bac-a203-6c701980927e',
  DeviationObject = 'ad478c1f-2e83-4f8b-8a7e-e1f512e61e7c',
  DeviationReportTemplate = '1e4f8d0d-0b4e-49a9-8231-465259abc99c',
  DeviationReportTemplateItem = '99d15e47-6246-4a0b-b2eb-c408f937ef33',
  DeviationReportTemplateItemValue = 'e52d46fc-0bd3-477b-ac17-8dc516ccec25',
  DeviationSmartImage = '8376f394-57bf-4171-87f4-7fc3cb79cb1a',
  DeviationSmartImageRegion = '1803f157-4d9f-4f20-9497-34448fa48624',
  DeviationSmartImageRegionReportTemplate = '60277522-3382-4786-b553-740fb9989518',
  DeviationSystem = 'fa60cc1f-8900-4149-a5d2-8f0ec5d0c531',
  DeviationSystemStatus = 'cfa953bc-cf4d-4872-8d0b-c067fc14833a',
  DeviationTask = '0b2aae49-82bd-4944-92c5-43cc431845df',
  DeviationTaskReportInfo = '62b26ca8-4c0d-4bac-a203-6c701980927e',
  DeviceSolution = 'f1d0fa60-0db8-4b6b-b87f-ed41ad49daeb',
  DeviceSolutionFlowConnection = '0a806726-fcbf-49a8-8af3-51acd18c60ae',
  DeviceSolutionFlowCore = 'd285519d-8679-4709-aeb6-6ae80f0f2857',
  // DeviceSolutionFlowItem = '2cc1b250-cd9f-4890-9854-b5515b7d7dff',
  DeviceSolutionFlowPort = '5682dcb5-bad2-4d51-bc8f-14287aa66a7d',
  DeviceSolutionFlowModule = '2bccd5ee-c63b-4794-b9a6-a2a612cb6713',
  DeviceSolutionFlowItemModule = 'd700d90e-df55-4de0-8b72-fc5b310053d7',
  DeviceSolutionModule = '56169a98-139e-4923-a60f-27b7a8ed40c0',
  DeviceSolutionSet = '6875c67f-f9a5-416c-b4bc-c9e159b272cb',
  DeviceSolutionState = '5955b433-7807-4c2e-a5dc-93eb0f82244b',
  EnrollmentProfile = '56e33fdf-f4e4-4127-917d-4a34c4c9d94d',
  EnrollmentRequest = '12186861-a475-4af3-927f-9cd06fa255b9',
  Environment = '4b45a9a6-33e0-44ae-9ec1-9ae430cc749d',
  EnvironmentExternalEndpoint = '3e4de1a2-6c21-49b6-a464-7aea199b8249',
  EnvironmentService = '1506272d-899f-4359-9622-07a98fc3605c',
  InventoryOrderLine = 'ad40c37c-aa08-463d-9765-79ad3c3fc1b5',
  Language = 'b0e2aab0-1ffb-48f7-a164-dc01ae5d186a',
  Mission = 'ec6cac9b-fd85-48be-b1c2-6203f5bb2415',
  MissionActivity = 'f3e1e11c-a307-4d29-ad29-c79785426d52',
  MissionFlow = 'cb75ef0b-7664-4db7-9870-59a4e81a7c52',
  MissionFlowConnection = '3bd40a4a-abba-49a1-9035-ffcc2d0db8ef',
  MissionFlowPoint = '214d62ed-d9e9-448e-9001-f37e88bef0f0',
  MissionFlowPointTrigger = '89203726-78e9-4b3b-88b4-431ed6f906a3',
  MissionFlowTask = '42ef2ea2-b145-4c50-88f3-878e44bc3732',
  MissionFlowTaskResult = 'c3b7c4b0-57a8-4fbc-87e0-1a29e1bb4305',
  MissionObject = '1ee65493-ae6e-4cbd-9d42-908aa76f99d8',
  MissionSetting = '03b1fcde-5fbc-46b9-872f-0e35835c929d',
  MissionSolution = 'cfcfac2d-f3e2-4168-80ed-8488f711f390',
  MissionSolutionEnvironment = 'e5c41057-5f3b-4b56-8143-fdb322d97766',
  MissionSolutionSet = '79ff4f09-1098-4dc4-967e-979f7e5cd073',
  MissionStatement = 'a01847e6-9aed-41b1-b7e3-79c8c218d97a',
  OrderSolutionEnvironment = 'e4cfa916-2252-4470-9095-3a05ea81fe65',
  OrderV2Line = '4ea79dc4-6388-4ebb-a105-e453b5a38478',
  OrderV2Type = '1e3ece28-505b-4a65-a2e2-7e907acc29bb',
  Picklist = '349965bc-af6d-4474-b658-e70bbbcf646f',
  PicklistLine = 'aa773612-a1b7-4b62-8af2-b427c180eeb0',
  PicklistOrder = '5cf945fb-d946-4cb6-8587-8cb1ab10625d',
  PicklistOrderLine = 'c7ba28b6-1e5d-4d7e-a04c-39a2b338000f',
  PicklistSolutionEnvironment = '1dbd2e94-3eb8-49f7-8115-774f2995308d',
  PicklistType = '65976785-4880-4559-ac8b-d3c71857420e',
  PicklistTypeBlock = 'fd61496f-466f-437f-84f1-4022f09277da',
  ProductionPlannerPlan = 'd0c3f218-3140-46aa-9822-83dd861f9f30',
  ProductionPlannerPlanArticle = '0e1abdcf-35f8-4213-af70-769dc66e87d8',
  ProductionPlannerPlanDay = '5e03b6ea-246c-4d5d-afa8-6c81eed3d504',
  ProductionPlannerPlanDayArticle = 'c9da5aad-df52-4e08-9dfc-de0497c0b800',
  ProductionPlannerWeekProductionIndex = 'dfc0737e-26eb-4e4d-9d06-c18227212e37',
  ProductionPlannerSolutionEnvironment = '476931c8-3cf6-478e-84ba-42b3e734cf52',
  PurchaseOrder = '00549546-bacd-48dd-9d8f-19928a3a291d',
  PurchaseOrderLine = '67c1da6f-87c8-45d3-9e6e-8add8b454082',
  Queue = '341bc198-37c1-4457-8bf9-4bb312d98bfd',
  QueueState = '88ec06c4-3d96-4e04-83bd-e708a7813b01',
  ResourceFolder = '12932aa0-9141-42ef-9241-4335dd63c195',
  RunStatus = '56407ccb-0997-454c-abe0-77530a767657',
  SAPOrder = '342fd0c3-6fac-43b6-b70c-0badfcd1c465',
  SAPTank = '46584696-6c3a-494a-9bdd-34579ce526d9',
  Scheduler = '20c905d5-4c39-4993-9463-fc81c073150d',
  SchedulerRun = 'd456f9c2-613a-4c0a-89e2-d0eed11bf6b4',
  SmartImage = '65be72de-0a42-4927-831c-fa962f8ed5c6',
  SmartImageObjectPoint = 'b8bcdb10-7e7b-4a59-85f1-f6d96c6aac61',
  SmartImageRegion = 'aa252598-aa92-4193-9068-9e1754d1929d',
  SolutionDataMap = 'a1e8831f-6bc5-4a15-a7d6-7f35530f1051',
  SolutionDataMapCall = '854311b1-22a0-4db6-8d4a-bd65221ab98a',
  SolutionDataType = '692ecf24-5091-4b36-8064-6809fc32f7d6',
  SorterLogic = 'a4101ea3-455a-454d-a808-4e5c9a3a156d',
  StockTransaction = 'b3fa46aa-9220-4f47-a2ef-cdd7060fed46',
  StockTransactionWarehouseLocationArticle = '853cb6ac-3795-4c59-a675-fde2864ea4b6',
  SubKey = 'f6caa869-3d6b-4622-9050-fbed2bbead34',
  SystemVersion = 'ce87e080-c27b-4c97-b746-ba6cb2a5baf3',
  Task = '9f4ff049-de41-496d-ba3b-489516a308b1',
  TaskExternalShare = '87f64163-71bd-42b7-9f83-6523d7cce72f',
  TaskReport = '8e2b4300-dbb0-4c5c-a551-e2bf3eae7f7d',
  TaskReportInfo = '4214b462-cb9b-474b-be15-ed2081979cef',
  TaskSystem = '407fe01a-79d7-4545-9c7f-934fd0f8b2ec',
  TaskSystemReportTemplate = 'cfa953bc-cf4d-4872-8d0b-c067fc14833a',
  TaskSystemStatus = 'e2c69742-f2f8-47b9-a3ad-b85d74bba474',
  TaskSolutionEnvironment = 'ded5a1b0-18b8-480b-8a97-4a1b3ee4855f',
  TaskTemplateOrder = '407fe01a-79d7-4545-9c7f-934fd0f8b2ec',
  TopFuelTankWeekConsumption = '758ed1bf-6a79-4642-b0c4-1a3792a41eaa',
  TemplateSolution = 'c76fce80-a280-4efb-a2f8-e868b05f1032',
  Trigger = 'ea29fb9b-cac7-45a8-a60b-844430a71ec4',
  TriggerObject = 'd8e1c10b-b6e0-413b-93f0-fa43538c9d64',
  TriggerSolutionEnvironment = '6165f558-52f9-4b75-afce-43e2e57cb6a7',
  TriggerTarget = '248de094-17e3-4151-aa97-5df377b3be1a',
  ViewDesign = 'b45e27c4-2c3b-4e6b-a9cb-5749235b3f17',
  ViewDesignAgGrid = '79b89d80-ac53-4ff6-a554-0abac6e69dd4',
  ViewEnvironment = 'fb24fae6-bd2b-45a6-8496-c51066a9fe1b',
  Warehouse = '26ef1d4f-19ed-475b-8c56-bf94c1522eff',
  WarehouseLocation = '7d15651b-835d-4745-ac60-6162da964312',
  WarehouseLocationArticle = 'c82c075d-7dd3-460b-8334-9d21e52d82fc',
  WarehouseLocationArticleReservation = '3ff1948d-d599-4e2a-98a4-df3e3aeeba00',
  WarehouseReservationStrategy = 'd6b736a9-d8e0-4e45-8a5e-3bd8f317c775',
  WarehouseReservationStrategyRule = '385d459b-3e94-44e1-a8e6-f69b15b18dc3',
  WarehouseStockPlan = '290413d9-9246-467a-8495-aaea7f335a1c',
  WarehouseStockPlanAction = '9b6b8cfc-b04a-423c-87d6-934ea8142e82',
  WarehouseStockPlanActionTransfer = '07fb3c93-7e5b-4efe-b8d0-84ddbf5b7488',
  WarehouseStockStrategy = '652b1080-3025-419b-8bf7-24ee0002ccd6',
  WarehouseStockStrategyOrderPoint = '4245a0ac-0d58-453e-a10c-0f955dbb6e5e',
  WarehouseSite = '45d546a5-068c-4523-a90b-2a14f38a0fef',
  WarehouseSolutionEnvironment = '0d8a5500-5d75-402b-abae-bfe53cdcd86c',
  WarehouseZone = 'c09f29e2-8773-4ebe-a4d8-6bc9176f6763',
  Workspace = '7b64cc61-8516-4944-ad2b-6196bf3fcbe9',
  WorkSystem = 'c4afbdf1-9bf2-417d-916a-e1566a1b3fe3',
  WorkSystemSolutionEnvironment = 'd7f6e963-c328-4e62-aa15-1dd94313293a',
}
