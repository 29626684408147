import { WebCenterTypeMember } from './web-center-type-member.model';

export enum WebCenterTypeRelationNewAttribute {
  None = 0,
  Parent = 1, 
  Environment = 2, 
  User = 3, 
  Site = 4, 
}

export class WebCenterTypeRelation {
  typeGuidId: string;
  typeTreeRelationGuidId: string;
  name: string;
  parentTypeGuidId: string;
  childTypeGuidId: string;
  collectionRelation: boolean;
  relationMembers: WebCenterTypeMember[];
  fallbackRelationTypeRelationGuidId: string;
  lazyRelation: boolean;
  web2Access: number;
  web2LazyLoad: boolean;
  web2NewAttribute: WebCenterTypeRelationNewAttribute;
  web2SpecialRelationFilter: any;
  web2Relation: boolean;

  constructor(item?: Partial<WebCenterTypeRelation>) {
    Object.assign(this, item);
  }
}

