import { DataResponse } from './data-response.model';

export class CommitResponse {
  commited: boolean;
  commitTick: number;
  hasSystemTask: boolean;
  systemTaskReason: string;
  systemTaskGuidId: string;
  webSources: DataResponse;

  $systemTaskComplete: boolean;
  $systemTaskCompleteAndEmitted: boolean;

  constructor(item?: any) {
    Object.assign(this, item);

    if (this.webSources) {
      this.webSources = new DataResponse(this.webSources);
    }
  }

}
