import { Injectable } from '@angular/core';
import { Commit, CommitResponse, NewResponse, SystemTaskStatus } from 'app/shared/models';
import { Observable, of } from 'rxjs';
import { delay, expand, map, takeWhile } from 'rxjs/operators';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { LookupService } from '../lookup/lookup.service';


@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  private urlSuffixPlaceholder = 'object/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
    private lookupService: LookupService,
  ) { }

  new(typeGuidId: string, toSiteGuidId: string, parentGuidId?: string): Observable<NewResponse> {
    return this.apiService.post<NewResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      {
        typeGuidId: typeGuidId,
        toSiteGuidId: toSiteGuidId,
        parentGuidId: parentGuidId,
      }
    )
    .pipe(
      expand(this.handleExpand.bind(this), 1),
      takeWhile(this.handleTakeWhile.bind(this)),
      map(this.handleMap.bind(this)),
    );
  }

  move(guidId: string, fromSiteGuidId: string, toSiteGuidId: string): Observable<CommitResponse> {
    return this.apiService.post<CommitResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'move'),
      {
        guidId: guidId,
        fromSiteGuidId: fromSiteGuidId,
        toSiteGuidId: toSiteGuidId,
      }
    )
    .pipe(
      expand(this.handleExpand.bind(this), 1),
      takeWhile(this.handleTakeWhile.bind(this)),
      map(this.handleMap.bind(this)),
    );
  }

  delete(guidId: string): Observable<CommitResponse> {
    return this.apiService.post<CommitResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'delete'),
      {
        guidId: guidId,
      }
    )
    .pipe(
      expand(this.handleExpand.bind(this), 1),
      takeWhile(this.handleTakeWhile.bind(this)),
      map(this.handleMap.bind(this)),
    );
  }

  update(commit: Commit): Observable<CommitResponse> {
    return this.apiService.post<CommitResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'update'),
      commit
    )
    .pipe(
      expand(this.handleExpand.bind(this), 1),
      takeWhile(this.handleTakeWhile.bind(this)),
      map(this.handleMap.bind(this)),
    );
  }

  private handleExpand(commitResponse: CommitResponse): Observable<CommitResponse> {
    if (commitResponse.hasSystemTask && !commitResponse.$systemTaskComplete) {
      return this.apiService.post<SystemTaskStatus>(
        'systemtask/status',
        {
          systemTaskGuidId: commitResponse.systemTaskGuidId
        }
      ).pipe(
        delay(1000),
        map((systemTaskStatus: SystemTaskStatus) => {
          commitResponse.$systemTaskComplete = systemTaskStatus.complete || systemTaskStatus.exception;
          return commitResponse;
        })
      );
    } else {
      commitResponse.$systemTaskCompleteAndEmitted = true;
      return of(commitResponse);
    }
  }

  private handleTakeWhile(commitResponse: CommitResponse): boolean {
    return (!commitResponse.hasSystemTask && !commitResponse.$systemTaskCompleteAndEmitted) ||
    (commitResponse.hasSystemTask && !commitResponse.$systemTaskCompleteAndEmitted);
  }

  private handleMap(cr: CommitResponse): CommitResponse {
    if (cr.webSources) {
      cr = new CommitResponse(cr);
      if (cr.webSources.webDataSources) {
        this.lookupService.setDataSource(cr.webSources.webDataSources);
      }
      if (cr.webSources.webTreeSources) {
        this.lookupService.setTreeSource(cr.webSources.webTreeSources);
      }
    }
    return cr;
  }

}
