import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CenterType } from '../../models';
import { ApiCenterService } from '../api/api-center.service';
import { LookupService } from '../lookup/lookup.service';


@Injectable({
  providedIn: 'root'
})
export class CenterTypeService {
  private urlSuffix = 'api/v0/types';

  constructor(
    private apiService: ApiCenterService,
    private lookupService: LookupService,
  ) { }

  get(typeGuidId: string): Observable<CenterType> {
    const cachedLogicType = this.lookupService.getLogicType(typeGuidId);
    if (cachedLogicType) {
      return of(cachedLogicType);
    } else {
      return this.apiService.post<CenterType>(
        this.urlSuffix,
      {
        typeGuidId: typeGuidId
      })
      .pipe(
        map((obj: CenterType) => {
          const type = new CenterType(obj);

          this.lookupService.setLogicType(type);
          return type;
        })
      );
    }
  }
}
