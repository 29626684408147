import { Injectable } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';
import { JsonUtils } from 'app/shared/utils';




@Injectable()
export class TranslateJsonParser extends TranslateParser {

  templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  constructor(
  ) {
    super();
  }

  interpolate(expr: string | Function, params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  getValue(translations: any, key: string): any {
    if (!this.isDefined(translations)) return key;
    if (!this.isDefined(key)) return key;
    if (key.indexOf('.') >= 0 && !this.isDefined(translations[key])) {
      return JsonUtils.deepFind(translations, key) || key;
    }

    return translations[key] || key;
  }

  private interpolateFunction(fn: Function, params?: any) {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any) {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      let r = params ? params[b] : undefined;
      return this.isDefined(r) ? r : substring;
    });
  }

  private isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
  }
}