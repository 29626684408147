import { ServerPort } from '../server-port.model';
import { VariableToolbox } from '../variable-toolbox/variable-toolbox.model';
import { FlowBase } from './flow-base.model';
import { FlowConnection } from './flow-connection.model';
import { FlowModule } from './flow-module.model';
import { FlowPoint } from './flow-point.model';
import { FlowPortPointer } from './flow-port-pointer.model';
import { FlowPort } from './flow-port.model';



export enum FlowType {
  Unknown = 0,
  Flow = 1,
  FlowItem = 2,
  FlowProcess = 3,
}

export class Flow extends FlowBase {

  comment: string;
  connections: FlowConnection[];
  flowType: FlowType;
  info: FlowBase;
  modules: FlowModule[];
  points: FlowPoint[];
  ports: FlowPort[];

  // variables: any[]; // no longer used...
  entryPorts: ServerPort[]; // TEMPORARY...only used for some webinar thingy...


  variableToolbox: VariableToolbox;

  constructor(item?: any) {
    super(item);

    this.info = new FlowBase(this.info);
    this.modules = (this.modules || []).map((x: FlowModule) => {
      return new FlowModule(x);
    });
    this.points = (this.points || []).map((x: FlowPoint) => {
      return new FlowPoint(x);
    });
    this.ports = (this.ports || []).map((x: FlowPort) => {
      return new FlowPort(x);
    });

    this.entryPorts = (this.entryPorts || []).map((x: ServerPort) => {
      return new ServerPort(x);
    });

    this.connections = (this.connections || [])
    .map((x: FlowConnection) => {
      if (x.sourcePortGuidId) {
        const fromPort = (this.ports.find((p: FlowPort) => { return p.guidId === x.sourcePortGuidId; }) || {} as any);
        const toPort = (this.ports.find((p: FlowPort) => { return p.guidId === x.targetPortGuidId; }) || {} as any);
        x.$sourcePortName = fromPort.shortName || fromPort.name;
        x.$targetPortName = toPort.shortName || toPort.name;
        return new FlowConnection(x);
      } else {
        return x;
      }
    });

    this.variableToolbox = new VariableToolbox(this.variableToolbox);
  }

  getFlowTypeString(): string {
    return FlowType[this.flowType];
  }

  updateWithChangeObjectResponse(affectedStudioObjects: FlowBase[]): boolean {
    let modified = false;
    for (const affectedObject of affectedStudioObjects || []) {
      if (this.guidId === affectedObject.flowCoreGuidId) {
        if (!affectedObject.isDeleted) {
          // I have to check if the objects already exist to add new or update existing
          if ((affectedObject as FlowModule).moduleType !== null && (affectedObject as FlowModule).moduleType !== undefined) {
            const existingObject = this.modules.find((x: FlowModule) => {
              return x.guidId === (affectedObject as FlowModule).guidId;
            });
            if (existingObject) {
              // I also need to check for deleted ports and remove them from the flow
              this.checkForDeletedPorts(existingObject, affectedObject);

              Object.assign(existingObject, affectedObject);

              // HACK for the following specific situation:
              // when creating a new OUT point in a FlowItem, patrik returns the new port
              // in the affectedStudioObjects but only for flowCoreGuidId === flowItem.guidId
              // and not for flow.guidId...so I'm adding an extra affectedObject here
              this.checkForExistingPortForNewPortPointer(existingObject, affectedStudioObjects);
            } else {
              this.modules.push(new FlowModule(affectedObject));
            }
            modified = true;
          } else if ((affectedObject as FlowPoint).pointType !== null && (affectedObject as FlowPoint).pointType !== undefined) {
            const existingObject = this.points.find((x: FlowPoint) => {
              return x.guidId === (affectedObject as FlowPoint).guidId;
            });
            if (existingObject) {
              Object.assign(existingObject, affectedObject);
            } else {
              this.points.push(new FlowPoint(affectedObject));
            }
            modified = true;
          } else if ((affectedObject as FlowPort).portType !== null && (affectedObject as FlowPort).portType !== undefined) {
            const existingObject = this.ports.find((x: FlowPort) => {
              return x.guidId === (affectedObject as FlowPort).guidId;
            });
            if (existingObject) {
              Object.assign(existingObject, affectedObject);
            } else {
              this.ports.push(new FlowPort(affectedObject));
            }
            modified = true;
          } else if ((affectedObject as FlowConnection).sourcePortGuidId !== null && (affectedObject as FlowConnection).sourcePortGuidId !== undefined) {
            const existingObject = this.connections.find((x: FlowConnection) => {
              return x.guidId === (affectedObject as FlowConnection).guidId;
            });
            if (existingObject) {
              Object.assign(existingObject, affectedObject);
            } else {
              this.connections.push(new FlowConnection(affectedObject, this.ports));
            }
            modified = true;
          }
        } else {
          // deleted object
          if ((affectedObject as FlowModule).moduleType !== null && (affectedObject as FlowModule).moduleType !== undefined) {
            const existingObjectIndex = this.modules.findIndex((x: FlowModule) => {
              return x.guidId === (affectedObject as FlowModule).guidId;
            });
            if (existingObjectIndex) {
              this.modules.splice(existingObjectIndex, 1);
            } else if ((affectedObject as FlowPoint).pointType !== null && (affectedObject as FlowPoint).pointType !== undefined) {
              const existingObjectIndex = this.points.findIndex((x: FlowPoint) => {
                return x.guidId === (affectedObject as FlowPoint).guidId;
              });
              if (existingObjectIndex) {
                this.points.splice(existingObjectIndex, 1);
              }
            } else if ((affectedObject as FlowPort).portType !== null && (affectedObject as FlowPort).portType !== undefined) {
              const existingObjectIndex = this.ports.findIndex((x: FlowPort) => {
                return x.guidId === (affectedObject as FlowPort).guidId;
              });
              if (existingObjectIndex) {
                this.ports.splice(existingObjectIndex, 1);
              }
            } else if ((affectedObject as FlowConnection).sourcePortGuidId !== null && (affectedObject as FlowConnection).sourcePortGuidId !== undefined) {
              const existingObjectIndex = this.connections.findIndex((x: FlowConnection) => {
                return x.guidId === (affectedObject as FlowConnection).guidId;
              });
              if (existingObjectIndex) {
                this.connections.splice(existingObjectIndex, 1);
              }
            }
          }
        }
      }
    }

    return modified;
  }

  private checkForDeletedPorts(existingObject: FlowModule, affectedObject: FlowBase) {
    const deletedPorts = existingObject.portPointers
    .filter((epp: FlowPortPointer) => {
      return !(affectedObject as FlowModule).portPointers.some((app: FlowPortPointer) => {
        return epp.webPortGuidId === app.webPortGuidId;
      });
    });
    for (const dp of deletedPorts) {
      this.ports = this.ports.filter((p: FlowPort) => {
        return p.guidId !== dp.webPortGuidId;
      })
    }
  }

  private checkForExistingPortForNewPortPointer(existingObject: FlowModule, affectedObjects: FlowBase[]) {
    for (const portPointer of existingObject.portPointers || []) {
      const existingPort = this.ports.find((p: FlowPort) => {
        return p.guidId === portPointer.webPortGuidId;
      });
      if (!existingPort) {
        // try to find it in the affectedObjects... most likely it is associated with the flowItem and not flow
        let orphanedPort = ((affectedObjects || []) as FlowPort[])
        .find((p: FlowPort) => {
          return p.guidId === portPointer.webPortGuidId &&
            p.flowCoreGuidId !== this.guidId;
        });
        if (orphanedPort) {
          orphanedPort = new FlowPort(orphanedPort);
          orphanedPort.flowCoreGuidId = this.guidId;
          affectedObjects.push(orphanedPort);
        }
      }
    }
  }

}
