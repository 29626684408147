
export enum ServerPortType {

}

export class ServerPort {

  guidId: string;
  name: string;
  serverPortCollectionGuidId: string;
  originalPortGuidId: string;
  serverRef: string;
  serverPortType: ServerPortType;
  isDeleted: boolean;


  $serverPortCollectionName?: string;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
