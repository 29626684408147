import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowPoint, NewPointRequest } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class PointService {

  private urlSuffixPlaceholder = 'api/point/{what}';

  constructor(private apiService: ApiStudioService) { }

  new(newRequest: NewPointRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowPoint(far.sourceObject);
        }
        return far;
      })
    );
  }

}
