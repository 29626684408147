import { SolutionType } from './solution-type.model';


export class DeviceSolutionType extends SolutionType {

  constructor(item?: any) {
    super(item);
  }

  static new(
    solutionProfileGuidId: string,
    centerTypeGuidId: string,
    name: string,
  ) {
    const newItem = new SolutionType();

    newItem.solutionProfileGuidId = solutionProfileGuidId;
    newItem.baseSolutionTypeGuidId = centerTypeGuidId;
    newItem.name = name;

    return newItem;
  }

}
