import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlowAffectedResponse } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  private urlSuffixPlaceholder = 'api/object/{what}';

  constructor(private apiService: ApiStudioService) { }

  delete(guidIds: string[]): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'delete'),
      {
        guidIds: guidIds
      }
    );
  }

}
