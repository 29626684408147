
<p-dialog styleClass="select-center-type-dialog"
  [header]="'Select a CenterType:' | translate"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="isVisible">
  <form #form="ngForm"
    (ngSubmit)="submit(form)"
    novalidate>

    <lc-form-field *ngIf="reportTypeOptions"
      appendTo="body"
      [editMode]="true"
      [label]="'Report Type' | translate"
      name="reportType"
      [(ngModel)]="reportType"
      [options]="reportTypeOptions"
      required
      type="dropdown">
    </lc-form-field>

    <lc-form-field appendTo="body"
      [disabled]="!centerTypeOptions?.length"
      [editMode]="true"
      [label]="'Center Type' | translate"
      name="centerTypeGuidId"
      [(ngModel)]="centerTypeGuidId"
      (ngModelChange)="centerTypeChanged($event)"
      [options]="centerTypeOptions"
      [placeholder]="!centerTypeOptions?.length ? ('Loading...' | translate) : ''"
      required
      type="dropdown">
    </lc-form-field>

    <lc-form-field *ngIf="!hideName"
      [editMode]="true"
      [label]="'Name' | translate"
      name="name"
      [(ngModel)]="name"
      required
      type="text">
    </lc-form-field>
  </form>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      (click)="dismiss()"
      [label]="'Cancel' | translate"
      type="secondary">
    </lc-button>

    <lc-button
      (click)="form.ngSubmit.emit();"
      [disabled]="form.invalid"
      [isFormSubmit]="true"
      [label]="'Ok' | translate"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
