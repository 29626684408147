import { GuidUtils } from 'app/shared/utils';
import { FlowConnection } from './flow/flow-connection.model';


export class ConnectionData {

  guidId: string;
  from: string;
  to: string;
  fromPort: string;
  toPort: string;

  fromPortName?: string;
  toPortName?: string;

  constructor(
    connection: FlowConnection,
  ) {
    this.guidId = connection.guidId;
    this.from = GuidUtils.isNullOrEmpty(connection.sourcePointGuidId) ? connection.sourceModuleGuidId : connection.sourcePointGuidId;
    this.to = GuidUtils.isNullOrEmpty(connection.targetPointGuidId) ? connection.targetModuleGuidId : connection.targetPointGuidId;
    this.fromPort = connection.sourcePortGuidId;
    this.toPort = connection.targetPortGuidId;
    this.fromPortName = connection.$sourcePortName;
    this.toPortName = connection.$targetPortName;
  }

}
