import { DesignStyle } from './design-style.model';
import { Design } from './design.model';

export class DesignListResponse {
  designs: Design[];
  designStyles: DesignStyle[];

  constructor(item?: any) {
    if (item) {
      this.designs = (item.designs || []).map((d: Design) => {
        return new Design(d);
      });
      this.designStyles = (item.designStyles || []).map((ds: DesignStyle) => {
        return new DesignStyle(ds);
      });
    }
  }
}
