export enum SolutionCommandType {

  // AddToHead = '729dfe18-80bf-4d81-81ce-7462a16a3828', // moved to be a module instead
  Build = 'addf019f-db89-466d-a374-8de05d1293f8',
  Combine = '8421e66a-37de-409d-8531-bcbb675a075d',
  Compare = 'f486745a-e418-4e3f-9b23-b603c3031229',
  DownloadSql = '32533fe2-315e-476b-bbb2-2ecbceb48dd5',
  GetObject = 'f6984ea6-1958-45cb-91a7-ddbe64a28bd5',
  GetObjectList = '496256d9-6f62-48bc-ab85-50f73d98292e',
  GetSodraBarcode = 'b6a588b1-e05e-4de3-9d23-355e94da8d18',
  HasPickerPicklist = 'e814a864-27be-4ad3-b4cc-e74375713144',
  ListCount = '6d121d60-77df-41c9-ac36-fca2ca0f98aa',
  ListFind = '18b136c0-94ce-4a3a-9ea2-e495b8b7c8ca',
  ListOperation = '766d55f5-53aa-4a30-8b4d-d2af112976a3',
  MatchFeature = 'd3695137-1da4-4ad6-8c65-ad93166212d8',
  Math = 'a6a60988-e58c-4a94-91d4-5e5c5ef3ff05',
  MonitorApiLogon = 'd61700d2-1951-47f2-bccd-09630f03e812',
  MonitorApiLogoff = 'e90d565f-beaa-4be8-8c16-28a091110906',
  MonitorGetOperation = '093bd65a-18db-4019-932d-300b271f1483',
  MonitorGetPart = '27ac4e53-b8da-46ad-951c-146c99f8a7b6',
  MonitorInventory = '06b23989-9296-400f-a748-c57de52a6dfd',
  MonitorMoveBalance = '021ace09-d6e0-4549-8ddf-f884d465d176',
  MonitorReportOperation = '302dcf5d-85da-4b1b-90bb-8d259af69231',
  MultiCompare = '5fb12248-7964-435b-9ebb-b88c30b8eae0',
  MultiSet = '8597a19d-b0c2-4be0-9dcc-f5151369b0aa',
  NewObject = 'a9fb4cfd-1117-4a05-8a75-92fadd554188',
  ParseSodraBarcode = 'd14e12cf-8f9c-47bb-9924-d49239873ebd',
  PausePicklist = 'bb7bcb0d-9fd4-4982-94f7-153669926567',
  // RemoveFromHead = '9d898886-a7a8-4059-a261-f99f0c3ddcbc', // moved to be a module instead
  RequestPicklistTypes = '7412e0c6-0c66-4017-85a2-3eb05adac8f0',
  RequestPicklist = '1bd13173-ec5e-4a3b-a591-c415e21bad0c',
  SendEmail = '47b85934-90e5-4536-8880-b05e39deb102',
  Set = 'e2796115-3d4d-4a94-8585-48826ae41342',
  SetFeature = '2d4a69ce-ca2f-4c55-ad89-6877b8e59625',
  StartObserve = '7141f513-0859-4e08-86a8-14caec9d4f2a',
  StopObserve = '04eeaa96-f45d-4ea9-ac40-95e1a674ced2',
  ToZapier = 'b6087923-384f-4181-8a91-4d0f141a6941',
  UserAuth = '94a52a38-75cb-43cf-90a7-8a65e191765f',
  VariableNew = '993b05fb-5db2-4415-b751-3fdfec591cc0',
  VinValidation = '2c1bac52-c5de-4561-be7c-d80c945bb7d6',

}