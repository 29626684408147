import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AppService } from '../app/app.service';




@Injectable({
  providedIn: 'root'
})
export class PluginLoaderService {

  private plugins = {
    cortex: {
      loaded: false,
      src: [
        'assets/plugins/cortex/CortexDecoderWeb.js',
      ],
    },
    fontSen: {
      loaded: false,
      src: [
        'https://fonts.googleapis.com/icon?family=Sen:400,600,800',
      ],
    },
    leaflet: {
      loaded: false,
      src: [
        'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css',
        'assets/plugins/leaflet/gps-trace.css',
        //'https://maps.googleapis.com/maps/api/js?key=AIzaSyDttXaUzI-5l2zwH3LCGumSn-sTb-HpaoA',
        'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js',
        'https://unpkg.com/leaflet.gridlayer.googlemutant@latest/dist/Leaflet.GoogleMutant.js',
        'assets/plugins/leaflet/svg-icon.js',
        'assets/plugins/leaflet/paginator.js',
        'assets/plugins/leaflet/gps-trace.js',
        'assets/plugins/leaflet/rastercoords.js',
      ],
    },
    leafletDraw: {
      loaded: false,
      src: [
        'https://unpkg.com/leaflet-draw@1.0.4/dist/leaflet.draw.css',

        'https://unpkg.com/leaflet-draw@1.0.4/dist/leaflet.draw.js',
      ],
    },
    leafletTurf: {
      loaded: false,
      src: [
        'https://api.tiles.mapbox.com/mapbox.js/plugins/turf/v2.0.0/turf.min.js'
      ],
    },
    quill: {
      loaded: false,
      src: [
        'https://unpkg.com/quill@1.3.7/dist/quill.core.css',
        'https://unpkg.com/quill@1.3.7/dist/quill.snow.css',
        'https://unpkg.com/quill@1.3.7/dist/quill.min.js',
      ],
    },
    signaturePad: {
      loaded: false,
      src: [
        'https://cdn.jsdelivr.net/npm/signature_pad@4.1.6/dist/signature_pad.umd.min.js',
      ],
    },
    stimulsoft: {
      loaded: false,
      src: [
        'assets/plugins/stimulsoft/stimulsoft.designer.office2013.whiteblue.css',
        'assets/plugins/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css',
        'assets/plugins/stimulsoft/stimulsoft.reports.js',
        'assets/plugins/stimulsoft/stimulsoft.designer.js',
        'assets/plugins/stimulsoft/stimulsoft.viewer.js',
      ],
    },
  };

  constructor(
    private appService: AppService,
  ) {

  }

  load(pluginKey: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const plugin = this.plugins[pluginKey];
      if (!plugin.loaded) {
        plugin.loaded = true;

        const stylesHostElement = document.querySelector('head');
        const scriptsHostElement = document.querySelector('#plugin-scripts');

        if (typeof plugin.src === 'string') {
          this.loadScripts(stylesHostElement, scriptsHostElement, [plugin.src], observer);
        } else {
          this.loadScripts(stylesHostElement, scriptsHostElement, plugin.src, observer);
        }
      } else {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        });
      }
    });
  }

  private loadScripts(stylesHostElement: any, scriptsHostElement: any, scripts: string[], observer: Observer<boolean>): void {
    if ((scripts || []).length > 0) {
      const src = scripts.splice(0, 1)[0];
      let injectElement = null;
      if (src.indexOf('.js') >= 0) {
        injectElement = document.createElement('script');
        injectElement.type = 'text/javascript';
        injectElement.src = src;

        scriptsHostElement.appendChild(injectElement);
      } else if (src.indexOf('.css') >= 0 || src.indexOf('fonts.googleapis.com') >= 0) {
        injectElement = document.createElement('link');
        injectElement.rel = 'stylesheet';
        injectElement.href = src;

        stylesHostElement.appendChild(injectElement);
      }

      if (injectElement) {
        injectElement.onload = () => {
          this.loadScripts(stylesHostElement, scriptsHostElement, scripts, observer);
        }
        injectElement.onerror = (error: any) => {
          console.error(`error loading ${src}`);
          observer.next(false);
          observer.complete();
        };
      }
    } else {
      observer.next(true);
      observer.complete();
    }
  }
}
