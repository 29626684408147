export const environment = {
  name: 'zoey',
  production: true,

  apiStatusUrl: 'https://wapi.logiccenter.com:1810/', // StatusWebAPI Production
  apiCenterUrl: 'https://webapiv2.logiccenter.com/', // CenterWebAPI Production
  apiCenterV2Url: 'https://centerapiv2.logiccenter.com/', // CenterWebAPI V2 Production
  apiReportUrl: 'https://wapi.logiccenter.com:1814/', // ReportWebAPI Production
  apiStudioUrl: 'https://wapi.logiccenter.com:1812/', // StudioWebAPI Production

  agGridLicenseKey: 'CompanyName=MobileLogic Sweden AB,LicensedApplication=LogicCenter,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=3,AssetReference=AG-037261,SupportServicesEnd=24_March_2024_[v2]_MTcxMTIzODQwMDAwMA==b1787252782ac39d2263a355c31e3223',
  appInsightsKey: '7eccb8a9-49e7-4125-bb60-61bd1ac18701',
  goJsLicenseKey: '298647e1b66643c702d90676423d6bf919a57a63c8841ea35d0414f6e9083c1d729dee7105d6dec287f848fb1d7fc1cbcdc36c7ac24c0c68b565d7de47e2d3a9b73276bb130c1388f4032fc09ff87ef2f87c75e0d2b322f2dc78dea2eabad39f5debf6cb4fd50dbd',
  googleApiKey: 'AIzaSyA79VviLKkr9Ot5YETvKQZS8lTGsLDXZn0',
  sentryDNS: 'https://40ac81a42da643bfa57c14cad126f682@sentry.io/1323950',
};
