import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { Events, IStatusPanelParams } from 'ag-grid-community';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { WebPointerStatus } from 'app/center-v2/shared/models/web-base/web-pointer-status.enum';


@Component({
  selector: 'lc-grid-statusbar-row-count',
  templateUrl: 'grid-statusbar-row-count.component.html',
  styleUrls: ['grid-statusbar-row-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridStatusBarRowCountComponent implements IStatusPanelAngularComp {

  private params: IStatusPanelParams;

  rowCount: string;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  // The init(params) method is called on the status panel once upon component initialisation.
  agInit(params: IStatusPanelParams): void {
    this.params = params;

    this.params.api.addEventListener(Events.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));
  }

  private onDataChanged() {
    const filteredRowCount = this.getFilteredRowCountValue();
    let displayValue: any = this.getTotalRowCountValue();

    if (filteredRowCount !== displayValue) {
      displayValue = `${filteredRowCount} of ${displayValue}`;
    }

    this.rowCount = displayValue;

    this.cdr.markForCheck();
  }

  private getTotalRowCountValue(): number | string {
    if (!(this.params.api as any).serverSideRowModel) {
      let totalRowCount = 0;
      this.params.api.forEachLeafNode((node) => {
        const rowHasValues = Object.keys(node.data || {}).length > 0 &&
          Object.keys(node.data).some((key: string) => { return node.data[key] != null; }) &&
          node.data?.web2Status !== WebPointerStatus.Remove;
        return totalRowCount += rowHasValues ? 1 : 0;
      });
      return totalRowCount;
    } else {
      return this.params.api.getDisplayedRowCount() || '?';
    }
  }

  private getFilteredRowCountValue(): number | string {
    if (!(this.params.api as any).serverSideRowModel) {
      let filteredRowCount = 0;
      this.params.api.forEachNodeAfterFilter((node) => {
        if (!node.group) {
          const rowHasValues = Object.keys(node.data || {}).length > 0 &&
            Object.keys(node.data).some((key: string) => { return node.data[key] != null; }) &&
            node.data?.web2Status !== WebPointerStatus.Remove;
          filteredRowCount += rowHasValues ? 1 : 0;
        }
      });
      return filteredRowCount;
    } else {
      return this.params.api.getDisplayedRowCount() || '?';
    }
  }

}