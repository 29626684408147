import { Permission, PermissionAccess } from '../models';


export class PermissionUtils {
  static hasReadAccess(permissions: Permission[], path: string): boolean {
    return this.checkPermission(permissions, path, PermissionAccess.Read, PermissionAccess.NoAccess);
  }

  static hasWriteAccess(permissions: Permission[], path: string): boolean {
    return this.checkPermission(permissions, path, PermissionAccess.ReadWrite, PermissionAccess.NoAccess);
  }

  private static checkPermission(permissions: Permission[], path: string, desiredPermission: PermissionAccess, defaultPermission: PermissionAccess): boolean {
    if (permissions && permissions.length > 0) {
      let pathSplit: string[] = path.split('|');
      let levelName: string = pathSplit[0];
      if (pathSplit.length > 1) {
        for (let permission of permissions) {
          if (permission.valueName === levelName || permission.valueGuidId === levelName) {
            return this.checkPermission(permission.permissions, pathSplit.slice(1).join('|'), desiredPermission, permission.access);
          }
        }
      } else {
        for (let permission of permissions) {
          if (permission.valueName === levelName || permission.valueGuidId === levelName) {
            return permission.access === desiredPermission;
          }
        }
      }
    }
    return defaultPermission === desiredPermission;
  }

  static getPermission(permissions: Permission[], path: string): Permission {
    if (permissions && permissions.length > 0) {
      let pathSplit: string[] = path.split('|');
      let levelName: string = pathSplit[0];
      if (pathSplit.length > 1) {
        for (let permission of permissions) {
          if (permission.valueName === levelName || permission.valueGuidId === levelName) {
            return this.getPermission(permission.permissions, pathSplit.slice(1).join('|'));
          }
        }
      } else {
        for (let permission of permissions) {
          if (permission.valueName === levelName || permission.valueGuidId === levelName) {
            return permission;
          }
        }
      }
    }
    return null;
  }
}
