import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';


@Component({
  selector: 'lc-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialog extends BaseDialog {

  @Input() appendTo: 'body' | string;

  title: string;
  message: string;

  noText: string;
  yesText: string;
  yesType: string;

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
    private translateService: TranslateService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.title = data.title;
    this.message = data.message;
    this.noText = data.noText || this.translateService.instant('No');
    this.yesText = data.yesText || this.translateService.instant('Yes');
    this.yesType = data.yesType || 'primary';
  }

  confirm() {
    this.close(true);
  }

}
