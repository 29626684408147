import { Injectable } from '@angular/core';
import { PlatformResource } from 'app/studio/models';
import { PlatformFolder } from 'app/studio/models/platform-folder.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class PlatformResourcesService {

  private urlSuffixPlaceholder = 'api/platformresource/{what}';

  private platformFolders: PlatformFolder[];

  constructor(
    private apiService: ApiStudioService,
  ) {

  }

  list(): Observable<PlatformFolder[]> {
    if (this.platformFolders?.length) return of(this.platformFolders);

    return this.apiService.post<PlatformResource[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      { }
    )
    .pipe(
      map((response: any) => {
        return (response?.platformFolders || []).map(x => new PlatformFolder(x));
      })
    );
  }

}
