
export enum SolutionFeatureStringFilterComplexOperator {
  Unknown = 0,
  In = 1,
}

export enum SolutionFeatureStringFilterGroupOperator {
    None = 0,
    And = 1,
    Or = 2,
}

export class SolutionFeatureStringFilterGroup {
  operator: SolutionFeatureStringFilterGroupOperator;
  filter: SolutionFeatureStringFilter;
}

export class SolutionFeatureStringFilter {

  isGroup: boolean;
  filterGroups: SolutionFeatureStringFilterGroup[];
  featureName: string;
  value: boolean;
  complexOperator?: SolutionFeatureStringFilterComplexOperator;
  complexValue?: string;

  constructor(item?: any) {
    Object.assign(this, item);

  }

}
