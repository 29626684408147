export class BrowserUtils {

  private static readonly HIGHEST_POSSIBLE_Z_INDEX = 2147483647;

  static scrollbarWidth: number;

  static getQueryParams(): any {
    return window.location.search.substr(1).split('&').reduce((q, query) => {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});
  }

  static getScrollbarWidth() {
    if (BrowserUtils.scrollbarWidth != null) {
      return BrowserUtils.scrollbarWidth;
    }

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    BrowserUtils.scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return BrowserUtils.scrollbarWidth;
  }

  static copyToClipboard(text: string) {
    const anyWindow = window as any;
    if (anyWindow.clipboardData && anyWindow.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return anyWindow.clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      var textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy');  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      }
      finally {
        document.body.removeChild(textarea);
      }
    }
  }


  static detectOldIE() {
    let ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …
    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    /*let trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      let rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    let edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }*/

    // other browser
    return false;
  }

  static getBrowser(): any {
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after 'OPR/'
    if ((verOffset = nAgt.indexOf('OPR/')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      // In older Opera, the true version is after 'Opera' or after 'Version'
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      // In MSIE, the true version is after 'MSIE' in userAgent
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      // In Chrome, the true version is after 'Chrome'
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      // In Safari, the true version is after 'Safari' or after 'Version'
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      // In Firefox, the true version is after 'Firefox'
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      // In most other browsers, 'name/version' is at the end of userAgent
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) !== -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(' ')) !== -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
      name: browserName,
      fullVersion: fullVersion,
      majorVersion: majorVersion,
    };
  }

  static isMac() {
    return navigator.platform.indexOf('Mac') >= 0;
  }

  static getChromeVersionString(): any {
    const cv = BrowserUtils.getChromeVersion();
    if (cv) {
      return `${cv.major}.${cv.minor}.${cv.build}.${cv.patch}`;
    } else {
      return 'N/A';
    }
  }

  static getChromeVersion(): any {
    let regExMatches = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
    if (regExMatches == null || regExMatches.length != 5) {
        return undefined;
    }
    const pieces = regExMatches.map(piece => parseInt(piece, 10));
    return {
        major: pieces[1],
        minor: pieces[2],
        build: pieces[3],
        patch: pieces[4]
    };
  }

  static getPicture (successCallback, errorCallback, options) {
    options = options || {};

    const quality = options.quality || 50;
    const destinationType = options.destinationType || 1;
    const sourceType = options.sourceType || 1;
    const targetWidth = options.targetWidth || -1;
    const targetHeight = options.targetHeight || -1;
    const encodingType = options.encodingType || 0;
    const mediaType = options.mediaType || 0;
    const allowEdit = !!options.allowEdit;
    const correctOrientation = !!options.correctOrientation;
    const saveToPhotoAlbum = !!options.saveToPhotoAlbum;
    const popoverOptions = options.popoverOptions || null;
    const cameraDirection = options.cameraDirection || 0;

    const args = [quality, destinationType, sourceType, targetWidth, targetHeight, encodingType,
        mediaType, allowEdit, correctOrientation, saveToPhotoAlbum, popoverOptions, cameraDirection];

    BrowserUtils.takePicture(successCallback, errorCallback, args)
  };

  private static takePicture(success, error, opts) {
    if (opts && opts[2] === 1) {
        BrowserUtils.capture(success, error, opts);
    } else {
        var input = document.createElement('input');
        input.style.position = 'relative';
        input.style.zIndex = BrowserUtils.HIGHEST_POSSIBLE_Z_INDEX.toString();
        input.className = 'camera-select';
        input.type = 'file';
        input.name = 'files[]';

        input.onchange = function (inputEvent) {
            var reader = new FileReader(); /* eslint no-undef : 0 */
            reader.onload = function (readerEvent) {
                input.parentNode.removeChild(input);

                var imageData = (readerEvent.target as any).result;

                return success(imageData.substr(imageData.indexOf(',') + 1));
            };

            reader.readAsDataURL((inputEvent.target as any).files[0]);
        };

        document.body.appendChild(input);
    }
  }

  private static capture(success, errorCallback, opts) {
    var localMediaStream;
    var targetWidth = opts[3];
    var targetHeight = opts[4];

    targetWidth = targetWidth === -1 ? 320 : targetWidth;
    targetHeight = targetHeight === -1 ? 240 : targetHeight;

    var video = document.createElement('video');
    var button = document.createElement('button');
    var parent = document.createElement('div');
    parent.style.position = 'relative';
    parent.style.zIndex = BrowserUtils.HIGHEST_POSSIBLE_Z_INDEX.toString();
    parent.className = 'camera-capture';
    parent.appendChild(video);
    parent.appendChild(button);

    video.width = targetWidth;
    video.height = targetHeight;
    button.innerHTML = 'Capture!';

    button.onclick = function () {
        // create a canvas and capture a frame from video stream
        var canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, targetWidth, targetHeight);

        // convert image stored in canvas to base64 encoded image
        var imageData = canvas.toDataURL('image/jpeg');
        imageData = imageData.replace('data:image/jpeg;base64,', '');

        // stop video stream, remove video and button.
        // Note that MediaStream.stop() is deprecated as of Chrome 47.
        if (localMediaStream.stop) {
            localMediaStream.stop();
        } else {
            localMediaStream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
        parent.parentNode.removeChild(parent);

        return success(imageData);
    };

    (navigator as any).getUserMedia = (navigator as any).getUserMedia ||
                            (navigator as any).webkitGetUserMedia ||
                            (navigator as any).mozGetUserMedia ||
                            (navigator as any).msGetUserMedia;

    var successCallback = function (stream) {
        localMediaStream = stream;
        if ('srcObject' in video) {
            video.srcObject = localMediaStream;
        } else {
            (video as any).src = window.URL.createObjectURL(localMediaStream);
        }
        video.play();
        document.body.appendChild(parent);
    };

    if ((navigator as any).getUserMedia) {
      (navigator as any).getUserMedia({video: { facingMode: 'environment' }, audio: false}, successCallback, errorCallback);
    } else {
      alert('Browser does not support camera :(');
    }
  }

}
