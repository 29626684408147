import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';



@Component({
  selector: 'lc-form-field-time-span-seconds',
  templateUrl: 'form-field-time-span-seconds.component.html',
  styleUrls: ['./form-field-time-span-seconds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldTimeSpanSecondsComponent),
    multi: true
  }],
})
export class FormFieldTimeSpanSecondsComponent implements ControlValueAccessor, OnInit {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;


  onChange: (x: any) => {};
  onTouched: () => {};

  value: number;
  hours: number;
  minutes: number;
  seconds: number;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }


  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.breakdownValues();

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {

  }

  breakdownValues() {
    let remain: number;

    remain = this.value || 0;
    this.hours = ~~(remain / (60 * 60));

    remain = remain - (this.hours * 60 * 60);
    this.minutes = ~~(remain / 60);

    remain = remain - (this.minutes * 60);
    this.seconds = ~~(remain);

    this.cdr.markForCheck();
  }

  setValueFromInputs() {
    this.hours = this.hours >= 0 ? this.hours : 0;
    this.minutes = this.minutes >= 0 ? this.minutes : 0;
    this.seconds = this.seconds >= 0 ? this.seconds : 0;

    this.value = (this.hours * 60 * 60) + (this.minutes * 60) + (this.seconds);

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  getValueLabel(): string {
    if (this.value != null) {
      return `Hours: ${this.hours}, Minutes: ${this.minutes}, Seconds: ${this.seconds}`;
    } else {
      return undefined;
    }
  }

}
