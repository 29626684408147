import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { SelectCenterTypeDialogModule } from 'app/shared/dialogs/select-center-type/select-center-type.module';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { CenterTypeTreeComponent } from './center-type-tree.component';




@NgModule({
  declarations: [
    CenterTypeTreeComponent,
  ],
  exports: [
    CenterTypeTreeComponent,
  ],
  imports: [
    AppSharedV2Module,
    CheckboxModule,
    RadioButtonModule,
    SelectCenterTypeDialogModule,
    TooltipModule,
    TreeModule,
  ],
})
export class CenterTypeTreeModule { }
