import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorOptions, JsonEditorMode, JsonEditorTreeNode, IError } from './models/json-editor-options.model';
import * as editor from 'jsoneditor';


@Component({
  selector: 'lc-form-field-json',
  templateUrl: 'form-field-json.component.html',
  styleUrls: ['./form-field-json.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldJsonComponent),
    multi: true
  }],
})
export class FormFieldJsonComponent implements ControlValueAccessor, OnInit {

  readonly id = Date.now();
  readonly noValueHtml = `<span class="no-value">-</span>`;

  @ViewChild('jsonEditorContainer', { static: true }) jsonEditorContainer: ElementRef;

  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() options: JsonEditorOptions;
  @Input() placeholder: string;
  @Input() required: boolean;

  @Output() change = new EventEmitter<string>();
  @Output() jsonChange = new EventEmitter<any>();

  private editor: any;
  isFocused = false;
  optionsChanged = false;
  value: any;
  valueString: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.options = new JsonEditorOptions();
    Object.assign(this.options, {
      mode: 'code',
      onEditable: () => {
        return !this.disabled;
      }
    })
  }


  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.valueString !== value) {
      this.valueString = value;
      this.value = value ? JSON.parse(value) : null;

      if (this.editor) {
        this.editor.destroy();
        this.ngOnInit();
      }

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // From ControlValueAccessor interface
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onTouched() {
  }

  onChange(x: any) {
    
  }

  onChangeModel(x: any) {
    if (this.editor) {
      try {
        const json = this.editor.get();
        this.change.emit(json);

        this.value = json ? JSON.stringify(json) : null;
        this.onChange(this.value);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  onChangeJSON(e) {
    if (this.editor) {
      try {
        this.jsonChange.emit(this.editor.get());
      } catch (e) {
        console.warn(e);
      }
    }
  }


  ngOnInit() {
    let optionsBefore = this.options;
    if (!this.optionsChanged && this.editor) {
      optionsBefore = this.editor.options;
    }

    if (!this.options.onChangeJSON && this.jsonChange) {
      this.options.onChangeJSON = this.onChangeJSON.bind(this);
    }
    if (!this.options.onChange && this.change) {
      this.options.onChange = this.onChangeModel.bind(this);
    }
    const optionsCopy = Object.assign({}, optionsBefore);

    // expandAll is an option only supported by ang-jsoneditor and not by the the original jsoneditor.
    delete optionsCopy.expandAll;

    if (!this.jsonEditorContainer?.nativeElement) {
      console.error(`Can't find the ElementRef reference for jsoneditor`);
      return;
    }

    if (optionsCopy.mode === 'text' || optionsCopy.mode === 'code') {
      optionsCopy.onChangeJSON = null;
    }
    this.editor = new editor(this.jsonEditorContainer.nativeElement, optionsCopy, this.value);

    if (this.options.expandAll) {
      this.editor.expandAll();
    }
  }

  
  collapseAll() {
    this.editor.collapseAll();
  }

  expandAll() {
    this.editor.expandAll();
  }

  focus() {
    this.editor.focus();
  }

  get(): JSON {
    return this.editor.get();
  }

  getMode(): JsonEditorMode {
    return this.editor.getMode() as JsonEditorMode;
  }

  getName(): string {
    return this.editor.getName();
  }

  getText(): string {
    return this.editor.getText();
  }

  set(json: JSON) {
    this.editor.set(json);
  }

  setMode(mode: JsonEditorMode) {
    this.editor.setMode(mode);
  }

  setName(name: string) {
    this.editor.setName(name);
  }

  setSelection(start, end) {
    this.editor.setSelection(start, end);
  }

  getSelection(): any {
    return this.editor.getSelection();
  }

  getValidateSchema(): any {
    return this.editor.validateSchema;
  }

  setSchema(schema: any, schemaRefs: any) {
    this.editor.setSchema(schema, schemaRefs);
  }

  search(query: string) {
    this.editor.search(query);
  }

  setOptions(newOptions: JsonEditorOptions) {
    if (this.editor) {
      this.editor.destroy();
    }
    this.optionsChanged = true;
    this.options = newOptions;
    this.ngOnInit();
  }

  update(json: JSON) {
    this.editor.update(json);
  }

  destroy() {
    this.editor.destroy();
  }

  isValidJson() {
    try {
      JSON.parse(this.getText());
      return true;
    } catch (e) {
      return false;
    }
  }

}
