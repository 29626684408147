export enum SpecialTab {

  SiteAddress = '2792a8ca-b55b-4f81-bbfd-0c9ec07b65e9',
  TankAddress = 'f96c0f0c-6009-4992-b209-aa39c35330f5',
  UserAddress = 'a303a35e-01c2-4ecc-aa89-ae81af16f648',
  SiteTasks = 'e36ce6e3-9c6c-4632-a4a3-97d9adf1e8cf',
  TankTasks = '5ee8e1b0-15fc-4bbd-a9c7-9da9548f6d90',
  Orders = 'ad7d87dc-f120-4ab8-9c82-784e59f78a5b',
  OrderUsers = '75fc2e29-8b23-44fe-b32b-df3cba03e7eb',
  AlarmUsers = '4353b2d2-2579-4800-8bb2-90f3401ac3cd',
  ResourceFolder = '4d903431-bbb0-4e8e-bc2c-a034ad406107',

}
