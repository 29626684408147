import { SelectItem } from 'primeng/api/selectitem';
import { FilterStringFilterGroup } from './filter-string-filter-group.model';
import { ObjectMemberValueFilter } from './object-member-value-filter.model';

export class FlatUiFilterStringFilterGroup extends FilterStringFilterGroup {

  groupIndicatorStart: boolean;
  groupIndicatorMiddle: boolean;
  groupIndicatorEnd: boolean;

  displayName: string;
  operatorItems: SelectItem[];
  fieldType: string;

  constructor(item?: Partial<FlatUiFilterStringFilterGroup>) {
    super(item);
  }

  static fromFilterStringFilterGroup(item: FilterStringFilterGroup) {
    const newItem = new FlatUiFilterStringFilterGroup(item);

    newItem.filter = new ObjectMemberValueFilter(newItem.filter as ObjectMemberValueFilter);

    const centerTypeMember = (newItem.filter as ObjectMemberValueFilter).member;
    newItem.displayName = centerTypeMember.name.replace(/ /g, '.');
    newItem.fieldType = centerTypeMember?.fieldType || '';

    switch (newItem.fieldType) {
      case 'checkbox':
        newItem.operatorItems = [
          { value: '=', label: '=' },
          { value: '!=', label: '!=' },
        ];
        (newItem.filter as ObjectMemberValueFilter).value = (newItem.filter as ObjectMemberValueFilter).value === true;
        break;
      case 'date':
      case 'number':
        newItem.operatorItems = [
          { value: '<', label: '<' },
          { value: '<=', label: '<=' },
          { value: '=', label: '=' },
          { value: '>', label: '>' },
          { value: '>=', label: '>=' },
          { value: '!=', label: '!=' },
        ];
        break;
      case 'text':
        newItem.operatorItems = [
          { value: '=', label: '=' },
          { value: '!=', label: '!=' },
        ];
        break;
      default:
        newItem.operatorItems = [
          { value: '=', label: '=' },
          { value: '!=', label: '!=' },
        ];
    }

    if (newItem.fieldType === 'date') {
      newItem.fieldType = 'date-relative';
      (newItem.filter as ObjectMemberValueFilter).member.fieldType = 'date-relative';
      (newItem.filter as ObjectMemberValueFilter).value = (newItem.filter as ObjectMemberValueFilter).value || '{{today}}+0';
    }

    return newItem;
  }

  static toFilterStringFilterGroup(item: FlatUiFilterStringFilterGroup) {
    return new FilterStringFilterGroup({
      operator: item.operator,
      filter: item.filter,
    });
  }
}
