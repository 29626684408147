import { Injectable } from '@angular/core';
import { CenterType, CommentSource, DataSource, TreeSource } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class LookupService {

  private dataSources: Map<string, DataSource>;
  private treeSources: Map<string, TreeSource>;
  private logicTypes: Map<string, CenterType>;
  private commentSources: Map<string, CommentSource[]>;

  constructor() {
    this.dataSources = new Map();
    this.treeSources = new Map();
    this.logicTypes = new Map();
    this.commentSources = new Map();
  }

  getDataSource(guidId: string): DataSource {
    return this.dataSources.get(guidId);
  }

  setDataSource(dataSource: DataSource | DataSource[]) {
    if (dataSource) {
      if (Array.isArray(dataSource)) {
        for (const ds of dataSource || []) {
          this.dataSources.set(ds.guidId, ds);
        }
      } else {
        this.dataSources.set(dataSource.guidId, dataSource);
      }
    }
  }


  getTreeSource(treeGuidId: string) {
    return this.treeSources.get(treeGuidId);
  }

  setTreeSource(treeSource: TreeSource | TreeSource[]) {
    if (treeSource) {
      if (Array.isArray(treeSource)) {
        for (const ds of treeSource || []) {
          this.treeSources.set(ds.treeGuidId, ds);
        }
      } else {
        this.treeSources.set(treeSource.treeGuidId, treeSource);
      }
    }
  }


  getLogicType(typeGuidId: string): CenterType {
    return this.logicTypes.get(typeGuidId);
  }

  setLogicType(logicType: CenterType | CenterType[]) {
    if (logicType) {
      if (Array.isArray(logicType)) {
        for (const t of logicType || []) {
          this.logicTypes.set(t.typeGuidId, t);
        }
      } else {
        this.logicTypes.set(logicType.typeGuidId, logicType);
      }
    }
  }


  getCommentSources(guidId: string): CommentSource[] {
    return this.commentSources.get(guidId);
  }

  setCommentSources(guidId: string, commentSource: CommentSource | CommentSource[]) {
    if (guidId && commentSource) {
      if (Array.isArray(commentSource)) {
        this.commentSources.set(guidId, commentSource);
      } else {
        this.commentSources.set(guidId, [commentSource]);
      }
    }
  }

}
