import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormFieldWarehouseLocationArticleTypeComponent } from './form-field-warehouse-location-article-type.component';


@NgModule({
  declarations: [
    FormFieldWarehouseLocationArticleTypeComponent,
  ],
  exports: [
    FormFieldWarehouseLocationArticleTypeComponent,
  ],
  imports: [
    AppSharedV2Module,
    MultiSelectModule,
  ],
})
export class FormFieldWarehouseLocationArticleTypeModule { }
