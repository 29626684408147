import { WebObject } from './web-object.model';
import { WebPointerStatus } from './web-pointer-status.enum';


export class WebRelationPointer {

  collection: boolean;
  collectionTypeGuidId: string;
  relation: boolean;
  relationTypeGuidId: string;
  relationGuidId: string;
  web2Status: WebPointerStatus;
  childGuidId: string;
  web2Object: WebObject;
  members: any;

  constructor(item?: Partial<WebRelationPointer>) {
    Object.assign(this, item);

    this.web2Object = this.web2Object ? new WebObject(this.web2Object) : undefined;
  }
}
