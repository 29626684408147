import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { FormFieldTimeSpanHoursComponent } from './form-field-time-span-hours.component';
import { InputTextModule } from 'primeng/inputtext';


@NgModule({
  declarations: [
    FormFieldTimeSpanHoursComponent,
  ],
  exports: [
    FormFieldTimeSpanHoursComponent,
  ],
  imports: [
    AppSharedV2Module,
    InputTextModule,
  ],
})
export class FormFieldTimeSpanHoursModule { }
