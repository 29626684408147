export enum CameraDeviceControlCaptureImageSize {
  Default = 0,
  MegaPixel1 = 1,
  MegaPixel2 = 2,
  MegaPixel3 = 3,
  MegaPixel4 = 4,
  MegaPixel5 = 5,
  MegaPixel6 = 6,
  MegaPixel7 = 7,
  MegaPixel8 = 8,
}
