
export class ArrayUtils {

  static uniqBy(array: any[], getKeyValueCallback: Function) {
    const seen = {};
    return array.filter((item: any) => {
        var k = getKeyValueCallback(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

}