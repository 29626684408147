import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCenterService } from '../../../shared/services/api/api-center.service';


@Injectable({
  providedIn: 'root'
})
export class ObjectNameService {

  private urlSuffixPlaceholder = 'api/v0/objectname';

  constructor(private apiService: ApiCenterService) { }

  get(guidId: string): Observable<string> {
    return this.apiService.post<string>(
      this.urlSuffixPlaceholder,
      {
        guidId: guidId
      }
    )
    .pipe(
      map((response: any) => {
        if (response && response.exists) {
          return response.name;
        } else {
          return null;
        }
      })
    );
  }

}
