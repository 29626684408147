import { VariableToolboxGroupItemType } from './variable-toolbox-group-item-type.enum';

export enum StateVariableInBehavior
{
  NotSet = 0,
  Inherit = 1,
  NewCopy = 2,
  New = 3,
  PortDefined = 4
}

export enum StateVariableOutBehavior {
  NotSet = 0,
  Return = 1,
  Remove = 2,
  PortDefined = 3
}

export class VariableToolboxGroupItem {
  itemType: VariableToolboxGroupItemType;
  guidId: string;
  originalStateVariableGuidId?: string;
  name: string;
  solutionTypeGuidId: string;
  behaviors?: boolean;
  inBehavior?: StateVariableInBehavior;
  outBehavior?: StateVariableOutBehavior;
  $isList: boolean;

  $memberGuidId?: string;
  $memberName?: string;
  $memberSolutionTypeGuidId?: string;
  $memberIsList?: boolean;

  $typeLabel?: string;
}
