import { Field, FlowVariable, SolutionField } from ".";
import { FieldUtils } from "../utils";
import { FlowBase } from "./flow/flow-base.model";


export class SystemEvent extends FlowBase {

  getField(fieldName: string): Field {
    let field = (this.fields || []).find((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    return FieldUtils.castField(field);
  }

  getFieldValue(fieldName: string, variables?: FlowVariable[], texts?: Map<number, string>): string {
    let field = this.getField(fieldName);
    if (field) {
      return field.getValue(variables, texts);
    } else {
      return '';
    }
  }

  getFieldVariable(fieldName: string, variables: FlowVariable[]): FlowVariable {
    let field = this.getField(fieldName);
    if (field) {
      return (field as SolutionField).getVariable(variables);
    } else {
      return null;
    }
  }

}
