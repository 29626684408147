import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';

export enum ArticleSerialnumberType {
  Quantity = 0,
  OptionalSerialnumber = 1,
  RequiredSerialnumber = 2,
}

export enum ArticleBaseType {
  Unknown = 0,
  Hardware = 1,
  Software = 2,
}

export enum ArticleQuantityDefinition {
  Unknown = 0,
  Quantity = 1,
  Time = 2,
  Length = 3,
  Sum = 4,
}

export class WebArticle extends WebObject {

  static typeGuidId = 'e5767bb0-943f-4cc8-a3c3-649f47a38c94';

  members: {
    name: string;
    articlePackageCount: number;
    image: string;
    description: string;
    lastModified: string;
    uoM: string;
    sku: string;
    stockManagement: number;
    stockType: number;
    supplierSku: string;
    serialnumberType: ArticleSerialnumberType;
    articleSerialnumberSyntax: string;
    articleGroupGuidId: string;
    articleTypeGuidId: string;
    brandGuidId: string;
    supplierGuidId: string;
    baseType: ArticleBaseType;
    quantityDefinition: ArticleQuantityDefinition;
    extraInformation: string;
    inactive: boolean;
    pnc: string;
    supplierPnc: string;
    shortName: string;
    siteGuidId: string;
    groupName: string;
    groupFullName: string;
    parentArticleGuidId: string;
    isVariant: boolean;
    ean: string;
    isStructure: boolean;
  };

  relations: {
    articleBrand?: WebRelationPointer;
    articleType?: WebRelationPointer;
    packages?: WebRelationPointer[];
    articleGroup?: WebRelationPointer;
    barcodes?: WebRelationPointer[];
    supplier?: WebRelationPointer;
    brand?: WebRelationPointer;
    smartImages?: WebRelationPointer[];
    articlesRelations?: WebRelationPointer[];
    variants?: WebRelationPointer[];
    parentArticle?: WebRelationPointer;
    primaryWarehouseLocationArticle?: WebRelationPointer;
    primaryWarehouse?: WebRelationPointer;
    stockTransactions?: WebRelationPointer[];
    warehouseLocationArticles?: WebRelationPointer[];
  };

  constructor(item?: Partial<WebArticle>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebArticle.typeGuidId;
  }

}