import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';
import { TreeNode } from 'primeng/api';
import { AppService } from '../../services';



@Component({
  selector: 'lc-select-site-dialog',
  templateUrl: './select-site.dialog.html',
  styleUrls: ['./select-site.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSiteDialog extends BaseDialog {

  @ViewChild('tree', { static: false }) tree: any;

  data: {
    accessTreeWorkspace?: boolean;
    rootObjectGuidId: string;
    selectedSiteGuidId?: string;
    title: string;
  }

  selectedTreeNode: TreeNode;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {

  }

  submit() {
    this.close(this.selectedTreeNode);
  }

  searchTree() {
    this.tree?.toggleSearch();
  }

}
