import { Injectable } from '@angular/core';
import { GetReservedUserResponse } from 'app/studio/models/get-reserved-user-response.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Solution } from '../../../shared/models';
import { DeviceSolution, ReserveSolutionResponse, ServerDeviceSolution, VariableToolbox } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  private urlSuffixPlaceholder = 'api/solution/{what}';

  solution: DeviceSolution;

  constructor(
    private apiService: ApiStudioService,
  ) { }

  getReservedUser(solutionGuidId: string): Observable<GetReservedUserResponse> {
    this.apiService.solutionGuidId = solutionGuidId;

    return this.apiService.post<GetReservedUserResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'getreserveduser'),
      { }
    );
  }

  reserve(solutionGuidId: string, readOnly: boolean): Observable<ReserveSolutionResponse> {
    this.apiService.solutionGuidId = solutionGuidId;

    return this.apiService.post<ReserveSolutionResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'reserve'),
      {
        readOnly: readOnly,
      }
    ).pipe(
      map((rsr: ReserveSolutionResponse) => {
        return this.handleRefreshReserveResponse(rsr);
      })
    );
  }

  refresh(solutionGuidId: string): Observable<ReserveSolutionResponse> {
    this.apiService.solutionGuidId = solutionGuidId;

    return this.apiService.post<ReserveSolutionResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'refresh'),
      { }
    ).pipe(
      map((rsr: ReserveSolutionResponse) => {
        return this.handleRefreshReserveResponse(rsr);
      })
    );
  }

  private handleRefreshReserveResponse(rsr: ReserveSolutionResponse) {
    rsr.variableToolbox = rsr.variableToolbox ? new VariableToolbox(rsr.variableToolbox) : undefined;
    rsr.serverDeviceSolutions = (rsr.serverDeviceSolutions || [])
    .map((item: ServerDeviceSolution) => {
      return new ServerDeviceSolution(item);
    });

    this.solution = rsr.deviceSolution;
    if (this.solution) {
      this.solution.$configurationDeviceRunCode = rsr.configurationDeviceRunCode;
      this.solution.$configurationDeviceHostUri = rsr.configurationDeviceHostUri;
      this.solution.$reserveTimeout = rsr.reserveTimeout;
      this.solution.$serverDeviceSolutions = rsr.serverDeviceSolutions;
      this.solution.$solutionFeatures = rsr.solutionFeatures;
      this.solution.$solutionTypes = rsr.solutionTypes;
      this.solution.$variableToolbox = rsr.variableToolbox;
    }

    return rsr;
  }

  recompile(): Observable<Solution> {
    return this.apiService.post<Solution>(
      this.urlSuffixPlaceholder.replace('{what}', 'recompile'),
      { }
    ).pipe(
      map((response: any) => {
        return response && response.deviceSolution ? response.deviceSolution : null;
      })
    );
  }

  commit(version: string): Observable<void> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'commit'),
      {
        version: version
      }
    );
  }

  forceRelease(solutionGuidId: string): Observable<boolean> {
    this.apiService.solutionGuidId = solutionGuidId;

    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'forcerelease'),
      { }
    );
  }

  release(solutionGuidId: string): Observable<boolean> {
    this.apiService.solutionGuidId = solutionGuidId;

    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'release'),
      { }
    ).pipe(
      map(() => {
        // even if the call fails and returns false, we want to allow the user to leave the page...
        return true;
      }),
      catchError(() => {
        // even if the call fails and returns false, we want to allow the user to leave the page...
        return of(true);
      })
    );
  }

}
