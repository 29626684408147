import { Injectable } from '@angular/core';
import { ApiStudioService } from 'app/studio/services';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class SolutionTypeService {

  private urlSuffixPlaceholder = 'api/solutiontype/{what}';

  constructor(private apiService: ApiStudioService) { }


  setInLayout(solutionTypeGuidId: string, subMemberGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'setinlayout'),
      {
        solutionTypeGuidId: solutionTypeGuidId,
        subMemberGuidId: subMemberGuidId,
      }
    )
  }

}
