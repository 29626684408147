import { FlowBase } from './flow-base.model';
import { FlowPort } from './flow-port.model';

export class FlowConnection extends FlowBase {

  style: string;
  sourceModuleGuidId: string;
  targetModuleGuidId: string;
  sourcePointGuidId: string;
  targetPointGuidId: string;
  sourcePortGuidId: string;
  targetPortGuidId: string;

  $sourcePortName: string;
  $targetPortName: string;

  constructor(item?: any, ports?: FlowPort[]) {
    super(item);

    if (ports && item.sourcePortGuidId && item.targetPortGuidId) {
      const fromPort = (ports.find((p: FlowPort) => { return p.guidId === this.sourcePortGuidId; }) || {} as any);
      const toPort = (ports.find((p: FlowPort) => { return p.guidId === this.targetPortGuidId; }) || {} as any);
      this.$sourcePortName = fromPort.shortName || fromPort.name;
      this.$targetPortName = toPort.shortName || toPort.name;
    }
  }

}
