
export class StorageUtils {

  static clear(type: 'local' | 'session'): void {
    if (type === 'local') {
      return localStorage.clear();
    } else if (type === 'session') {
      return sessionStorage.clear();
    }
  }

  static getItem(type: 'local' | 'session', key: string): string {
    if (type === 'local') {
      return localStorage.getItem(key);
    } else if (type === 'session') {
      return sessionStorage.getItem(key);
    }
  }

  static removeItem(type: 'local' | 'session', key: string): void {
    if (type === 'local') {
      localStorage.removeItem(key);
    } else if (type === 'session') {
      sessionStorage.removeItem(key);
    }
  }

  static setItem(type: 'local' | 'session', key: string, value: any): void {
    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }

      if (type === 'local') {
        localStorage.setItem(key, value);
      } else if (type === 'session') {
        sessionStorage.setItem(key, value);
      }
    } catch (error) {
      // This is pretty much here to cover the scenario where the storage gets above
      // the maximum size and it would start failing all the time if we don't free up
      // some space
      sessionStorage.clear();
    }
  }

}
