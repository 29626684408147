
export class GeoJSON {
  type: 'Feature' | 'FeatureCollection';
  features?: any[];
  geometry?: {
    type: string,
    coordinates: [number, number] | [number, number][] | [number, number][][],
  };
  properties?: any;

  constructor(item: Partial<GeoJSON>) {
    if (item) {
      Object.assign(this, item);  
    }

    this.properties = this.properties || {};
  }

  static fromLatLng(lat: number, lng: number): GeoJSON {
    return new GeoJSON({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lng, lat]
      },
      properties: {}
    });
  }

  static fromGeoposition(geoposition: any): GeoJSON {
    return new GeoJSON({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [geoposition.coords.longitude, geoposition.coords.latitude]
      },
      properties: {
        accuracy: ~~geoposition.coords.accuracy,
        altitude: ~~geoposition.coords.altitude,
        bearing: geoposition.coords.bearing,
        direction: geoposition.coords.direction,
      }
    });
  }

}
