import { Tag } from '../tag.model';
// import { VariableToolbox } from './variable-toolbox';

export enum SolutionBaseType {
  DeviceSolution = 0,
  ServerDeviceSolution = 1,
}

export enum DeviceSolutionSolutionType {
  Device = 0,
  Integration = 1,
  ServerDevice = 2,
}

export enum DataDeviceSolutionType {
  Custom = 0,
  Event = 1,
  Performance = 2,
  TemplateSolutionSystem = 3,
}

export class Solution {

  guidId: string;
  name: string;
  version: string;
  versionDateTime: number;
  subTypes: any;
  baseType: SolutionBaseType;
  tags: Tag[];

  constructor(item?: Partial<Solution>) {
    Object.assign(this, item);
  }
}
