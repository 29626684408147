import { Injectable } from '@angular/core';
import { Text } from '../../../center/shared/models/text.model';
import { textsDB } from './language-texts-db';


@Injectable({
  providedIn: 'root'
})
export class LanguageTextsService {

  // this never got implemented...using local hardcoded "db"
  private static readonly URL_SUFFIX = 'api/something/texts'; 

  private static defaultLang = 'en';
  private static currentLang = 'en';

  // Local hardcoded DB
  private static db = textsDB;

  static getTextOrDefault(guidId: string, defaultText: string): string {
    let logicText: Text = (this.db || []).find((text: Text) => {
      return  text.textGuidId === guidId && text.language === this.currentLang;
    });
    if (logicText) {
      return logicText.text;
    } else {
      // if at first you don't succeed...try again for default language
      logicText = (this.db || []).find((text: Text) => {
        return  text.textGuidId === guidId && text.language === this.defaultLang;
      });
      if (logicText) {
        return logicText.text;
      } else {
        return defaultText;
      }
    }
  }

  constructor() { }

  setDefaultLang(lang: string) {
    LanguageTextsService.defaultLang = lang;
  }

  use(lang: string) {
    LanguageTextsService.currentLang = lang;
  }

}
