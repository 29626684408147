declare var L: any;

export class LeafletUtils {

  static addGoogleMutantLayers(map: any, overlays?: any) {
    var roadMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'roadmap',
    })
    .addTo(map);

    var satMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'satellite',
    });

    var terrainMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'terrain',
    });

    var hybridMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'hybrid',
    });

    var trafficMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'roadmap',
    });
    trafficMutant.addGoogleLayer('TrafficLayer');

    var transitMutant = L.gridLayer.googleMutant({
      maxZoom: 24,
      type: 'roadmap',
    });
    transitMutant.addGoogleLayer('TransitLayer');


    L.control.layers({
      Roads: roadMutant,
      Aerial: satMutant,
      Terrain: terrainMutant,
      Hybrid: hybridMutant,
      Traffic: trafficMutant,
      Transit: transitMutant,
    },
    overlays,
    {
      position: 'topright',
    }).addTo(map);
  }

}