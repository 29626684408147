import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebArticleGroupOnNewSubTypeCollectionObject extends WebObject {

  static typeGuidId = '1852098c-dfd4-4c48-933b-134dc710c048';

  members: {

  };

  relations: {

  };

  constructor(item?: Partial<WebArticleGroupOnNewSubTypeCollectionObject>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebArticleGroupOnNewSubTypeCollectionObject.typeGuidId;
  }

}