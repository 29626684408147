import { WebSkin } from '../../center/shared/models';


export class WebSkinUtils {

  private static webSkinLogicCenterGuidId = 'dd7cc557-1e84-49a1-8e10-bd3e8d318137';
  private static webSkinKeyportalGuidId = 'b2274d06-b907-4017-87b9-567393e16093';
  private static webSkinPreemGuidId = '638f04be-1416-4284-b4f0-ecffc5a833f1';
  private static webSkinSatoGuidId = '524a5e1d-fccb-4490-98c7-502fe8261dff';
  private static webSkinServlyGuidId = '627a3490-f3c5-4414-b7e7-5feec102f37a';

  static readonly DEFAULT_SKIN: WebSkin = {
    guidId: WebSkinUtils.webSkinLogicCenterGuidId,
    appTitle: 'LogicCenter',
    theme: 'mobilelogic',
    language: 'en',
  } as WebSkin;

  // HARDCODED SKINS
  static db: WebSkin[] = [
    WebSkinUtils.DEFAULT_SKIN,
    {
      guidId: WebSkinUtils.webSkinKeyportalGuidId, // Keyportal / TopFuel
      appTitle: 'Keyportal',
      theme: 'keyportal',
      language: 'en',
      supportPage: {
        title: 'Top Fuel Support',
        routerLink: '/volymkoll/support',
      },
    },
    {
      guidId: WebSkinUtils.webSkinPreemGuidId, // Preem / Volymkoll
      appTitle: 'Preem',
      theme: 'preem',
      language: 'se',
      customLogin: 'volymkoll',
      supportPage: {
        title: 'Top Fuel Support',
        routerLink: '/volymkoll/support',
      },
    },
    {
      guidId: WebSkinUtils.webSkinSatoGuidId, // Sato
      appTitle: 'SATO',
      customLogin: 'v2/auth/sato',
      theme: 'sato',
      language: 'en',
    },
    {
      guidId: WebSkinUtils.webSkinServlyGuidId, // Servly
      appTitle: 'Servly Online',
      customLogin: 'v2/auth/servly',
      theme: 'servly',
      language: 'se',
    },
  ];
  // HARDCODED SKINS

  static getWebSkinForCurrentUrl(url: string): WebSkin {
    let webSkin: WebSkin;
    if (url.indexOf('keyportal.se') >= 0) {
      webSkin = (this.db || []).find((item) => {
        return item.guidId === WebSkinUtils.webSkinKeyportalGuidId; // Keyportal / TopFuel
      });
    } else if (url.indexOf('volymkoll.se') >= 0) {
      webSkin = (this.db || []).find((item) => {
        return item.guidId === WebSkinUtils.webSkinPreemGuidId; // Preem / Volymkoll
      });
    } else if (url.indexOf('sato.logiccenter.com') >= 0) {
      webSkin = (this.db || []).find((item) => {
        return item.guidId === WebSkinUtils.webSkinSatoGuidId; // SATO
      });
    } else if (url.indexOf('servlyonline.se') >= 0) {
      webSkin = (this.db || []).find((item) => {
        return item.guidId === WebSkinUtils.webSkinServlyGuidId; // Servly
      });
    }

    return webSkin;
  }

}
