import { SolutionFeatureStringFilter, SolutionType, SolutionTypeEnum, SolutionTypeMember, SystemComplexSolutionTypes, SystemSolutionTypes } from 'app/shared/models';
import { CaseUtils, GuidUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api';
import { PlatformResource } from '../platform-resource.model';
import { FlowVariable } from '../flow/flow-variable.model';
import { VariableToolboxGroupItem } from '../variable-toolbox';
import { Field, FieldDirection, FieldType } from './field.model';
import { ButtonsType, CameraDeviceControlCaptureImageSize, CompareType, DeviceSolutionMenuSettings, DeviceSolutionUIAttributes, KeyboardType, LoopType, MathOperation, NotifyType, ReservePicklistCommandReserveType, ScannerEnabledFlagType, ScannerTypes, SelectOperationType, SpecialValue, TextboxType, TextboxValueType, VariableCompareType, VariableListFindType, VariableListOperationType, VariableSetType, VerificationType } from './special-value-enums';
import { ClientSetting } from './special-value-enums/client-settings.enum';
import { ControlClientSortDefault } from './special-value-enums/control-client-sort-default.enum';
import { ControlClientSortSortOrder } from './special-value-enums/control-client-sort-sort-order.enum';
import { NativeKeyboard } from './special-value-enums/native-keyboard.enum';
import { OperationType } from './special-value-enums/operation-type.enum';
import { QuantityList1LineBehaviour } from './special-value-enums/quantity-list-line-behaviour.enum';
import { QuantityList1ScanBehaviour } from './special-value-enums/quantity-list-scan-behaviour.enum';
import { HybridMode } from './special-value-enums/hybrid-mode.enum';
import { HybridState } from './special-value-enums/hybrid-state.enum';
import { HybridDataSynchronizationState } from './special-value-enums/hybrid-data-synchronization-state.enum';



export const enum FieldValueType {
  NotSet = 0,
  Static = 1,
  Text = 2,
  Variable = 3,
  SolutionType = 4,
  Null = 5,
  Resource = 6,

  ScannerEnabledType = 100,
  FeatureString = 101,

  $DeviceSolutionScheduler = 200,
}

export class VariablePointer {

  constructor(
    public stateVariableGuidId?: string,
    public subMemberGuidId?: string
  ) {
  }
}

export class SolutionField extends Field {

  allowStatic: boolean;
  allowSubVariableMember: boolean;
  allowText: boolean;
  allowVariable: boolean;
  allowResource: boolean;
  allowListOnly: boolean;
  allowList: boolean;
  specialValue: SpecialValue;
  staticValue?: any;
  textId?: number;
  resourceId?: number;
  webFieldValueType: FieldValueType;

  originalVariableGuidId: string;
  variablePointer?: VariablePointer;
  isHybrid: boolean;
  hybridVariablePointers?: VariablePointer[];

  $options: SelectItem[];
  $allowSolutionType: boolean; // only used on Item field in designStyle

  constructor(field?: any) {
    super(field);

    this.webFieldType = FieldType.Solution;
    this.webFieldValueType = this.webFieldValueType || FieldValueType.NotSet;
  }


  getValue(variables?: FlowVariable[], texts?: Map<number, string>, solutionTypes?: SolutionType[]): string {
    switch (this.webFieldValueType) {
      case FieldValueType.Resource:
        return PlatformResource.resourceMap[this.resourceId];
      case FieldValueType.Static:
        return this.specialValue ? this.getSpecialFieldValue() : this.staticValue;
      case FieldValueType.Text:
        return texts.get(this.textId);
      case FieldValueType.Variable:
        const variable = this.getVariable(variables);
        return variable?.name || '!VAR_REF!';
      case FieldValueType.SolutionType:
      case FieldValueType.Null:
        if (!this.variablePointer || GuidUtils.isEqual(this.variablePointer.stateVariableGuidId, GuidUtils.emptyGuid())) {
          return 'Value';
        } else {
          const solutionType = this.getSolutionType(this.variablePointer.stateVariableGuidId, solutionTypes);
          return solutionType ? (solutionType.name || '!SOLTYPE_REF!') : '!SOLTYPE_REF!';
        }
      case FieldValueType.FeatureString:
      case FieldValueType.ScannerEnabledType:
        return this.staticValue;
      case FieldValueType.NotSet:
      default:
        return null;
    }
  }

  private getSpecialFieldValue() {
    switch (this.specialValue) {
      case SpecialValue.CameraDeviceControlCaptureSize:
        return CameraDeviceControlCaptureImageSize[this.staticValue];
      case SpecialValue.ClientSettings:
        return ClientSetting[this.staticValue];
      case SpecialValue.CompareType:
        return CompareType[this.staticValue];
      case SpecialValue.ControlClientSortDefault:
        return ControlClientSortDefault[this.staticValue];
      case SpecialValue.ControlClientSortSortOrder:
        return ControlClientSortSortOrder[this.staticValue];
      case SpecialValue.Custom:
        const item = this.$options.find((si: SelectItem) => {
          return si.value === this.staticValue;
        }) || {} as SelectItem;
        return item.label || '';
      case SpecialValue.DeviceSolutionMenuSettings:
        return DeviceSolutionMenuSettings[this.staticValue];
      case SpecialValue.DeviceSolutionUIAttributes:
        return DeviceSolutionUIAttributes[this.staticValue];
      case SpecialValue.HybridMode:
        return HybridMode[this.staticValue];
      case SpecialValue.HybridState:
        return HybridState[this.staticValue];
      case SpecialValue.HybridDataSynchronizationState:
        return HybridDataSynchronizationState[this.staticValue];
      case SpecialValue.LoopType:
        return LoopType[this.staticValue];
      case SpecialValue.MathOperation:
        return MathOperation[this.staticValue];
      case SpecialValue.NativeKeyboard:
        return NativeKeyboard[this.staticValue];
      case SpecialValue.NotifyType:
        return NotifyType[this.staticValue];
      case SpecialValue.OperationType:
        return OperationType[this.staticValue];
      case SpecialValue.QuantityList1LineBehaviour:
        return QuantityList1LineBehaviour[this.staticValue];
      case SpecialValue.QuantityList1ScanBehaviour:
        return QuantityList1ScanBehaviour[this.staticValue];
      case SpecialValue.ReservePicklistCommandReserveType:
        return ReservePicklistCommandReserveType[this.staticValue];
      case SpecialValue.SolutionDeviceControlButtonsType:
        return ButtonsType[this.staticValue];
      case SpecialValue.SolutionDeviceControlInput1KeyboardType:
        return KeyboardType[this.staticValue];
      case SpecialValue.SolutionDeviceControlScannerTypes:
        return ScannerTypes[this.staticValue];
      case SpecialValue.SolutionDeviceControlInput1TextBoxType:
        return TextboxType[this.staticValue];
      case SpecialValue.SolutionDeviceControlInput1TextBoxValueType:
        return TextboxValueType[this.staticValue];
      case SpecialValue.SolutionDeviceControlInput1VerificationType:
        return VerificationType[this.staticValue];
      case SpecialValue.SolutionDeviceControlMenu1SelectOperationType:
        return SelectOperationType[this.staticValue];
      case SpecialValue.VariableCompareCommandCompareType:
        return VariableCompareType[this.staticValue];
      case SpecialValue.VariableListFindType:
        return VariableListFindType[this.staticValue];
      case SpecialValue.VariableListOperationType:
        return VariableListOperationType[this.staticValue];
      case SpecialValue.VariableSetType:
        return VariableSetType[this.staticValue];
      // case SpecialValue.SolutionDeviceControlInput1VerificationValue:
      //case SpecialValue.SolutionDeviceControlInput1VerificationInvalidValue:
      case SpecialValue.FeatureString:
        return this.getFeatureStringValue();
      case SpecialValue.SolutionDeviceControlScannerEnabledType:
      case SpecialValue.SolutionDeviceControlScannerEnabledTypeAdvancedOnly:
        return this.getScannerEnabledTypeStringValue();
      default:
        return this.staticValue;
    }
  }

  getSubMember(variables: FlowVariable[], solutionTypes?: SolutionType[]): SolutionTypeMember {
    if (
      this.variablePointer &&
      !GuidUtils.isNullOrEmpty(this.variablePointer.subMemberGuidId)
    ) {
      if (this.webFieldValueType === FieldValueType.Variable) {
        const variable = this.getVariable(variables);
        if (variable) {
          const variableSolutionTypeGuidId = variable.getFieldValue('SolutionTypeGuidId');
          const solutionType = this.getSolutionType(variableSolutionTypeGuidId, solutionTypes);
          if (solutionType) {
            const member = (solutionType.members || []).find((m: SolutionTypeMember) => {
              return this.variablePointer && GuidUtils.isEqual(m.guidId, this.variablePointer.subMemberGuidId);
            });
            if (member) return member;
          }
        }
      } else if (this.webFieldValueType === FieldValueType.SolutionType) {
        const solutionType = this.getSolutionType(this.variablePointer.stateVariableGuidId, solutionTypes);
        if (solutionType) {
          const member = (solutionType.members || []).find((m: SolutionTypeMember) => {
            return this.variablePointer && GuidUtils.isEqual(m.guidId, this.variablePointer.subMemberGuidId);
          });
          if (member) return member;
        }
      }
    }

    return null;
  }

  getSubMemberValue(variables: FlowVariable[], solutionTypes?: SolutionType[]): string {
    const subMember = this.getSubMember(variables, solutionTypes);
    if (subMember) return subMember.displayName;
    // empty defaults...
    return this.webFieldValueType === FieldValueType.Variable &&
      this.solutionTypeGuidId !== GuidUtils.emptyGuid() &&
      SystemSolutionTypes.indexOf(this.solutionTypeGuidId) < 0 &&
      this.variablePointer?.subMemberGuidId ? ' ' : '';
  }

  getVariable(variables: FlowVariable[]): FlowVariable {
    const v = (variables || []).find((v: FlowVariable) => {
      return GuidUtils.isEqual(v.guidId, this.variablePointer?.stateVariableGuidId) || GuidUtils.isEqual(v.originalStateVariableGuidId, this.variablePointer?.stateVariableGuidId);
    });
    return new FlowVariable(v);
  }

  getSolutionType(solutionTypeGuidId: string, solutionTypes: SolutionType[]): SolutionType {
    if (solutionTypeGuidId === SolutionTypeEnum.Value) {
      return new SolutionType({
        guidId: GuidUtils.emptyGuid(),
        baseSolutionTypeGuidId: GuidUtils.emptyGuid(),
        solutionTypeGuidId: SolutionTypeEnum.Value,
        name: 'Value'
      });
    } else {
      const solutionType = (solutionTypes || []).find((st: SolutionType) => {
        return GuidUtils.isEqual(st.guidId, solutionTypeGuidId) || GuidUtils.isEqual(st.baseSolutionTypeGuidId, solutionTypeGuidId);
      });
      return solutionType;
    }
  }

  getVariableValidHint(solutionTypes: SolutionType[]): string {
    let hint = 'This field accepts';

    if (this.direction === FieldDirection.In) {
      if (!this.solutionTypeGuidId || this.solutionTypeGuidId === GuidUtils.emptyGuid()) {
        hint += ' variables of any type';
      } else if (SystemSolutionTypes.indexOf(this.solutionTypeGuidId) >= 0 && SystemComplexSolutionTypes.indexOf(this.solutionTypeGuidId) < 0) {
        hint += ' simple value variables';
      } else {
        const fieldSolutionType = this.getSolutionType(this.solutionTypeGuidId, solutionTypes);
        if (fieldSolutionType) {
          hint += ` variables of type ${fieldSolutionType.name}`;
        } else {
          hint += ' no variables';
        }
      }
      hint += this.allowListOnly ? ' (list).' : '.';
    } else {
      let and = true;
      if (SystemSolutionTypes.indexOf(this.solutionTypeGuidId) >= 0 && SystemComplexSolutionTypes.indexOf(this.solutionTypeGuidId) < 0) {
        hint += ' simple value variables';
      } else {
        const fieldSolutionType = this.getSolutionType(this.solutionTypeGuidId, solutionTypes);
        if (fieldSolutionType) {
          hint += ` variables of type ${fieldSolutionType.name}`;
        } else {
          and = false;
        }
      }
      hint += this.allowListOnly ? ` (list) ${and ? 'and' : ''} not yet set variables.` : ` ${and ? 'and' : ''} not yet set variables.`;
    }
    return hint;
  }

  isVariableValidHere(v: VariableToolboxGroupItem, solutionTypes: SolutionType[]): string {
    let validationError = '';
    const varSolutionTypeGuidId = !GuidUtils.isNullOrEmpty(v.$memberSolutionTypeGuidId) ? v.$memberSolutionTypeGuidId : v.solutionTypeGuidId;

    // check if this field "sets or gets" variables and filter by appropriate solutionTypeGuidId
    if (this.direction === FieldDirection.In) {
      if (!this.solutionTypeGuidId || this.solutionTypeGuidId === GuidUtils.emptyGuid()) {
        // field doesn't have a solutionTypeGuidId set, so pretty much anything is valid
        validationError = '';
      } else if (!GuidUtils.isNullOrEmpty(v.$memberGuidId) && !this.allowSubVariableMember) {
        // reject if the field doesn't allow subVariableMembers and we're trying to set one
        validationError = 'Sub variables not supported by this field.';
      } else if (SystemSolutionTypes.indexOf(this.solutionTypeGuidId) >= 0 && SystemComplexSolutionTypes.indexOf(this.solutionTypeGuidId) < 0) {
        // field only accepts system solution types
        // check if variable memberSolutionType or solutionType is also a system solution type
        if (SystemSolutionTypes.indexOf(varSolutionTypeGuidId) < 0) {
          validationError = 'Only System Solution Types are supported by this field.';
        }
      } else {
        // field is of a non-system solution type
        // check if variable memberSolutionType or solutionType is also of the same non system solution type
        const fieldSolutionType = this.getSolutionType(this.solutionTypeGuidId, solutionTypes);
        if (fieldSolutionType) {
          const varSolutionType = this.getSolutionType(varSolutionTypeGuidId, solutionTypes);
          if (!(fieldSolutionType && varSolutionType && fieldSolutionType.baseSolutionTypeGuidId === varSolutionType.baseSolutionTypeGuidId)) {
            validationError = 'Solution Type not supported by this field.';
          }
        }
      }
    } else {
      // if this is an 'Out' field, we can assigned it either a NOT SET variable or a variable of the same solution type
      if (GuidUtils.isNullOrEmpty(varSolutionTypeGuidId)) {
        // not set variable
        validationError = '';
      } else if (!GuidUtils.isNullOrEmpty(v.$memberGuidId) && !this.allowSubVariableMember) {
        // reject if the field doesn't allow subVariableMembers and we're trying to set one
        validationError = 'Sub variables not supported by this field.';
      } else if (SystemSolutionTypes.indexOf(this.solutionTypeGuidId) >= 0 && SystemComplexSolutionTypes.indexOf(this.solutionTypeGuidId) < 0) {
        // field only accepts system solution types
        // check if variable memberSolutionType or solutionType is also a system solution type
        if (SystemSolutionTypes.indexOf(varSolutionTypeGuidId) < 0) {
          validationError = 'Only System Solution Types are supported by this field.';
        }
      } else {
        // field is of a non-system solution type
        // check if variable memberSolutionType or solutionType is also of the same non system solution type
        const fieldSolutionType = this.getSolutionType(this.solutionTypeGuidId, solutionTypes);
        if (fieldSolutionType) {
          const varSolutionType = this.getSolutionType(varSolutionTypeGuidId, solutionTypes);
          if (!(fieldSolutionType && varSolutionType && fieldSolutionType.baseSolutionTypeGuidId === varSolutionType.baseSolutionTypeGuidId)) {
            validationError = 'Solution Type not supported by this field.';
          }
        }
      }
    }

    if (!validationError) {
      // filter depending on allowListOnly and variable IsList field
      if (this.allowListOnly) {
        if (!GuidUtils.isNullOrEmpty(varSolutionTypeGuidId) && !(v.$memberIsList || v.$isList)) {
          validationError = 'Only list variables are supported by this field.';
        }
      } else if (!this.allowList) {
        if (!GuidUtils.isNullOrEmpty(varSolutionTypeGuidId) && (v.$memberIsList || v.$isList)) {
          validationError = 'Only non-list variables are supported by this field.';
        }
      }
    }

    return validationError;
  }

  private getFeatureStringValue() {
    const featureStringFilter = this.staticValue ?
      new SolutionFeatureStringFilter(CaseUtils.toCamel(JSON.parse(this.staticValue))) :
      null;
    if (featureStringFilter) {
      return `${featureStringFilter.value ? '' : '!'}${featureStringFilter.featureName}`;
    } else {
      return '';
    }
  }

  private getScannerEnabledTypeStringValue() {
    let resultString = 'Not Active';
    if (this.staticValue) {
      if (this.staticValue > 0 && this.staticValue < 4) { // Legacy!
        resultString = this.staticValue === 1 ? 'Simple Barcode' : 'Advanced Barcode';
      } else if ((this.staticValue & ScannerEnabledFlagType.Simple) === ScannerEnabledFlagType.Simple) {
        resultString = 'Simple, ';
      } else if ((this.staticValue & ScannerEnabledFlagType.Advanced) === ScannerEnabledFlagType.Advanced) {
        resultString = 'Advanced, ';
      }

      if ((this.staticValue & ScannerEnabledFlagType.BuiltInScanner) === ScannerEnabledFlagType.BuiltInScanner) {
        resultString += 'BuiltInScanner, ';
      }
      if ((this.staticValue & ScannerEnabledFlagType.BluetoothScanner) === ScannerEnabledFlagType.BluetoothScanner) {
        resultString += 'BluetoothScanner, ';
      }
      if ((this.staticValue & ScannerEnabledFlagType.BuiltInNFC) === ScannerEnabledFlagType.BuiltInNFC) {
        resultString += 'BuiltInNFC, ';
      }
    }
    return resultString;
  }

}
