export enum RunDeviceStatus {

  Unknown = 0,
  NotStarted = 1,
  Shutdown = 2,
  Hibernate = 3,

  Initializing = 10,

  Running = 20,

  Free = 40,

  Exception = 99,

}
