import { WebObject } from 'app/center-v2/shared/models';
import { FilterStringFilter } from 'app/center-v2/shared/models/filter-string/filter-string-filter.model';

export class SmartImage extends WebObject {

  static readonly rootSmartImageFilterStringFilter = new FilterStringFilter({
    "isGroup": true,
    "filterGroups": [
      {
        "operator": 0,
        "filter": {
          "filterTypeGuidId": "fff3cb32-7f51-431a-a16a-a17ed771d44d",
          "memberTypeGuidId": "65be72de-0a42-4927-831c-fa962f8ed5c6",
          "member": {
            "subMembers": {
              "0": {
                "fromTypeGuidId": "65be72de-0a42-4927-831c-fa962f8ed5c6",
                "toTypeGuidId": "00000000-0000-0000-0000-000000000000",
                "dataMemberId": 0,
                "dataMemberGuidId": "75699778-2935-47cd-a5dd-b8a860f5a3f8",
                "subMemberType": 1,
                "relationCollection": false,
                "relationGuidId": "00000000-0000-0000-0000-000000000000"
              }
            },
            "guidId": "75699778-2935-47cd-a5dd-b8a860f5a3f8",
            "baseSolutionTypeGuidId": "b06de26e-bd62-48a6-a8c5-c2ac270f3715",
            "objectOrValue": 3,
            "path": "",
            "name": "GroupGuidId",
            "displayName": "GroupGuidId",
            "isList": false,
            "isValue": true,
            "isStructuralResolveValue": false,
            "fieldType": "guidid",
            "subLevels": 0
          },
          "filterGroups": [],
          "operator": "=",
          "value": null,
          "memberGuidId": "75699778-2935-47cd-a5dd-b8a860f5a3f8",
          "wildcardFilter": false,
          "incaseSensetive": false,
          "quotation": true,
          "checkDefined": false
        }
      },
      {
        "operator": 2,
        "filter": {
          "filterTypeGuidId": "fff3cb32-7f51-431a-a16a-a17ed771d44d",
          "memberTypeGuidId": "65be72de-0a42-4927-831c-fa962f8ed5c6",
          "member": {
            "subMembers": {
              "0": {
                "fromTypeGuidId": "65be72de-0a42-4927-831c-fa962f8ed5c6",
                "toTypeGuidId": "00000000-0000-0000-0000-000000000000",
                "dataMemberId": 0,
                "dataMemberGuidId": "75699778-2935-47cd-a5dd-b8a860f5a3f8",
                "subMemberType": 1,
                "relationCollection": false,
                "relationGuidId": "00000000-0000-0000-0000-000000000000"
              }
            },
            "guidId": "75699778-2935-47cd-a5dd-b8a860f5a3f8",
            "baseSolutionTypeGuidId": "b06de26e-bd62-48a6-a8c5-c2ac270f3715",
            "objectOrValue": 3,
            "path": "",
            "name": "GroupGuidId",
            "displayName": "GroupGuidId",
            "isList": false,
            "isValue": true,
            "isStructuralResolveValue": false,
            "fieldType": "guidid",
            "subLevels": 0
          },
          "filterGroups": [],
          "operator": "=",
          "value": "00000000-0000-0000-0000-000000000000",
          "memberGuidId": "75699778-2935-47cd-a5dd-b8a860f5a3f8",
          "wildcardFilter": false,
          "incaseSensetive": false,
          "quotation": true,
          "checkDefined": false
        }
      }
    ]
  } as any);
  
  members: {
    name: string;
    version: string;
    sysVersion: string;
    versionDateTime: string;
    isGroupRoot: boolean;
    groupGuidId: string;
    compiledMapTick: string;
    compiledMap: string;
  }

  constructor(item?: Partial<SmartImage>) {
    super(item);
  }

}
