import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowModule, NewModuleRequest, SolutionCommand, VariableToolbox } from '../../models';
import { FieldUtils } from '../../utils';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class SolutionCommandService {

  private urlSuffixPlaceholder = 'api/solutioncommand/{what}';

  constructor(private apiService: ApiStudioService) { }

  get(solutionDeviceControlGuidId: string): Observable<SolutionCommand> {
    return this.apiService.post<SolutionCommand>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
    {
      solutionCommandGuidId: solutionDeviceControlGuidId,
    })
    .pipe(
      map((response: any) => {
        const sc = new SolutionCommand(response.solutionCommand);
        sc.fields = FieldUtils.castFields(sc.fields);
        sc.$variableToolbox = new VariableToolbox(response.variableToolbox);
        return sc;
      })
    );
  }

  newModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'newmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

  resetModule(solutionCommandModuleGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'resetmodule'),
      {
        solutionCommandModuleGuidId: solutionCommandModuleGuidId,
      }
    )
    .pipe(
      map((response: any) => {
        return response ? new FlowAffectedResponse(response) : undefined;
      })
    );
  }

}
