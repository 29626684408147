import { SolutionDeviceControl } from '../solution-device-control.model';
import { VariableToolbox } from '../variable-toolbox';
import { FlowBase } from './flow-base.model';
import { FlowPortPointer } from './flow-port-pointer.model';


export enum FlowModuleType {
  Unknown = 0,

  Flow = 1,
  FlowItem = 2,
  FlowProcess = 3,
  MultiFlow = 4,

  SolutionDeviceControl = 10,

  AddToHead = 15,
  RemoveFromHead = 16,

  Notify = 20,
  Load = 21,

  SolutionCommand = 50,
  SolutionProcess = 51,

  LoopStart = 60,
  LoopBreak = 61,
  LoopNext = 62,

  PathMap = 80,

  BackgroundStart = 90,
  BackgroundJoin = 91,

  DataMap = 100,
}

export class FlowModule extends FlowBase {

  flowCoreGuidId: string;
  moduleType: FlowModuleType;
  comment: string;
  portPointers: FlowPortPointer[];

  // Maybe consider subcasting for these properties?
  flowGuidId?: string;
  flowItemGuidId?: string;

  solutionDeviceControlGuidId?: string;
  deviceControlTypeGuidId?: string;

  solutionCommandGuidId?: string;
  commandTypeGuidId?: string;
  commandTypeName?: string;

  loadGuidId?: string;
  notifyGuidId?: string;

  $variableToolbox?: VariableToolbox;

  constructor(item: any) {
    super(item);
  }

  getTypeLabel(): string {
    if (this.moduleType === FlowModuleType.SolutionCommand) {
      return this.commandTypeName;
    } else if (this.moduleType === FlowModuleType.SolutionDeviceControl) {
      return SolutionDeviceControl.getTypeLabel(this.deviceControlTypeGuidId);
    } else {
      return FlowModuleType[this.moduleType];
    }
  }

}
