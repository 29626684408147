import { GuidUtils } from 'app/shared/utils';
import { TypeUtils } from '../utils/type.utils';



export class TreeRelationSource {

  level: number;
  guidId: string;
  typeTreeRelationGuidId: string;
  isRootObject: boolean;
  parentTypeGuidId: string;
  parentGuidId: string;
  childTypeGuidId: string;

  set childGuidId(value: string) { this._childGuidId = value; this.isDirty = true; }
  get childGuidId(): string { return this._childGuidId; }
  private _childGuidId: string;

  childName: string;
  values: any;
  logicPermissionGuidId: string;

  active: boolean;
  isNew: boolean;
  isDirty: boolean;

  constructor(item?: Partial<TreeRelationSource>) {
    Object.assign(this, item);
  }

  static build(parentGuidId: string, parentTypeGuidId: string, childGuidId: string, childTypeGuidId: string) {
    const newItem = new TreeRelationSource();

    newItem.level = 0;
    newItem.guidId = GuidUtils.new();
    newItem.typeTreeRelationGuidId = TypeUtils.getTypeTreeRelationGuidIdFromTypeGuidIds(parentTypeGuidId, childTypeGuidId);
    newItem.isRootObject = GuidUtils.isNullOrEmpty(parentTypeGuidId);
    newItem.parentGuidId = parentGuidId;
    newItem.parentTypeGuidId =  parentTypeGuidId;
    newItem.childGuidId = childGuidId;
    newItem.childTypeGuidId = childTypeGuidId;
    newItem.values = null;
    newItem.logicPermissionGuidId = GuidUtils.emptyGuid();
    newItem.active = true;
    newItem.isNew = true;
    newItem.isDirty = true;

    return newItem;
  }

  toJSON() {
    const obj = Object.assign({}, this);

    for (const key of Object.keys(obj)) {
      if (key[0] === '_') {
        obj[key.substring(1)] = this[key];
        delete obj[key];
      }
    }

    return obj;
  }

  deactivate(): void {
    this.active = false;
    this.isDirty = true;
  }

  setValue(key: string, value: any) {
    this.values = this.values || {};
    this.values[key] = value;

    this.isDirty = true;
  }

}
