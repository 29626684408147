import { BlobUtils } from './blob.utils';


export class DownloadUtils {

  static downloadB64AsFile(b64: string, mimeType: string, filename: string, binary?: boolean) {
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        BlobUtils.b64ToBlob(b64, mimeType, binary),
        filename
      );
    } else {
      this.downloadHrefAsFile(
        URL.createObjectURL(
          BlobUtils.b64ToBlob(b64, mimeType, binary)
        ),
        filename,
      );
    }
  }

  static downloadBlobAsFile(blob: Blob, filename: string) {
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        blob,
        filename
      );
    } else {
      this.downloadHrefAsFile(
        URL.createObjectURL(blob),
        filename,
      );
    }
  }

  static downloadPlainTextAsFile(text: string, filename: string) {
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        BlobUtils.stringToBlob(text, 'text/plain'),
        filename
      );
    } else {
      this.downloadHrefAsFile(
        URL.createObjectURL(
          BlobUtils.stringToBlob(text, 'text/plain'),
        ),
        filename,
      );
    }
  }

  static downloadHrefAsFile(href: string, filename: string) {
    if (href.indexOf('data:') === 0 && (navigator as any).msSaveBlob) {
      return (navigator as any).msSaveBlob(
        this.dataURItoBlob(href),
        filename
      );
    } else {
      const tempDownloadLink = document.createElement('a');
      tempDownloadLink.href = href;
      tempDownloadLink.download = filename;

      document.body.appendChild(tempDownloadLink);
      tempDownloadLink.click();
      document.body.removeChild(tempDownloadLink);
    }
  }

  private static dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: mimeString});
    return blob;
  }

}
