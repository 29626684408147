import { ReportSolutionTypeRelation } from './solution-type-relation.model';
import { SolutionType } from './solution-type.model';


export enum ReportSolutionTypeType {
  Object = 0,
  Trace = 1,
  PerformanceCounter = 2
}

export class ReportSolutionType extends SolutionType {

  reportType: ReportSolutionTypeType;
  reportRelations: ReportSolutionTypeRelation[];

  constructor(item?: any) {
    super(item);

    if (item) {
      /*this.reportRelations = (this.reportRelations || []).map((i: ReportSolutionTypeRelation) => {
        return new ReportSolutionTypeRelation(i);
      });*/
    } else {
      this.reportRelations = [];
    }
  }

  static new(
    reportTemplateGuidId: string,
    centerTypeGuidId: string,
    reportType: ReportSolutionTypeType,
    name: string,
  ) {
    const newItem = new ReportSolutionType();

    newItem.reportTemplateGuidId = reportTemplateGuidId;
    newItem.baseSolutionTypeGuidId = centerTypeGuidId;
    newItem.reportType = reportType;
    newItem.name = name;

    return newItem;
  }

}
