export enum VariableCompareType {
  Unknown = 0,
  Equal = 1,
  NotEqual = 2,
  LargerThan = 3,
  SmallerThan = 4,
  ExistsIn = 5,
  StartsWith = 6,
  EndsWith = 7,
  Regex = 8,

  EqualOrLargerThan = 50,
  EqualOrSmallerThan = 51,
}
