import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urlSuffixPlaceholder = 'centerv2/user/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  getSessionUser(guidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'getsessionuser'),
      {
        guidId: guidId,
      }
    );
  }

  setSessionUserLanguage(languageGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'setsessionuserlanguage'),
      {
        languageGuidId: languageGuidId,
      }
    );
  }

}
