import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResponse, DataSource, DictString, TreeSource } from '../../models';
import { StorageUtils } from '../../utils';
import { ApiCenterService } from '../api/api-center.service';
import { LookupService } from '../lookup/lookup.service';
import { DesignTreeDataRequest } from './design-tree-data-request.model';


/**
 * this is different to /designtree in the way it returns data. This one return dataSources
 */
@Injectable({
  providedIn: 'root'
})
export class DesignTreeDataService {
  private readonly URL_SUFFIX = 'api/v0/designtreedata';

  constructor(
    private apiService: ApiCenterService,
    private lookupService: LookupService,
  ) { }

  get(
    designTreeGuidId: string,
    filterSiteGuidId: string,
    filterLevels: number,
    typeGuidIds: string[],
    environmentTypeGuidIds: DictString<string[]>,
  ): Observable<DataResponse> {
    const request: DesignTreeDataRequest = {
      designTreeGuidId: designTreeGuidId,
      filterSiteGuidId: filterSiteGuidId,
      filterLevels: filterLevels,
      designTreeTick: 0,
      typeGuidIds: typeGuidIds || [],
      environmentTypeGuidIds: environmentTypeGuidIds,
    };

    const cacheKey = 'designtreedata_' + filterLevels + '_' + typeGuidIds.length + '_' + designTreeGuidId;
    const cachedDR = JSON.parse(StorageUtils.getItem('session', cacheKey) || '{}');
    if (cachedDR && cachedDR.webTreeSources && cachedDR.webTreeSources.length) {
      request.designTreeTick = cachedDR.webTreeSources[0].tick;
    }

    return this.makeRequest(request, cacheKey, cachedDR);
  }

  private makeRequest(request: DesignTreeDataRequest, cacheKey: string, cachedDR: DataResponse): Observable<DataResponse> {
    return this.apiService.post<DataResponse>(this.URL_SUFFIX, request)
    .pipe(
      map((dr: DataResponse) => {
        let result = null;
        if (dr) {
          StorageUtils.setItem('session', cacheKey, dr);

          result = dr;
        } else {
          result = cachedDR;
        }

        if (result.webDataSources && dr.webDataSources.length) {
          result.webDataSources = (result.webDataSources || []).map(x => new DataSource(x));
          this.lookupService.setDataSource(result.webDataSources);
        }

        if (result.webTreeSources && result.webTreeSources.length) {
          result.webTreeSources = (result.webTreeSources || []).map(x => new TreeSource(x));
          this.lookupService.setTreeSource(result.webTreeSources);
        }

        return result;
      })
    );
  }

}
