<div class="form-field"
  (click)="onTouched()">
  <label *ngIf="label"
    class="label"
    [innerHTML]="label">
  </label>

  <div [id]="mapId"
    class="map">
  </div>
</div>
