import { Injectable } from '@angular/core';
import { FieldUtils } from 'app/studio/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowModule, NewModuleRequest, SolutionLoad } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';



@Injectable({
  providedIn: 'root'
})
export class SolutionLoadService {

  private urlSuffixPlaceholder = 'api/load/{what}';

  constructor(private apiService: ApiStudioService) { }

  get(loadGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
    {
      loadGuidId: loadGuidId,
    })
    .pipe(
      map((response: any) => {
        const sl = new SolutionLoad(response.load);
        sl.fields = FieldUtils.castFields(sl.fields);
        // sl.$variableToolbox = new VariableToolbox(response.variableToolbox);
        return sl;
      })
    );
  }

  newModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'newmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

}
