import { Injectable } from '@angular/core';
import { FilterStringFilter } from 'app/center-v2/shared/models/filter-string/filter-string-filter.model';
import { DictString } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { WebObject, WebPointerStatus, WebRelationPointer, WebResponse, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { WebLoadMap } from 'app/center-v2/shared/models/web-base/web-load-map.model';
import { AdvancedSearchResponse } from '../models/advanced-search-response.model';
import { WebCustomSearch } from '../models/web-base/web-custom-search.model';
import { GuidUtils } from 'app/shared/utils';


@Injectable({
  providedIn: 'root'
})
export class CoreAdminService {

  private urlSuffixPlaceholder = 'centerv2/coreadmin/generic/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  /** @deprecated */
  //get(guidId: string, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
  get(guidId: string, fullObject?: boolean, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        workspaceGuidId: workspaceGuidId,
        web2LoadTemplate: fullObject ? 'Complete' : 'Base',
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  getV2(guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
        web2LoadTemplate: 'Base',
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  getBatch(guidIds: string[], webLoadMap: WebLoadMap, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'getbatch'),
      {
        guidIds: guidIds,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        return response;
      })
    );
  }

  getForPrint(solutionTypes: WorkspaceSolutionType[], guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return of(null);
  }

  getTypes(typeGuidIds: string[]): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'types/get'),
      {
        typeGuidIds: typeGuidIds,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  list(typeGuidId: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, siteGuidId?: string, subSiteLevels?: number): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      {
        includeCenterTypes: includeCenterTypes || false,
        siteGuidId: siteGuidId,
        subSiteLevels: subSiteLevels,
        typeGuidId: typeGuidId,
        web2FilterString: webLoadMap || filterStringFilter ? {
          filter: filterStringFilter,
          loadMap: webLoadMap || new WebLoadMap(),
        } : undefined,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  listPaged(
    typeGuidId: string,
    page: number,
    perPageCount: number,
    webLoadMap?: WebLoadMap,
    filterStringFilter?: FilterStringFilter,
    includeCenterTypes?: boolean,
    siteGuidId?: string,
    subSiteLevels?: number,
    fromDateTime?: string,
    toDateTime?: string,
  ): Observable<WebResponse> {
    throw new Error('Not Implemented...');
  }

  relationMap(parentGuidId: string, typeGuidId: string, relationName: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, siteRestricted?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        siteRestricted: siteRestricted || undefined,
        // web2FilterString: {
        //   filter: filterStringFilter || null,
        //   web2LoadMap: webLoadMap || new WebLoadMap(),
        // },
        web2LoadMap: webLoadMap,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  /** @deprecated */
  relation(parentGuidId: string, typeGuidId: string, relationName: string, fullObjects?: boolean, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationV2(parentWebObject: WebObject, relationName: string, webLoadMap: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, siteRestricted?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.relationMap(parentWebObject.guidId, parentWebObject.typeGuidId, relationName, webLoadMap, filterStringFilter, includeCenterTypes, siteRestricted, workspaceGuidId);
  }

  relationV2Paged(
    parentWebObject: WebObject,
    relationName: string,
    page: number,
    perPageCount: number,
    webLoadMap?: WebLoadMap,
    filterStringFilter?: FilterStringFilter,
    includeCenterTypes?: boolean,
    workspaceGuidId?: string,
  ): Observable<WebResponse> {
    throw new Error('Not Implemented...');
  }

  relationsCount(parentGuidId: string, parentTypeGuidId: string, relationNames: string[]): Observable<DictString<number>> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'relationscount'),
      {
        guidId: parentGuidId,
        typeGuidId: parentTypeGuidId,
        relationNames: relationNames,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.relationsCount : null;
      })
    );
  }

  search(typeGuidId: string, member: string, value: any, wildcardSearch?: boolean, incaseSensitive?: boolean, webLoadMap?: WebLoadMap, includeCenterTypes?: boolean, adminCallGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'search'),
      {
        adminCallGuidId: adminCallGuidId,
        typeGuidId: typeGuidId,
        member: member,
        value: value,
        wildcardSearch: wildcardSearch,
        incaseSensetive: incaseSensitive,
        web2LoadMap: webLoadMap,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  advancedSearch(web2FilterStrings: DictString<{ filter: FilterStringFilter, web2LoadMap: WebLoadMap }>, web2CustomSearches?: DictString<WebCustomSearch>, fromDateTime?: string, toDateTime?: string, includeCenterTypes?: boolean): Observable<AdvancedSearchResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/advancesearch'),
      {
        web2CustomSearches: web2CustomSearches,
        web2FilterStrings: web2FilterStrings,
        fromDateTime: fromDateTime,
        toDateTime: toDateTime,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new AdvancedSearchResponse(response) : null;
      })
    );
  }

  newDraftObject(typeGuidId: string, parentGuidId?: string, includeCenterTypes?: boolean, siteGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      {
        typeGuidId: typeGuidId,
        parentGuidId: parentGuidId,
        includeCenterTypes: includeCenterTypes,
        siteGuidId: siteGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  /** @deprecated */
  newDraftRelation(parentTypeGuidId: string, relationName: string, childObjectOrChildGuidId: WebObject | string, workspaceGuidId?: string): Observable<WebRelationPointer> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'newrelation'),
      {
        parentTypeGuidId: parentTypeGuidId,
        relationName: relationName,
        childGuidId: (childObjectOrChildGuidId instanceof WebObject ? childObjectOrChildGuidId.guidId : childObjectOrChildGuidId) || GuidUtils.emptyGuid(),
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (!response?.web2RelationPointer) return null;

        const wrp = new WebRelationPointer(response.web2RelationPointer);
        if (childObjectOrChildGuidId instanceof WebObject) wrp.web2Object = childObjectOrChildGuidId;
        return wrp;
      })
    );
  }

  newDraftRelationV2(parentWebObject: WebObject, relationName: string, childObjectOrChildGuidId: WebObject | string, collectionRelation: boolean, workspaceGuidId?: string): Observable<WebRelationPointer> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'newrelation'),
      {
        parentTypeGuidId: parentWebObject.typeGuidId,
        relationName: relationName,
        childGuidId: (childObjectOrChildGuidId instanceof WebObject ? childObjectOrChildGuidId.guidId : childObjectOrChildGuidId) || GuidUtils.emptyGuid(),
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (!response?.web2RelationPointer) return null;

        const wrp = new WebRelationPointer(response.web2RelationPointer);
        if (childObjectOrChildGuidId instanceof WebObject) wrp.web2Object = childObjectOrChildGuidId;
        parentWebObject.addRelationPointer(relationName, wrp, collectionRelation);
        return wrp;
      })
    );
  }

  /** @deprecated */
  newDraftSubType(guidId: string, typeGuidId: string, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'newsubtype'),
      {
        guidId: guidId,
        typeGuidId: typeGuidId,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  newDraftSubTypeV2(webObject: WebObject, typeGuidIds: string[], includeCenterTypes?: boolean): Observable<WebResponse> {
    throw new Error('Not Implemented...');
  }

  update(webObjects: WebObject[], workspaceGuidId?: string, skipUpdateOriginal?: boolean): Observable<WebResponse> {
    return of(null)
    .pipe(
      mergeMap(() => {
        const webObjectsToUpdate = WebObject.cleanWebObjectsForUpdate(webObjects);

        const hasUpdates = webObjectsToUpdate.some(wo => wo.web2Status !== WebPointerStatus.Update || Object.keys(wo.members || {}).length || Object.keys(wo.relations || {}).length || Object.keys(wo.subTypes || {}).length);
        if (!hasUpdates) return of(new WebResponse({ web2Objects: [], web2CenterTypes: null } as any));

        return this.apiService.post(
          this.urlSuffixPlaceholder.replace('{what}', 'update'),
          {
            web2Objects: webObjectsToUpdate,
            workspaceGuidId: workspaceGuidId,
          }
        )
      }),
      map((response: any) => {
        if (!response) return null;

        const webResponse = new WebResponse(response);
        if (skipUpdateOriginal) return webResponse;

        const updatedWebObjects = webResponse.getWebObjects();
        for (const uwo of updatedWebObjects || []) {
          const existingWO = webObjects.find(wo => wo.guidId === uwo.guidId);
          if (!existingWO) continue;
          if (existingWO.typeGuidId === uwo.typeGuidId) {
            Object.assign(existingWO, uwo);
            existingWO.updateOriginalWebObject();
          } else {
            // we need to find the subtype
            Object.assign(existingWO, uwo.getSubType(existingWO.web2Type));
            existingWO.updateOriginalWebObject();
          }
        }
        return webResponse;
      })
    );
  }

}
