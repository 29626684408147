import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCenterService } from '../api/api-center.service';
import { GdprUserRequest } from './gdpr-user-request.model';


@Injectable({
  providedIn: 'root'
})
export class GdprService {

  private urlSuffixPlaceholder = 'api/v0/gdpr/{what}';

  constructor(private apiService: ApiCenterService) { }

  user(gdprUserRequest: GdprUserRequest): Observable<boolean> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user'),
      gdprUserRequest
    );
  }

  createRequest(userGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'createrequest'),
      { userGuidId: userGuidId }
    );
  }

  report(): Observable<string> {
    return this.apiService.post<string>(
      this.urlSuffixPlaceholder.replace('{what}', 'report'),
      {}
    ).pipe(
      map((response: any) => {
        return response.reportData;
      })
    );
  }


}
