import { ChangeDetectorRef, HostListener, ViewChild, Directive, Component } from '@angular/core';
import { BaseComponentV2 } from 'app/shared/components/base/base-v2.component';
import { SessionService } from 'app/shared/services/app';
import { Dialog } from 'primeng/dialog';



@Component({
  template: '<div></div>'
})
export abstract class BaseDialog extends BaseComponentV2 {

  readonly sizeMD = 960;

  @ViewChild(Dialog, { static: true }) dialog: Dialog;

  data: any;
  isVisible: boolean;
  onClose: (result: any) => void;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected sessionService?: SessionService,
  ) {
    super(cdr, sessionService);
  }

  show(data?: any) {
    this.isVisible = true;
    this.data = data;
    this.init(this.data);

    this.cdr.markForCheck();
  }

  protected abstract init(data: any);

  dismiss() {
    this.close(undefined);
  }

  protected close(result: any) {
    this.data = undefined;
    this.isVisible = false;
    this.cdr.markForCheck();

    if (this.onClose) this.onClose(result);
  }

  positionOverlay() {
    setTimeout(() => {
      if (this.isVisible) {
        this.cdr.markForCheck();
      }
    }, 10);
  }

}
