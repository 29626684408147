import { FlowBase } from './flow';
import { ServerDeviceSolutionType } from './server-device-solution.model';
import { VariableToolbox } from './variable-toolbox';


export class ServerPort2 extends FlowBase {

  serverDeviceSolutionGuidId: string;
  serverDeviceSolutionType: ServerDeviceSolutionType;

  $serverDeviceSolutionName?: string;
  $variableToolbox?: VariableToolbox;

  constructor(item?: any) {
    super(item);
  }

}
