
export class WebFailedReasonResponse {

  failedReason: string;

  constructor(item?: Partial<WebFailedReasonResponse>) {
    Object.assign(this, item);
  }

}
