<span *ngIf="refreshClick?.observers?.length" class="ag-name-value">
  <a href="javascript: void(0);"
    (click)="refreshClick.emit()">
    <i class="fas fa-sync-alt"></i>
  </a>
</span>
&nbsp;
<span *ngIf="lastUpdateDate" class="ag-name-value">
  <span>{{ 'Last Update' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ lastUpdateDate | dfnsFormat: 'HH:mm:ss' }}</span>
</span>
&nbsp;
<span *ngIf="nextUpdateDate" class="ag-name-value">
  <span>{{ 'Next Update' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ nextUpdateDate | dfnsFormat: 'HH:mm:ss' }}</span>
</span>
&nbsp;
<span *ngIf="lastUpdateFailed" class="ag-name-value">
  <span class="fas fa-exclamation-circle" style="color: red"></span>
</span>

