import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SolutionFeature } from 'app/shared/models';
import { AppService, SolutionProfileService } from 'app/shared/services';
import { SessionService } from 'app/shared/services/app';
import { SelectItem } from 'primeng/api';
import { NewSolutionFeatureDialog } from './new-solution-feature/new-solution-feature.dialog';



@Component({
  selector: 'lc-solution-features-dialog',
  templateUrl: './solution-features.dialog.html',
  styleUrls: ['./solution-features.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SolutionFeaturesDialog extends BaseDialog {

  @ViewChild(NewSolutionFeatureDialog, { static: true }) newSolutionFeatureDialog: BaseDialog;

  readonly TYPE_CHECKBOX = 'checkbox';

  solutionProfileGuidId: string;

  solutionFeatures: SolutionFeature[];

  selectedSF: SolutionFeature;

  defaultValueItems: SelectItem[];

  saveHappened: boolean;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private solutionProfileService: SolutionProfileService,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);

    this.defaultValueItems = [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ];
  }

  init(data: any) {
    this.solutionProfileGuidId = data.solutionProfileGuidId;
    this.solutionFeatures = data.solutionFeatures || [];

    this.selectedSF = undefined;

    this.fetchSolFeatures();
  }

  private fetchSolFeatures() {
    this.solutionProfileService.listSolutionFeatures(this.solutionProfileGuidId)
    .subscribe((solutionFeatures: SolutionFeature[]) => {
      if (solutionFeatures) {
        this.solutionFeatures = solutionFeatures || [];
        console.log(solutionFeatures);
      }

      this.cdr.markForCheck();
    });
  }

  closeClick() {
    this.close(this.saveHappened);
  }

  save(newOrUpdateSF: SolutionFeature) {
    if (newOrUpdateSF) {
      this.solutionProfileService.updateSolutionFeature(
        this.solutionProfileGuidId,
        newOrUpdateSF.guidId,
        newOrUpdateSF,
      ).subscribe((response: any) => {
        this.saveHappened = true;
        this.showSuccess('SolutionFeature updated successfully!');
      }, (error: any) => {

      });
    }
  }

  private showSuccess(msg: string) {
    this.appService.showToast({ severity: 'success', summary: 'Success', detail: msg });
  }

  newSolutionFeature() {
    this.newSolutionFeatureDialog.show({
      solutionProfileGuidId: this.solutionProfileGuidId,
    });
    this.newSolutionFeatureDialog.onClose = (sf: SolutionFeature) => {
      if (sf) {
        this.addNewSolutionFeature(sf, true);
        this.cdr.markForCheck();
      }
    };
  }

  private addNewSolutionFeature(newSF: SolutionFeature, makeItCurrentSelection: boolean): SolutionFeature {
    this.solutionFeatures = [...this.solutionFeatures, newSF];

    if (makeItCurrentSelection) {
      this.selectedSF = newSF;
    }

    return newSF;
  }

}
