import { FieldUtils } from '../utils/field.utils';
import { Field } from './field';
import { SolutionField } from './field/solution-field.model';
import { FlowBase } from './flow/flow-base.model';
import { FlowVariable } from './flow/flow-variable.model';
import { VariableToolbox } from './variable-toolbox';


export class SolutionCommand extends FlowBase {

  commandTypeGuidId: string;
  commandTypeName: string;
  fields: Field[];

  $variableToolbox: VariableToolbox;

  getField(fieldName: string): Field {
    const index = (this.fields || []).findIndex((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    let field = this.fields[index];
    if (field) {
      if (field instanceof Field) {
        return field;
      } else {
        field = FieldUtils.castField(field);
        this.fields.splice(index, 1, field);
        return field;
      }
    } else {
      return null;
    }
  }

  getFieldValue(fieldName: string, variables?: FlowVariable[], texts?: Map<number, string>): string {
    let field = this.getField(fieldName);
    if (field) {
      return field.getValue(variables, texts);
    } else {
      return '';
    }
  }

  getFieldVariable(fieldName: string, variables: FlowVariable[]): FlowVariable {
    let field = this.getField(fieldName);
    if (field) {
      return (field as SolutionField).getVariable(variables);
    } else {
      return null;
    }
  }

}
