import { ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeType } from 'app/center-v2/shared/models/barcode-type.enum';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { drawBarcode } from 'bardcode';

@Component({
  selector: 'lc-grid-tooltip-barcode',
  templateUrl: 'grid-tooltip-barcode.component.html',
  styleUrls: ['grid-tooltip-barcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTooltipBarcode implements ITooltipAngularComp {

  @ViewChild('barcodeCanvas', { static: false }) barcodeCanvas: ElementRef;

  params: {color: string} & ITooltipParams;

  constructor(
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {

  }

  agInit(params: {color: string} & ITooltipParams): void {
    this.params = params;

    this.generateBarcode(this.params.value);
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  private generateBarcode(text: string) {
    const options = {
      type: BarcodeType.Code128,
      hasChecksum: false,
      x: 0,
      y: 0,
      moduleWidth: 2.892,
      height: 100,
      horizontalAlign: 'left',
      verticalAlign: 'top',
      quietZoneSize: 5,
      angle: 0,
      maxWidth: 500,
      width: NaN,
    };
    setTimeout(() => {
      const canvas = this.barcodeCanvas.nativeElement as HTMLCanvasElement;
      if (canvas && text) {
        const g = canvas.getContext('2d');
        canvas.height = options.height || canvas.height;

        try {
          drawBarcode(g, text, options);
        } catch (error) {
          this.notificationService.error(
            this.translateService.instant('Barcode Error'),
            error?.message || error,
          );
        }

        this.cdr.markForCheck();
      }
    }, 1);
  }

}
