import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Field, FlowAffectedResponse, FlowFieldsObject } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class FieldService {

  private urlSuffixPlaceholder = 'api/field/{what}';

  constructor(private apiService: ApiStudioService) { }

  static getFieldGuidIdByFieldName(fields: Field[], fieldName: string): string {
    const field = (fields || []).find((f: Field) => {
      return f.name === fieldName;
    }) || {} as Field;
    return field.fieldGuidId;
  }

  propertySetValue(fieldParentGuidId: string, fieldGuidId: string, value: any): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'property/setvalue'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        value: value
      }
    );
  }


  relationGetFieldObject(fieldParentGuidId: string, fieldGuidId: string): Observable<FlowFieldsObject> {
    return this.apiService.post<FlowFieldsObject>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/getfieldobject'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response && response.fieldObject ? new FlowFieldsObject(response.fieldObject) : undefined;
      })
    );
  }

  relationGetFieldsObjects(fieldParentGuidId: string, fieldGuidId: string): Observable<FlowFieldsObject[]> {
    return this.apiService.post<FlowFieldsObject[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/getfieldsobjects'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (response) {
          const result = [];
          for (const fo of response.fieldsObjects || []) {
            result.push(new FlowFieldsObject(fo));
          }
          return result;
        } else {
          return [];
        }
      })
    );
  }

  relationNewFieldsObject(fieldParentGuidId: string, fieldGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/newfieldsobject'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowFieldsObject(far.sourceObject);
          return far;
        } else {
          return null;
        }
      })
    );
  }

  relationRemoveAllChildren(fieldParentGuidId: string, fieldGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/removeallchildren'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    );
  }

  relationRemoveFieldsObject(fieldParentGuidId: string, fieldGuidId: string, fieldObjectGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/removefieldsobject'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        fieldObjectGuidId: fieldObjectGuidId,
      }
    );
  }

  relationAddChild(fieldParentGuidId: string, fieldGuidId: string, childGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/addchild'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        childGuidId: childGuidId
      }
    );
  }

  relationRemoveChild(fieldParentGuidId: string, fieldGuidId: string, childGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/removechild'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        childGuidId: childGuidId
      }
    );
  }

  relationSetChild(fieldParentGuidId: string, fieldGuidId: string, childGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'relation/setchild'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        childGuidId: childGuidId
      }
    );
  }

  solutionSetNotSet(fieldParentGuidId: string, fieldGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/setnotset'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    );
  }

  solutionSetNull(fieldParentGuidId: string, fieldGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/setnull'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    );
  }

  solutionSetStatic(fieldParentGuidId: string, fieldGuidId: string, value: any): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/setstatic'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        value: value
      }
    );
  }

  solutionSetText(fieldParentGuidId: string, fieldGuidId: string, textId: number): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/settext'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        textId: textId
      }
    );
  }

  solutionSetVariable(fieldParentGuidId: string, fieldGuidId: string, variableGuidId: string, subMemberGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/setvariable'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        variableGuidId: variableGuidId,
        subMemberGuidId: subMemberGuidId,
      }
    );
  }

  solutionAddHybridVariable(fieldParentGuidId: string, fieldGuidId: string, variableGuidId: string, subMemberGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/addhybridvariable'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        variableGuidId: variableGuidId,
        subMemberGuidId: subMemberGuidId,
      }
    );
  }

  solutionClearHybridVariables(fieldParentGuidId: string, fieldGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/clearhybridvariables'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
      }
    );
  }

  solutionSetResource(fieldParentGuidId: string, fieldGuidId: string, resourceId: number): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solution/setresource'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        resourceId: resourceId
      }
    );
  }

  solutionTypeSet(fieldParentGuidId: string, fieldGuidId: string, solutionTypeGuidId: string, subMemberGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'solutiontype/set'),
      {
        fieldParentGuidId: fieldParentGuidId,
        fieldGuidId: fieldGuidId,
        solutionTypeGuidId: solutionTypeGuidId,
        subMemberGuidId: subMemberGuidId,
      }
    );
  }

}
