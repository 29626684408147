import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormFieldDaysOfWeekComponent } from './form-field-days-of-week.component';


@NgModule({
  declarations: [
    FormFieldDaysOfWeekComponent,
  ],
  exports: [
    FormFieldDaysOfWeekComponent,
  ],
  imports: [
    AppSharedV2Module,
    MultiSelectModule,
  ],
})
export class FormFieldDaysOfWeekModule { }
