import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Design, DesignGetResponse, DesignListResponse, DesignStyle, DesignStyleTypes, FlowAffectedResponse, VariableToolbox } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class DesignService {

  private urlSuffixPlaceholder = 'api/design/{what}';

  constructor(private apiService: ApiStudioService) { }

  delete(design: Design): Observable<boolean> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'delete'),
      {
        design: design,
      }
    );
  }

  get(designGuidId: string, withStyles?: boolean): Observable<DesignGetResponse> {
    return this.apiService.post<DesignGetResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        designGuidId: designGuidId,
        withStyles: withStyles || false
      }
    )
    .pipe(
      map((response: DesignGetResponse) => {
        return response ? new DesignGetResponse(response) : null;
      })
    );
  }

  getStyle(designStyleGuidId: string): Observable<DesignStyle> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'getstyle'),
      {
        designStyleGuidId: designStyleGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (response && response.designStyle) {
          response.designStyle.$variableToolbox = new VariableToolbox(response.variableToolbox);
          response.designStyle = new DesignStyle(response.designStyle);
          return response.designStyle;
        } else {
          return null;
        }
      })
    );
  }

  list(withStyles: boolean): Observable<DesignListResponse> {
    return this.apiService.post<DesignListResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      {
        withStyles: withStyles,
      }
    ).pipe(
      map((response: DesignListResponse) => {
        return response ? new DesignListResponse(response) : null;
      })
    );
  }

  new(parentFlowCoreGuidId: string, name: string, platform = false): Observable<Design> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      {
        parentFlowCoreGuidId: parentFlowCoreGuidId,
        name: name,
        platform: platform,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        return far && far.sourceObject ? new Design(far.sourceObject) : null;
      })
    );
  }

  newStyle(
    parentFlowCoreGuidId: string, designGuidId: string, designStyleName: string, designStyleTypes: DesignStyleTypes,
  ): Observable<DesignStyle> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'newstyle'),
      {
        parentFlowCoreGuidId: parentFlowCoreGuidId,
        designGuidId: designGuidId,
        designStyleName: designStyleName,
        designStyleTypes: designStyleTypes,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        return far && far.sourceObject ? new DesignStyle(far.sourceObject) : null;
      })
    );
  }

  update(design: Design): Observable<Design> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'update'),
      {
        design: design,
      }
    ).pipe(
      map((response: any) => {
        return response && response.design ? new Design(response.design) : null;
      })
    );
  }

  updateStyle(designStyle: DesignStyle): Observable<DesignStyle> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'updatestyle'),
      {
        designStyle: designStyle,
      }
    ).pipe(
      map((response: any) => {
        return response && response.designStyle ? new DesignStyle(response.designStyle) : null;
      })
    );
  }

}
