import { FlowBase } from './flow-base.model';


export const enum FlowPortType {
  In = 0,
  Out = 1,
  Action = 2,
}

export class FlowPort extends FlowBase {

  portType: FlowPortType;
  comment: string;
  parentPortObjectType: number;
  parentGuidId: string;
  originalPortGuidId: string;

}
