import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowModule, NewModuleRequest, SolutionDeviceControl, VariableToolbox } from '../../models';
import { FieldUtils } from '../../utils';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class SolutionDeviceControlService {

  private urlSuffixPlaceholder = 'api/solutiondevicecontrol/{what}';

  constructor(private apiService: ApiStudioService) { }

  get(solutionDeviceControlGuidId: string): Observable<SolutionDeviceControl> {
    return this.apiService.post<SolutionDeviceControl>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
    {
      solutionDeviceControlGuidId: solutionDeviceControlGuidId,
    })
    .pipe(
      map((response: any) => {
        const sdc = new SolutionDeviceControl(response.solutionDeviceControl);
        sdc.fields = FieldUtils.castFields(sdc.fields);
        sdc.$variableToolbox = new VariableToolbox(response.variableToolbox);
        return sdc;
      })
    );
  }

  newModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'newmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

  addAR(sourceSolutionDeviceControlGuidId: string, deviceControlTypeGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'addar'),
      {
        sourceSolutionDeviceControlGuidId: sourceSolutionDeviceControlGuidId,
        deviceControlTypeGuidId: deviceControlTypeGuidId,
      }
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

  addWatch(sourceSolutionDeviceControlGuidId: string, deviceControlTypeGuidId: string): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'addwatch'),
      {
        sourceSolutionDeviceControlGuidId: sourceSolutionDeviceControlGuidId,
        deviceControlTypeGuidId: deviceControlTypeGuidId,
      }
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

}
