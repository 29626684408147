import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';
import { FormFieldModule } from 'app/shared/components/form-field/form-field.module';
import { DialogModule } from 'primeng/dialog';
import { SelectCenterTypeDialog } from './select-center-type.dialog';



@NgModule({
  declarations: [
    SelectCenterTypeDialog,
  ],
  exports: [
    SelectCenterTypeDialog,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    DialogModule,
    FormFieldModule,
  ],
  providers: [],
})
export class SelectCenterTypeDialogModule { }
