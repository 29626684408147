import { ElementRef } from '@angular/core';

export class DomUtils {

  static isEmptyObject(obj: any) {
		for (var name in obj) {
			return false;
		}
		return true;
  }

  static decodeText(text: string, keepLineBreaks?: boolean): string {
    var div = document.createElement('div');
    div.innerHTML = (text || '').replace(/<br>/g, '\r\n');
    if (keepLineBreaks) {
      return div.innerText.trim();
    } else {
      return div.innerText.replace(/\r?\n|\r/g, ' ').trim();
    }
  }

  static encodeText(text: string): string {
    const textArea = document.createElement('textarea');
    textArea.innerText = text || '';
    return textArea.innerHTML.split("<br>").join("\n");
  }

  static getParents(elem: any, selector: string) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        (Element.prototype as any).matchesSelector ||
        (Element.prototype as any).mozMatchesSelector ||
        (Element.prototype as any).msMatchesSelector ||
        (Element.prototype as any).oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Setup parents array
    var parents = [];

    // Get matching parent elements
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      // Add matching parents to array
      if ( selector ) {
        if ( elem.matches( selector ) ) {
          parents.push( elem );
        }
      } else {
        parents.push( elem );
      }
    }

    return parents;
  }

  static highlight(text: string, search: string): string {
    if (search && (text !== null && text !== undefined)) {
      search = search.replace(/\*/g, ''); // remove wildcards?...
      const pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      const regex = new RegExp(pattern, 'gi');

      return text.toString().replace(regex, (match) => { return `<em>${match}</em>`; });
    } else {
      return text || '';
    }
  }

  static maskInput(inputId: string, mask: RegExp) {
    const searchEl = document.getElementById(inputId);
    if (searchEl) {
      searchEl.onkeyup = () => {
        const value = (searchEl as HTMLInputElement).value;
        const match = value.match(mask);
        if (!match || match[0] !== value) {
          (searchEl as HTMLInputElement).value = !match ? '' : match.join('');
        }
      }
    }
  }

  static copyTextToClipboard(text) {
    if (!(navigator as any).clipboard) {
      DomUtils.fallbackCopyTextToClipboard(text);
      return;
    }
    (navigator as any).clipboard.writeText(text)
    .then(() => {
      console.log('Copying to clipboard was successful!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

  private static fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

}