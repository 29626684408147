import { JsonUtils } from 'app/shared/utils';
import { WebObject } from '../web-base';
import { WorkspaceSolutionTypeMember } from '../workspace-solution-type';
import { FilterStringFilterGroup } from './filter-string-filter-group.model';
import { ObjectMemberValueFilter } from './object-member-value-filter.model';


export interface IFilterStringFilter {

  readonly filterTypeGuidId: string;

  filterGroups?: FilterStringFilterGroup[];
  isGroup: boolean;

}

export class FilterStringFilter implements IFilterStringFilter {

  readonly filterTypeGuidId: string;

  filterGroups?: FilterStringFilterGroup[];
  isGroup: boolean;

  constructor(item?: Partial<IFilterStringFilter>) {
    switch (item?.filterTypeGuidId) {
      case ObjectMemberValueFilter.filterTypeGuidId:
        Object.assign(this, new ObjectMemberValueFilter(item));
        break;
      default:
        Object.assign(this, item);

        this.filterGroups = (this.filterGroups || []).map(x => new FilterStringFilterGroup(x));
    }
  }

  static doesWebObjectPassFilter(filterStringFilter: IFilterStringFilter, webObject: WebObject) {
    if (!filterStringFilter.filterGroups?.length) return true;

    let groupResult = null;
    for (const filterGroup of filterStringFilter.filterGroups) {
      let stepResult = this.evalFilterGroupResult(filterGroup, webObject);
      groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
    }
    return groupResult;
  }

  private static evalFilterGroupResult(outterfilterGroup: FilterStringFilterGroup, webObject: WebObject): boolean {
    if (!outterfilterGroup.filter?.filterGroups?.length) {
      return this.evalFilterResult(outterfilterGroup.filter, webObject);
    }

    let groupResult = null;
    for (const filterGroup of outterfilterGroup.filter.filterGroups) {
      let stepResult = this.evalFilterResult(filterGroup.filter, webObject);
      groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
    }
    return groupResult;
  }

  private static evalFilterResult(filter: any, webObject: WebObject) {
    if (!filter || !webObject) return null;

    let stepResult = null;
    const stMember = new WorkspaceSolutionTypeMember(filter.member);
    const filterValue = JsonUtils.deepFind(webObject, stMember.getWeb2ObjectPath());
    switch (filter.operator) {
      case '=':
        stepResult = filter.value == filterValue;
        break;
      case '!=':
        stepResult = filter.value != filterValue;
        break;
      case '<':
        stepResult = filter.value < filterValue;
        break;
      case '<=':
        stepResult = filter.value <= filterValue;
        break;
      case '>':
        stepResult = filter.value > filterValue;
        break;
      case '>=':
        stepResult = filter.value >= filterValue;
        break;
    }
    return stepResult;
  }

  toJSON() {
    switch (this.filterTypeGuidId) {
      case ObjectMemberValueFilter.filterTypeGuidId:
        return (new ObjectMemberValueFilter(this as any)).toJSON();
      default:
        return this;
    }
  }


}
