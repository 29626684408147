import { Injectable } from '@angular/core';
import { WorkspaceWidgetTemplate } from 'app/center-v2/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { GuidUtils } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CoreAdminWidgetService {

  private urlSuffixPlaceholder = 'centerv2/coreadmin/widget/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  getTemplate(widgetTemplateGuidId: string): Observable<WorkspaceWidgetTemplate> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'template/get'),
      {
        widgetTemplateGuidId: widgetTemplateGuidId,
      },
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidgetTemplate(response.widgetTemplate) : null;
      })
    );
  }

  setSetting(widgetTemplateGuidId: string, name: string, value: any, settingGuidId?: string, removeSetting?: boolean): Observable<WorkspaceWidgetTemplate> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'template/setsetting'),
      {
        name: name,
        jsonValue: JSON.stringify(value),
        settingGuidId: settingGuidId || GuidUtils.emptyGuid(),
        widgetTemplateGuidId: widgetTemplateGuidId,
        removeSetting: removeSetting || false,
      },
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidgetTemplate(response.widgetTemplate) : null;
      })
    );
  }

  updateTemplate(widgetTemplate: WorkspaceWidgetTemplate): Observable<WorkspaceWidgetTemplate> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'template/update'),
      widgetTemplate,
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidgetTemplate(response.widgetTemplate) : null;
      })
    );
  }

}
