import { CenterType, TreeRelationSource } from '.';
import { CommentSource } from './comment-source.model';
import { DataSource } from './data-source.model';
import { Permission } from './permission.model';
import { TreeSource } from './tree-source.model';

export class DataResponse {
  webDataSources: DataSource[];
  webTreeSources: TreeSource[];
  webCenterTypes?: CenterType[];
  webTraceSources?: any[];
  webCommentSources?: CommentSource[];
  webSessionPermissions?: Permission[];

  constructor(item?: any) {
    Object.assign(this, item);

    this.webDataSources = (this.webDataSources || []).map(x => new DataSource(x));

    if (this.webTreeSources) {
      this.webTreeSources = (this.webTreeSources || []).map(x => new TreeSource(x));
      for (const ts of this.webTreeSources) {
        ts.treeRelationSources = (ts.treeRelationSources || []).map(x => new TreeRelationSource(x));
      }
    }
  }

}
