import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { DateTimeIndexUtils } from '../utils/date-time-index.utils';


@Pipe({
  name: 'lcDate',
  standalone: true,
})
export class DatePipe implements PipeTransform {

  transform(dt: Date | string): string {
    if (!dt) return '';
    if (dt instanceof Date && isNaN(dt.getTime())) return '';

    if (dt instanceof Date) {
      return format(dt, 'yyyy-MM-dd');
    } else if (typeof dt === 'string') {
      return format(new Date(dt), 'yyyy-MM-dd');
    } else {
      return format(DateTimeIndexUtils.decodeDayIndexToDate(dt), 'yyyy-MM-dd');
    }
  }
}
