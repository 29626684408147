import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, expand, map, takeWhile } from 'rxjs/operators';
import { Commit, CommitResponse, SystemTaskStatus } from '../../models';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { ApiCenterService } from '../api/api-center.service';
import { LookupService } from '../lookup/lookup.service';


@Injectable({
  providedIn: 'root'
})
export class CommitService {

  private urlSuffixPlaceholder = 'api/v0/{what}';
  private urlSuffixV2Placeholder = '{what}';

  constructor(
    private apiService: ApiCenterService,
    private apiV2Service: ApiCenterV2Service,
    private lookupService: LookupService,
  ) { }

  post(commit: Commit): Observable<CommitResponse> {
    return this.apiService.post<CommitResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'commit'),
      commit
    )
    .pipe(
      expand((commitResponse: CommitResponse) => {
        if (commitResponse.hasSystemTask && !commitResponse.$systemTaskComplete) {
          return this.apiV2Service.post<SystemTaskStatus>(
            this.urlSuffixV2Placeholder.replace('{what}', 'systemtask/status'),
            {
              systemTaskGuidId: commitResponse.systemTaskGuidId
            }
          ).pipe(
            delay(1000),
            map((systemTaskStatus: SystemTaskStatus) => {
              commitResponse.$systemTaskComplete = systemTaskStatus.complete || systemTaskStatus.exception;
              return commitResponse
            })
          );
        } else {
          commitResponse.$systemTaskCompleteAndEmitted = true;
          return of(commitResponse);
        }
      }, 1),
      takeWhile((commitResponse: any) => {
        return (!commitResponse.hasSystemTask && !commitResponse.$systemTaskCompleteAndEmitted) ||
        (commitResponse.hasSystemTask && !commitResponse.$systemTaskCompleteAndEmitted);
      }),
      map((cr: CommitResponse) => {
        if (cr.webSources) {
          cr = new CommitResponse(cr);
          if (cr.webSources.webDataSources) {
            this.lookupService.setDataSource(cr.webSources.webDataSources);
          }
          if (cr.webSources.webTreeSources) {
            this.lookupService.setTreeSource(cr.webSources.webTreeSources);
          }
        }
        return cr;
      })
    );
  }

}
