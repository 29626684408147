export enum BarcodeType {
  Code128 = 'Code 128',
  Code39 = 'Code 39',
  Codabar = 'Codabar',
  EAN_8 = 'EAN-8',
  EAN_13 = 'EAN-13',
  FIM = 'FIM',
  ITF = 'ITF',
  UPC_A = 'UPC-A',
}
