import { Text } from '../../../center/shared/models';

export let textsDB: Text[] = [
  {
    guidId: '',
    language: 'en',
    textGuidId: '08da2986-a886-4753-a38d-4874a3e84dec',
    text: 'Logic User',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '08da2986-a886-4753-a38d-4874a3e84dec',
    text: 'Logik Användare',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '1d09e373-3b2c-4068-8755-cabce6b3d303',
    text: 'Description',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '1d09e373-3b2c-4068-8755-cabce6b3d303',
    text: 'Beskrivning',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '9d128318-486b-4b9c-a5d6-fe9b4cbf03cd',
    text: 'Name',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '9d128318-486b-4b9c-a5d6-fe9b4cbf03cd',
    text: 'Namn',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'ddfd651a-c8b9-42c9-8f86-b2ed18d31cd8',
    text: 'Name',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'ddfd651a-c8b9-42c9-8f86-b2ed18d31cd8',
    text: 'Namn',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'f148adb7-1065-4355-aea0-1947bc9343bc',
    text: 'Name',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'f148adb7-1065-4355-aea0-1947bc9343bc',
    text: 'Namn',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'e454aeb1-1008-4651-bbbb-5335491d6643',
    text: 'Username',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'e454aeb1-1008-4651-bbbb-5335491d6643',
    text: 'Användarnamn',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'b1ec33d8-77fd-43a2-9f0a-687a22db0870',
    text: 'Password',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'b1ec33d8-77fd-43a2-9f0a-687a22db0870',
    text: 'Lösenord',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '753236f1-dac5-4217-a066-2b0f58b5065b',
    text: 'Required Field',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '753236f1-dac5-4217-a066-2b0f58b5065b',
    text: 'Obligatoriskt fält',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'b7d0a724-7562-4a2f-b4cd-cb34325a3a81',
    text: 'Required Field',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'b7d0a724-7562-4a2f-b4cd-cb34325a3a81',
    text: 'Obligatoriskt fält',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '62a13c86-beb5-4e12-93b2-f9a93c3f4b21',
    text: 'Must contain at least one lowercase (a-z), one uppercase (A-Z), one number (0-9) and one special char.',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '62a13c86-beb5-4e12-93b2-f9a93c3f4b21',
    text: 'Måste innehålla minst en liten bokstav (a-z), en stor bokstav (A-Z), ett nummer (0-9) samt ett specialtecken.',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'b961ad86-4d31-412e-bd7a-dc22031ecdaa',
    text: 'Must contain at least one lowercase (a-z), one uppercase (A-Z), one number (0-9) and one special char.',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'b961ad86-4d31-412e-bd7a-dc22031ecdaa',
    text: 'Måste innehålla minst en liten bokstav (a-z), en stor bokstav (A-Z), ett nummer (0-9) samt ett specialtecken.',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '257e1e0a-2a55-4eea-a5f7-3ad7998bce98',
    text: 'RawValue',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '257e1e0a-2a55-4eea-a5f7-3ad7998bce98',
    text: 'Obehandlat värde',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '4a19b234-9157-4af9-95c3-d4f9167c9af8',
    text: 'Value',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '4a19b234-9157-4af9-95c3-d4f9167c9af8',
    text: 'Värde',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '76164336-0a83-495a-99e8-003bf5243793',
    text: 'Type',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '76164336-0a83-495a-99e8-003bf5243793',
    text: 'Typ',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '37bec083-9be7-480a-a0eb-62003a0d31ee',
    text: 'Scale AddFactor',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '37bec083-9be7-480a-a0eb-62003a0d31ee',
    text: 'Skalning Addera',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '41ad6ec9-b320-480d-875d-5f2646d0f61f',
    text: 'Scale MultiFactor',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '41ad6ec9-b320-480d-875d-5f2646d0f61f',
    text: 'Skalning Multiplicera',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '2c0fab6c-4f83-4aa7-9f7c-bdc0e14b1992',
    text: 'Scale Comment',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '2c0fab6c-4f83-4aa7-9f7c-bdc0e14b1992',
    text: 'Skalningskommentar',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'edb26c3c-e266-49db-a47a-931fd42b6fdf',
    text: 'Depot No',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'edb26c3c-e266-49db-a47a-931fd42b6fdf',
    text: 'DepåNr',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '0ca7cef1-124f-4473-84dc-24fac135b973',
    text: 'Receiver No',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '0ca7cef1-124f-4473-84dc-24fac135b973',
    text: 'MottagarNr',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'd9c8b424-2e36-48dc-8cf2-60cd6417d62c',
    text: 'PreemSiteInfo',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'd9c8b424-2e36-48dc-8cf2-60cd6417d62c',
    text: 'PreemSiteInfo',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '64219c0f-9ae4-4758-ace3-c805bd21f472',
    text: 'Address 1',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '64219c0f-9ae4-4758-ace3-c805bd21f472',
    text: 'Adress 1',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '01cfc48b-3049-4f55-8e92-d3b0be146fef',
    text: 'Address 2',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '01cfc48b-3049-4f55-8e92-d3b0be146fef',
    text: 'Adress 2',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: 'dcf808ae-7df8-4299-8cf6-f3d7f37cd605',
    text: 'ZipCode',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: 'dcf808ae-7df8-4299-8cf6-f3d7f37cd605',
    text: 'Postnummer',
  },
  {
    guidId: '',
    language: 'en',
    textGuidId: '77d780a9-f2c5-47c3-9684-97ee2d092ca7',
    text: 'City',
  },
  {
    guidId: '',
    language: 'se',
    textGuidId: '77d780a9-f2c5-47c3-9684-97ee2d092ca7',
    text: 'Stad',
  },
];
