
import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api/api-center-v2.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebLoadMap, WebObject, WebResponse } from 'app/center-v2/shared/models';
import { ProductionPlannerUpdatePlanDayArticle } from '../models/production-planner-update-plan-day-article.model';


@Injectable({
  providedIn: 'root'
})
export class ProductionPlannerService {

  private urlSuffixPlaceholder = 'centerv2/templatesolution/productionplannersystem/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  getWeekPlan(
    templatePlan: boolean,
    weekIndex: number,
    webLoadMap: WebLoadMap,
  ): Observable<{ articleGuidIds: string[], web2Objects: WebObject[] }> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'plan/getweekplan'),
      {
        templatePlan: templatePlan,
        weekIndex: weekIndex,
        web2LoadMap: webLoadMap,
      }
    );
  }

  addPlanDayArticles(
    articleGuidId: string,
    planDayGuidIds: string[],
    isTemplatePlanDayArticle: boolean,
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'plan/addplandayarticles'),
      {
        articleGuidId: articleGuidId,
        planDayGuidIds: planDayGuidIds,
        isTemplatePlanDayArticle: isTemplatePlanDayArticle,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  updatePlanDayArticle(
    planDayArticleGuidId: string,
    setQuantity: boolean,
    quantity: number,
    setMinQuantity: boolean,
    minQuantity: number,
    setComment: boolean,
    comment: string,
    syncTemplatePlanDayArticle: boolean,
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'plan/updateplandayarticle'),
      {
        planDayArticleGuidId: planDayArticleGuidId,
        setQuantity: setQuantity,
        quantity: quantity || 0,
        setMinQuantity: setMinQuantity,
        minQuantity: minQuantity || 0,
        setComment: setComment,
        comment: comment,
        setSyncTemplatePlanDayArticle: syncTemplatePlanDayArticle,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  updatePlanDayArticles(
    updatePlanDayArticles: ProductionPlannerUpdatePlanDayArticle[]
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'plan/updateplandayarticles'),
      {
        updatePlanDayArticles: updatePlanDayArticles.map((upda: ProductionPlannerUpdatePlanDayArticle) => {
          upda.quantity = upda.quantity || 0; // needs a default value
          upda.daySegments = upda.daySegments || [];
          return upda;
        }),
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  setToTemplate(
    planDayArticleGuidId: string,
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'plan/settotemplate'),
      {
        planDayArticleGuidId: planDayArticleGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

}
