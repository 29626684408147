import { Injectable } from '@angular/core';
import { FilterStringFilter } from 'app/center-v2/shared/models/filter-string/filter-string-filter.model';
import { DictString } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { ArrayUtils, CaseUtils, GuidUtils } from 'app/shared/utils';
import { Observable, of, zip } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { WebCenterType, WebObject, WebPointerStatus, WebRelationPointer, WebResponse, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { WebLoadMap } from 'app/center-v2/shared/models/web-base/web-load-map.model';
import { AdvancedSearchResponse } from '../models/advanced-search-response.model';
import { WebDesignTree } from '../models/web-base/web-design-tree.model';
import { SelectItem } from 'primeng/api';
import { WebCustomSearch } from '../models/web-base/web-custom-search.model';


@Injectable({
  providedIn: 'root'
})
export class GenericService {

  private urlSuffixPlaceholder = 'centerv2/generic/{what}';
  private urlSuffixPlaceholderV2 = 'centerv2/generic2/{what}';
  private urlSuffixPlaceholderV3 = 'centerv2/generic3/{what}';

  private webCenterTypeCache: DictString<WebCenterType> = {};

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  /** @deprecated */
  get(guidId: string, fullObject?: boolean, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        workspaceGuidId: workspaceGuidId,
        web2LoadTemplate: fullObject ? 'Complete' : 'Base',
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  /** @deprecated */
  getMap(guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/get'),
      {
        guidId: guidId,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  /** @deprecated */
  getV2(guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.getV3({
      guidId: guidId,
      includeCenterTypes: includeCenterTypes,
      webLoadMap: webLoadMap,
      workspaceGuidId: workspaceGuidId,
    });
  }

  getV3(options: {
    accessTokenGuidId?: string,
    baseRelationTypeGuidId?: string,
    guidId: string,
    includeCenterTypes?: boolean,
    nonGenericTypeGuidIds?: string[],
    useBaseObject?: boolean,
    webLoadMap: WebLoadMap,
    workspaceGuidId?: string }): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV3.replace('{what}', 'get'),
      {
        accessTokenGuidId: options.accessTokenGuidId,
        baseRelationTypeGuidId: options.baseRelationTypeGuidId,
        guidId: options.guidId,
        includeCenterTypes: options.includeCenterTypes,
        nonGenericTypeGuidIds: options.nonGenericTypeGuidIds,
        useBaseObject: options.useBaseObject,
        web2LoadMap: options.webLoadMap,
        workspaceGuidId: options.workspaceGuidId,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  getBatch(guidIds: string[], webLoadMap: WebLoadMap, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'getbatch'),
      {
        guidIds: guidIds,
        includeCenterTypes: includeCenterTypes,
        web2LoadMap: webLoadMap,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  getForPrint(solutionTypes: WorkspaceSolutionType[], guidId: string, webLoadMap: WebLoadMap, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.getV2(guidId, webLoadMap, includeCenterTypes, workspaceGuidId)
    .pipe(
      mergeMap((response: WebResponse) => {
        const webObject = response.getWebObject();
        const extraRequests = [];

        let allWebObjects = [webObject];
        allWebObjects.push(...Object.values(webObject.subTypes));
        for (const wo of allWebObjects) {
          for (const relation of Object.values(wo.relations)) {
            if (!relation) continue;
            const relationWO = (relation as WebRelationPointer)?.web2Object;
            if (!relationWO) continue;

            allWebObjects.push(relationWO)
            allWebObjects.push(...Object.values(relationWO.subTypes));
          }
        }
        allWebObjects = ArrayUtils.uniqBy(allWebObjects, wo => wo.typeGuidId + wo.guidId);
        // skip the first level of objects as the web2LoadMap would have returned the relations for them
        allWebObjects = allWebObjects.filter(wo => !Object.keys(wo.relations)?.length);

        for (const wo of allWebObjects) {
          const st = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === wo.typeGuidId)
          const relationWebLoadMap = WebLoadMap.buildFromWorkspaceSolutionTypeV2(st, ['style.print.members', 'style.form.members'], solutionTypes);
          if (!relationWebLoadMap?.web2LoadMapCenterTypes?.length) continue;

          extraRequests.push(
            this.getV2(wo.guidId, relationWebLoadMap, true)
            .pipe(
              map((response2: WebResponse) => {
                let webObjectOrSubType = response2.getWebObject();
                if (webObjectOrSubType.typeGuidId !== wo.typeGuidId) {
                  for (const key of Object.keys(webObjectOrSubType.subTypes)) {
                    if (webObjectOrSubType.subTypes[key].typeGuidId === wo.typeGuidId) {
                      webObjectOrSubType = webObjectOrSubType.subTypes[key];
                      break;
                    }
                  }
                }
                Object.assign(wo.relations, webObjectOrSubType.relations);
                response.addCenterTypes(response2.getCenterTypes());

                return null;
              })
            )
          )
        }

        if (!extraRequests.length) return of(response);
        return zip(...extraRequests)
        .pipe(
          map(() => {
            return response;
          })
        )
      }),
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        return response;
      })
    );
  }

  getObjectToken(guidId: string): Observable<string> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'getobjecttoken'),
      {
        guidId: guidId,
      }
    ).pipe(
      map((response: any) => {
        return response?.token;
      })
    );
  }

  getTypes(typeGuidIds: string[]): Observable<WebResponse> {
    const cachedWebCenterTypes = (typeGuidIds || []).map((typeGuidId: string) => {
      return this.webCenterTypeCache[typeGuidId];
    }).filter(x => x);
    typeGuidIds = (typeGuidIds || []).filter((typeGuidId: string) => {
      return !cachedWebCenterTypes.some(x => x.typeGuidId === typeGuidId);
    });

    const request = typeGuidIds?.length ?
      this.apiService.post(
        this.urlSuffixPlaceholder.replace('{what}', 'types/get'),
        {
          typeGuidIds: typeGuidIds,
        }
      ) :
      of(new WebResponse()).pipe(delay(100));

    return request.pipe(
      map((result: any) => {
        if (!result) return null;

        const response =  new WebResponse(result);
        for (const webCenterType of response.getCenterTypes()) {
          this.webCenterTypeCache[webCenterType.typeGuidId] = webCenterType;
        }
        for (const webCenterType of cachedWebCenterTypes) {
          response.addCenterType(webCenterType);
        }
        return response;
      })
    );
  }

  list(
    typeGuidId: string,
    webLoadMap?: WebLoadMap,
    filterStringFilter?: FilterStringFilter,
    includeCenterTypes?: boolean,
    siteGuidId?: string,
    subSiteLevels?: number,
    fromDateTime?: string,
    toDateTime?: string,
    nonGenericTypeGuidIds?: string[],
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'list'),
      {
        fromDateTime: fromDateTime,
        includeCenterTypes: includeCenterTypes || false,
        siteGuidId: siteGuidId,
        subSiteLevels: subSiteLevels || undefined,
        toDateTime: toDateTime || undefined,
        typeGuidId: typeGuidId,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        // web2LoadTemplate: 'List',
        nonGenericTypeGuidIds: nonGenericTypeGuidIds,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  listPaged(
    typeGuidId: string,
    page: number,
    perPageCount: number,
    webLoadMap?: WebLoadMap,
    filterStringFilter?: FilterStringFilter,
    includeCenterTypes?: boolean,
    siteGuidId?: string,
    subSiteLevels?: number,
    fromDateTime?: string,
    toDateTime?: string,
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'list'),
      {
        fromDateTime: fromDateTime,
        includeCenterTypes: includeCenterTypes || false,
        siteGuidId: siteGuidId,
        subSiteLevels: subSiteLevels || undefined,
        toDateTime: toDateTime,
        typeGuidId: typeGuidId,
        useWeb2Page: true,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        web2Page: page,
        web2PageCount: perPageCount,
        // web2PageToken: null,
        // web2PageSort: null,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  /** @deprecated */
  relationMap(parentGuidId: string, typeGuidId: string, relationName: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  /** @deprecated */
  relation(parentGuidId: string, typeGuidId: string, relationName: string, fullObjects?: boolean, includeCenterTypes?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'relation'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        web2LoadTemplate: fullObjects ? 'Complete' : undefined,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationV2(parentWebObject: WebObject, relationName: string, webLoadMap?: WebLoadMap, filterStringFilter?: FilterStringFilter, includeCenterTypes?: boolean, siteRestricted?: boolean, workspaceGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'relation'),
      {
        guidId: parentWebObject.guidId,
        typeGuidId: parentWebObject.typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        siteRestricted: siteRestricted || undefined,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationV2Paged(
    parentWebObject: WebObject,
    relationName: string,
    page: number,
    perPageCount: number,
    webLoadMap?: WebLoadMap,
    filterStringFilter?: FilterStringFilter,
    includeCenterTypes?: boolean,
    workspaceGuidId?: string,
  ): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'relation'),
      {
        guidId: parentWebObject.guidId,
        typeGuidId: parentWebObject.typeGuidId,
        relationName: relationName,
        includeCenterTypes: includeCenterTypes,
        // useWeb2CenterTypePointer: true,
        useWeb2Page: true,
        web2FilterString: {
          filter: filterStringFilter || null,
          web2LoadMap: webLoadMap || new WebLoadMap(),
        },
        web2Page: page,
        web2PageCount: perPageCount,
        // web2PageToken: null,
        // web2PageSort: null,
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  relationsCount(parentGuidId: string, typeGuidId: string, relationNames: string[]): Observable<DictString<number>> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'relationscount'),
      {
        guidId: parentGuidId,
        typeGuidId: typeGuidId,
        relationNames: relationNames,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.relationsCount : null;
      })
    );
  }

  search(typeGuidId: string, member: string, value: any, wildcardSearch?: boolean, incaseSensitive?: boolean, webLoadMap?: WebLoadMap, includeCenterTypes?: boolean, adminCallGuidId?: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'search'),
      {
        adminCallGuidId: adminCallGuidId,
        typeGuidId: typeGuidId,
        member: member,
        value: value,
        wildcardSearch: wildcardSearch,
        incaseSensetive: incaseSensitive,
        web2LoadMap: webLoadMap,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  advancedSearch(web2FilterStrings: DictString<{ filter: FilterStringFilter, web2LoadMap: WebLoadMap }>, web2CustomSearches?: DictString<WebCustomSearch>, fromDateTime?: string, toDateTime?: string, includeCenterTypes?: boolean, siteGuidId?: string): Observable<AdvancedSearchResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'advancesearch'),
      {
        fromDateTime: fromDateTime,
        includeCenterTypes: includeCenterTypes,
        siteGuidId: siteGuidId,
        toDateTime: toDateTime,
        web2CustomSearches: web2CustomSearches,
        web2FilterStrings: web2FilterStrings,
      }
    ).pipe(
      map((response: any) => {
        return response ? new AdvancedSearchResponse(response) : null;
      })
    );
  }

  newDraftObject(typeGuidId: string, parentGuidId?: string, includeCenterTypes?: boolean, siteGuidId?: string): Observable<WebResponse> {
    if (includeCenterTypes && !!this.webCenterTypeCache[typeGuidId]) {
      includeCenterTypes = false;
    }

    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'new'),
      {
        typeGuidId: typeGuidId,
        parentGuidId: parentGuidId,
        includeCenterTypes: includeCenterTypes,
        siteGuidId: siteGuidId,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        const response = new WebResponse(result);
        if (!includeCenterTypes && !!this.webCenterTypeCache[typeGuidId]) {
          response.addCenterType(this.webCenterTypeCache[typeGuidId]);
        } else if (includeCenterTypes) {
          this.webCenterTypeCache[typeGuidId] = response.getCenterType(typeGuidId);
        }

        return response;
      })
    );
  }

  /** @deprecated */
  newDraftRelation(parentTypeGuidId: string, relationName: string, childObjectOrChildGuidId: WebObject | string, workspaceGuidId?: string): Observable<WebRelationPointer> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'newrelation'),
      {
        parentTypeGuidId: parentTypeGuidId,
        relationName: relationName,
        childGuidId: (childObjectOrChildGuidId instanceof WebObject ? childObjectOrChildGuidId.guidId : childObjectOrChildGuidId) || GuidUtils.emptyGuid(),
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (!response?.web2RelationPointer) return null;

        const wrp = new WebRelationPointer(response.web2RelationPointer);
        if (childObjectOrChildGuidId instanceof WebObject) wrp.web2Object = childObjectOrChildGuidId;
        return wrp;
      })
    );
  }

  newDraftRelationV2(parentWebObject: WebObject, relationName: string, childObjectOrChildGuidId: WebObject | string, collectionRelation: boolean, workspaceGuidId?: string): Observable<WebRelationPointer> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'newrelation'),
      {
        parentTypeGuidId: parentWebObject.typeGuidId,
        relationName: relationName,
        childGuidId: (childObjectOrChildGuidId instanceof WebObject ? childObjectOrChildGuidId.guidId : childObjectOrChildGuidId) || GuidUtils.emptyGuid(),
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        if (!response?.web2RelationPointer) return null;

        const wrp = new WebRelationPointer(response.web2RelationPointer);
        if (childObjectOrChildGuidId instanceof WebObject) wrp.web2Object = childObjectOrChildGuidId;
        parentWebObject.addRelationPointer(relationName, wrp, collectionRelation);
        return wrp;
      })
    );
  }

  /** @deprecated */
  newDraftSubType(guidId: string, typeGuidId: string, includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'newsubtype'),
      {
        guidId: guidId,
        typeGuidId: typeGuidId,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  newDraftSubTypeV2(webObject: WebObject, typeGuidIds: string[], includeCenterTypes?: boolean): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'newsubtype'),
      {
        guidId: webObject.guidId,
        typeGuidIds: typeGuidIds,
        includeCenterTypes: includeCenterTypes,
      }
    ).pipe(
      map((response: any) => {
        if (!response) return null;

        response = new WebResponse(response);

        const webObjectSutTypes = response.getWebObjectSubTypes() || {};
        for (const subTypeKey of Object.keys(webObjectSutTypes)) {
          webObject.subTypes[subTypeKey] = webObjectSutTypes[subTypeKey];
        }

        return response;
      })
    );
  }

  copy(guidId: string, overridingDeepCopyRelationTypes?: any): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'copy'),
      {
        guidId: guidId,
        overridingDeepCopyRelationTypes: overridingDeepCopyRelationTypes,
      }
    ).pipe(
      map((result: any) => {
        if (!result) return null;

        return new WebResponse(result);
      })
    );
  }

  update(webObjects: WebObject[], skipUpdateOriginal?: boolean, siteGuidId?: string): Observable<WebResponse> {
    return this.updateV2({
      skipUpdateOriginal: skipUpdateOriginal,
      siteGuidId: siteGuidId,
      webObjects: webObjects,
    });
  }

  updateV2(options: { webObjects: WebObject[], skipUpdateOriginal?: boolean, siteGuidId?: string, accessTokenGuidId?: string }): Observable<WebResponse> {
    if (!options?.webObjects?.length) throw new Error('Missing options.webObjects!');

    return of(null)
    .pipe(
      mergeMap(() => {
        const webObjectsToUpdate = WebObject.cleanWebObjectsForUpdate(options.webObjects, options.siteGuidId);

        const hasUpdates = webObjectsToUpdate.some(wo => wo.web2Status !== WebPointerStatus.Update || Object.keys(wo.members || {}).length || Object.keys(wo.relations || {}).length || Object.keys(wo.subTypes || {}).length);
        if (!hasUpdates) return of(new WebResponse({ web2Objects: [], web2CenterTypes: null } as any));

        return this.apiService.post(
          this.urlSuffixPlaceholderV2.replace('{what}', 'update'),
          {
            accessTokenGuidId: options.accessTokenGuidId,
            siteGuidId: options.siteGuidId,
            web2Objects: webObjectsToUpdate,
          }
        );
      }),
      map((response: any) => {
        if (!response) return null;

        const webResponse = new WebResponse(response);
        if (options?.skipUpdateOriginal) return webResponse;

        const updatedWebObjects = webResponse.getWebObjects();
        for (const uwo of updatedWebObjects || []) {
          const existingWO = options.webObjects.find(wo => wo.guidId === uwo.guidId);
          if (!existingWO) continue;
          if (existingWO.typeGuidId === uwo.typeGuidId) {
            Object.assign(existingWO, uwo);
            existingWO.updateOriginalWebObject();
          } else {
            // we need to find the subtype
            Object.assign(existingWO, uwo.getSubType(existingWO.web2Type));
            existingWO.updateOriginalWebObject();
          }
        }
        return webResponse;
      })
    );
  }

  getDesignTree(
    designTreeGuidId: string,
    filterSiteGuidId: string,
    filterLevels: number,
    typeGuidIds: string[],
  ): Observable<WebDesignTree> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'designtree'),
      {
        designTreeGuidId: designTreeGuidId,
        filterSiteGuidId: filterSiteGuidId,
        filterLevels: filterLevels,
        designTreeTick: 0,
        typeGuidIds: typeGuidIds || [],
      }
    ).pipe(
      map((response: any) => {
        return response?.web2DesignTreeObject ? new WebDesignTree(response.web2DesignTreeObject) : null;
      })
    );
  }

  listCustomSearch(centerTypeGuidId: string): Observable<SelectItem[]> {
    return this.apiService.post(
      this.urlSuffixPlaceholderV2.replace('{what}', 'customsearch/listcustomsearch'),
      {
        centerTypeGuidId: centerTypeGuidId,
      }
    ).pipe(
      map((response: any) => {
        return (response?.customSearches || []).map((item: any) => { return { value: item.customSearchGuidId, label: item.name } });
      })
    );
  }

  convertListToSelectItems(webObjects: WebObject[], solutionType: WorkspaceSolutionType, webCenterType: WebCenterType) {
    if (!solutionType?.style?.grid?.structureType) {
      return (webObjects || [])
      .sort((a, b) => {
        return (a.members.name || '').localeCompare(b.members.name || '');
      })
      .map((wo: WebObject) => {
        return { label: wo.members.name, value: wo }
      });
    }

    const webCenterTypeMember = (webCenterType.members || []).find(x => x.memberId === webCenterType.structureParentMemberId);
    const webCenterTypeMemberName = CaseUtils.camelize(webCenterTypeMember.name);
    const allWebObjects = (webObjects || [])
    .sort((a, b) => {
      return (a.members[webCenterTypeMemberName] || '').localeCompare(b.members[webCenterTypeMemberName] || '')
        || (a.members.name || '').localeCompare(b.members.name || '');
    });

    const result = [];
    const missingChildWebObjects = [];
    for (let wo of allWebObjects || []) {
      let parentGuidId = wo.members[webCenterTypeMemberName];
      if (!parentGuidId) {
        result.push({ label: wo.members.name, value: wo });
        continue;
      }

      let existingParentIndex = result.findIndex(x => x.value.guidId === parentGuidId);
      if (existingParentIndex >= 0) {
        const parentIndent = parseInt((result[existingParentIndex].icon || '').substring('icon-indent-'.length) || '0');
        while (result[existingParentIndex + 1] && parseInt((result[existingParentIndex + 1].icon || '').substring('icon-indent-'.length) || '0') > parentIndent) {
          existingParentIndex++;
        }
        result.splice(
          existingParentIndex + 1,
          0,
          {
            icon: 'icon-indent-' + (parentIndent + 1),
            label: wo.members.name,
            value: wo,
          });
        continue;
      } else {
        missingChildWebObjects.push(wo);
      }
    };

    let i = 0;
    while (missingChildWebObjects.length && i <= allWebObjects.length) {
      let parentGuidId = missingChildWebObjects[0].members[webCenterTypeMemberName];
      let existingParentIndex = result.findIndex(x => x.value.guidId === parentGuidId);
      if (existingParentIndex >= 0) {
        const parentIndent = parseInt((result[existingParentIndex].icon || '').substring('icon-indent-'.length) || '0');
        while (result[existingParentIndex + 1] && parseInt((result[existingParentIndex + 1].icon || '').substring('icon-indent-'.length) || '0') > parentIndent) {
          existingParentIndex++;
        }
        result.splice(existingParentIndex + 1, 0, { label: missingChildWebObjects[0].members.name, value: missingChildWebObjects[0], icon: 'icon-indent-' + (parentIndent + 1) });
      } else {
        missingChildWebObjects.push(missingChildWebObjects[0]);
      }
      missingChildWebObjects.splice(0, 1);
      i++;
    }

    return result;
  }

}
