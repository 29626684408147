import { LanguageTextsService } from '../services/language-texts/language-texts.service';
import { CenterTypeUI } from './center-type-ui.model';
import { Collection } from './collection.model';
import { CenterTypeMember } from './center-type-member.model';
import { TypeTreeRelation } from './type-tree-relation.model';


export class CenterType {

  typeGuidId: string;
  name: string;
  dataName: string;
  structuralType: boolean;
  profileGuidId: string;
  hasSubTypes: boolean;
  subTypesTypeGuidId: string[];
  parentTypeGuidIds: string[];
  isSubType: boolean;
  isTreeRoot: boolean;
  members: CenterTypeMember[];
  relations: TypeTreeRelation[];
  centerTypeUi: CenterTypeUI;
  designTreeNodeIconUri: string;
  collections: Collection[];
  hasTraces: boolean;

  tick: number;

  constructor(item?: any) {
    Object.assign(this, item);

    this.addAdditionalClientSideInfo();
  }

  getRelationsByTypeTreeRelationGuidId(typeTreeRelationGuidId: string): TypeTreeRelation[] {
    return this.relations.filter((ttr: TypeTreeRelation) => {
      return ttr.typeTreeRelationGuidId === typeTreeRelationGuidId;
    });
  }

  private addAdditionalClientSideInfo() {
    // need to add an extra $key to the member objects -> lowercase name
    this.members = (this.members || [])
    .map((m: CenterTypeMember) => {
      m.$key = (m.name || '').toLowerCase();
      return m;
    });
    for (const relation of this.relations || []) {
      if (relation.relationMembers) {
        relation.relationMembers = (relation.relationMembers || [])
        .map((m: CenterTypeMember) => {
          m.$key = (m.name || '').toLowerCase();
          return m;
        });
      }
    }

    if (this.centerTypeUi) {
      this.centerTypeUi.nameText = LanguageTextsService.getTextOrDefault(this.centerTypeUi.nameTextGuidId, this.name);
      this.centerTypeUi.descriptionText = LanguageTextsService.getTextOrDefault(this.centerTypeUi.descriptionTextGuidId, 'Type Description');
    }

    // then, I map out the server types to HTML types...or special types
    for (const member of this.members || []) {
      this.mapServerTypesAndTexts(member);
    }
    for (const relation of this.relations || []) {
      if (relation.relationMembers) {
        for (const member of relation.relationMembers || []) {
          this.mapServerTypesAndTexts(member);
        }
      }
    }
  }

  private mapServerTypesAndTexts(member: CenterTypeMember) {
    if (member.centerTypeMemberUi) {
      member.centerTypeMemberUi.nameText = LanguageTextsService.getTextOrDefault(
        member.centerTypeMemberUi?.nameTextGuidId,
        member.name,
      );
      member.centerTypeMemberUi.descriptionText = LanguageTextsService.getTextOrDefault(
        member.centerTypeMemberUi.descriptionTextGuidId,
        'Member Description',
      );
      member.centerTypeMemberUi.validationFailedText = LanguageTextsService.getTextOrDefault(
        member.centerTypeMemberUi.validationFailedTextGuidId,
        'Validation Failed',
      );
    }

    if (member.specialMemberType) {
      member.$formControlType = member.specialMemberType.toLowerCase();
    } else if (member.centerTypeMemberUi?.viewControl) {
      member.$formControlType = member.centerTypeMemberUi.viewControl.toLowerCase();
    } else {
      switch (member.nativeType) {
        case 'System.String':
          member.$formControlType = 'text';
          break;
        case 'System.Int64':
        case 'System.Int32':
        case 'System.Double':
          member.$formControlType = 'number';
          break;
        case 'System.Boolean':
          member.$formControlType = 'checkbox';
          break;
      }
    }
  }

}
