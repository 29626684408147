<div class="flex-full-screen">
  <ngx-loading-bar class="loading-bar"
    [class.mobile]="windowWidth < sizeMD || currentUrl?.indexOf('/horeca') >= 0">
  </ngx-loading-bar>
  <ngx-loading [show]="isBusy || !webSkin"></ngx-loading>
  <p-toast position="top-center"></p-toast>

  <div class="body flex-1">
    <router-outlet></router-outlet>
  </div>
</div>

<lc-info-dialog></lc-info-dialog>

<div id="plugin-scripts"></div>
