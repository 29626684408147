import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormFieldBackofficePlatformReportResultMethodComponent } from './form-field-backoffice-platform-report-result-method.component';


@NgModule({
  declarations: [
    FormFieldBackofficePlatformReportResultMethodComponent,
  ],
  exports: [
    FormFieldBackofficePlatformReportResultMethodComponent,
  ],
  imports: [
    AppSharedV2Module,
    DropdownModule,
  ],
})
export class FormFieldBackofficePlatformReportResultMethodModule { }
