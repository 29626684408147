import { Injectable } from '@angular/core';
import { RunEngine } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class BaseGuidIdService {

  private urlSuffixPlaceholder = 'centerv2/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  fromBaseGuidId(baseGuidId: string): Observable<string> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'baseguidid'),
      {
        baseGuidId: baseGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.guidId : null;
      })
    );
  }

  toBaseGuidId(guidId: string): Observable<string> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'baseguidid'),
      {
        guidId: guidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.baseGuidId : null;
      })
    );
  }

}
