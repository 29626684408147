
export class WorkspaceWidgetSetting {

  guidId: string;
  name: string;
  sysVersion?: string;
  set jsonValue(value: string) {
    this._jsonValue = value;

    try {
      this.$value = JSON.parse(this.jsonValue || 'null');
    } catch (error) {
      this.$value = null;
    }
  }
  get jsonValue(): string {
    return this._jsonValue;
  }
  private _jsonValue?: string;

  $value?: any;

  constructor(item?: Partial<WorkspaceWidgetSetting>) {
    Object.assign(this, item);
  }

}
