import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowConnection, MoveConnectionRequest, NewConnectionRequest, NewConnectionResponse } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private urlSuffixPlaceholder = 'api/connection/{what}';

  constructor(private apiService: ApiStudioService) { }

  new(newRequest: NewConnectionRequest): Observable<NewConnectionResponse> {
    return this.apiService.post<NewConnectionResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      newRequest
    )
    .pipe(
      map((ncr: NewConnectionResponse) => {
        if (ncr) {
          ncr.connection = new FlowConnection(ncr.connection);
        }
        return ncr;
      })
    );
  }

  delete(connectionGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'delete'),
      {
        connectionGuidId: connectionGuidId,
      }
    );
  }

  move(moveRequest: MoveConnectionRequest): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'move'),
      moveRequest
    );
  }

}
