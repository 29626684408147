import { Injectable } from '@angular/core';
import { SystemEvent } from 'app/studio/models/system-event.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, ServerDeviceSolution, ServerPort2, SolutionEvent } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class ServerDeviceSolutionService {

  private urlSuffixPlaceholder = 'api/serverdevicesolution/{what}';

  constructor(private apiService: ApiStudioService) { }

  get(
    serverDeviceSolutionGuidId: string,
  ): Observable<ServerDeviceSolution> {
    return this.apiService.post<ServerDeviceSolution>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
      }
    )
    .pipe(
      map((response: any) => {
        return response ? new ServerDeviceSolution(response) : null;
      })
    );
  }

  newServerPort(
    serverDeviceSolutionGuidId: string,
    originalPortGuidId: string,
    name: string,
    serverRef: string,
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'serverport/new'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
        originalPortGuidId: originalPortGuidId,
        name: name,
        serverRef: serverRef,
      }
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new ServerPort2(far.sourceObject);
        }
        return far;
      })
    );
  }

  newEventServerPort(
    serverDeviceSolutionGuidId: string,
    originalPortGuidId: string,
    name: string,
    solutionEventGuidId: string,
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'event/serverport/new'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
        originalPortGuidId: originalPortGuidId,
        name: name,
        solutionEventGuidId: solutionEventGuidId,
      }
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new ServerPort2(far.sourceObject);
        }
        return far;
      })
    );
  }

  newTemplateSolutionSystemServerPort(
    serverDeviceSolutionGuidId: string,
    originalPortGuidId: string,
    name: string,
    templateSolutionSystemEventGuidId: string,
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'templatesolutionsystem/serverport/new'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
        originalPortGuidId: originalPortGuidId,
        name: name,
        templateSolutionSystemEventGuidId: templateSolutionSystemEventGuidId,
      }
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new ServerPort2(far.sourceObject);
        }
        return far;
      })
    );
  }

  listTemplateSolutionSystemEvents(
    serverDeviceSolutionGuidId: string,
  ): Observable<SystemEvent[]> {
    return this.apiService.post<SystemEvent[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'templatesolutionsystem/systemevents/list'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
      }
    )
    .pipe(
      map((response: any) => {
        const result = [];
        if (response) {
          for (const se of response.systemEvents || []) {
            result.push(new SystemEvent(se));
          }
        }
        return result;
      })
    );
  }

  listSolutionEvents(
    serverDeviceSolutionGuidId: string,
  ): Observable<SolutionEvent[]> {
    return this.apiService.post<SolutionEvent[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'event/solutionevent/list'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
      }
    )
    .pipe(
      map((response: any) => {
        const result = [];
        if (response) {
          for (const se of response.solutionEvents || []) {
            result.push(new SolutionEvent(se));
          }
        }
        return result;
      })
    );
  }

  newSolutionEvent(
    serverDeviceSolutionGuidId: string,
    eventName: string,
  ): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'event/solutionevent/new'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
        eventName: eventName,
      }
    )
    .pipe(
      map((response: any) => {
        console.warn(response);
        return response;
      })
    );
  }

  newSolutionEventValue(
    serverDeviceSolutionGuidId: string,
    solutionEventGuidId: string,
    serverPortGuidId: string,
    eventValue: string,
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'event/solutionevent/newvalue'),
      {
        serverDeviceSolutionGuidId: serverDeviceSolutionGuidId,
        solutionEventGuidId: solutionEventGuidId,
        serverPortGuidId: serverPortGuidId,
        eventValue: eventValue,
      }
    )
    .pipe(
      map((far: any) => {
        return far ? new FlowAffectedResponse(far) : null;
      })
    );
  }

}
