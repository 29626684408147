import { WebObject } from './web-object.model';

export class WebDesignTreeCustomObject {

  customType: string;
  customObject: any;

  constructor(item?: Partial<WebDesignTreeCustomObject>) {
    Object.assign(this, item);
  }

}
export class WebDesignTreeSite {

  parentSiteGuidId: string;
  guidId: string;
  name: string;
  iconUri: string;
  sites: WebDesignTreeSite[];
  objects: WebObject[];
  customObjects: WebDesignTreeCustomObject[];

  constructor(item?: Partial<WebDesignTree>) {
    Object.assign(this, item);

    this.sites = (this.sites || []).map(x => new WebDesignTreeSite(x));
    this.objects = (this.objects || []).map(x => new WebObject(x));
    this.customObjects = (this.customObjects || []).map(x => new WebDesignTreeCustomObject(x));
  }

}

export class WebDesignTree {

  designTreeGuidId: string;
  sites: WebDesignTreeSite[];
  $webObjects: WebObject[];

  constructor(item?: Partial<WebDesignTree>) {
    Object.assign(this, item);

    this.sites = (this.sites || []).map((x: WebDesignTreeSite) => {
      return new WebDesignTreeSite(x);
    });
  }

}
