import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { FormFieldTimeSpanSecondsComponent } from './form-field-time-span-seconds.component';
import { InputTextModule } from 'primeng/inputtext';


@NgModule({
  declarations: [
    FormFieldTimeSpanSecondsComponent,
  ],
  exports: [
    FormFieldTimeSpanSecondsComponent,
  ],
  imports: [
    AppSharedV2Module,
    InputTextModule,
  ],
})
export class FormFieldTimeSpanSecondsModule { }
