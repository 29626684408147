<div *ngFor="let detailGrid of detailGrids || []"
  class="flex-col">
  <div *ngIf="detailGrid.label"
    class="label vertical"
    [style.height]="detailGrid.gridHeight">
    <label [innerHTML]="detailGrid.label">
    </label>

    <i *ngIf="detailGrid.iconRight"
      style="margin-top: 4px;"
      [class.link]="detailGrid.iconRightClick && !detailGrid.iconRightDisabled"
      (click)="detailGrid.iconRightClick(params)"
      [ngClass]="detailGrid.iconRight">
    </i>
  </div>

  <lc-grid
    [style.height]="detailGrid.gridHeight || '100%'"
    [columns]="detailGrid.gridCols"
    [detailCellRenderer]="detailGrid.detailCellRenderer"
    [detailCellRendererParams]="detailGrid.detailCellRendererParams"
    [detailRowHeight]="detailGrid.detailRowHeight"
    [getRowClass]="detailGrid.getRowClass"
    [getRowStyle]="detailGrid.getRowStyle"
    [lastUpdateDate]="!detailGrid.hideLastUpdateDate ? detailGrid.lastUpdateDate : null"
    [lastUpdateFailed]="detailGrid.lastUpdateFailed"
    [masterDetail]="!!detailGrid.detailCellRenderer"
    [getGridReady]="gridReady"
    (refreshClick)="refreshGrid(detailGrid)"
    [rows]="detailGrid.gridRows"
    [rowSelection]="detailGrid.rowSelection"
    [singleClickEdit]="true">
  </lc-grid>
</div>
