import { NgModule } from '@angular/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { EditWorkScheduleDayDialogModule } from './dialogs/edit-work-schedule-day/edit-work-schedule-day.module';
import { FormFieldWorkScheduleComponent } from './form-field-work-schedule.component';


@NgModule({
  declarations: [
    FormFieldWorkScheduleComponent,
  ],
  exports: [
    FormFieldWorkScheduleComponent,
  ],
  imports: [
    AppSharedV2Module,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    EditWorkScheduleDayDialogModule,
    MultiSelectModule,
  ],
})
export class FormFieldWorkScheduleModule { }
