import { AgRendererComponent } from 'ag-grid-angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GridUtils } from 'app/shared/utils';
import { BaseGridCellRenderer } from '../base/base-grid-cellrenderer.component';


@Component({
  selector: 'lc-grid-cellrenderer-icon',
  templateUrl: 'grid-cellrenderer-icon.component.html',
  styleUrls: ['grid-cellrenderer-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererIcon extends BaseGridCellRenderer implements AgRendererComponent {

  constructor(
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  agInit(params): void {
    this.params = params;

    if (this.params?.colDef?.cellRendererParams) {
      this.params.colDef.cellRendererParams.isLink = !!this.params.colDef.cellRendererParams.click;
    }
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  onClick(ev: Event) {
    if (!this.params?.click) return;

    ev.stopPropagation();

    this.getParamValue(this.params.disabled) ? null : this.params.click(this.params, ev);
  }

}
