import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DateUtils, StorageUtils } from 'app/shared/utils';
import { AppService } from '../app/app.service';
const { version } = require('../../../../../package.json');


@Injectable({
  providedIn: 'root'
})
export class CheckVersionService {

  private readonly localCheckVersionCountKey = 'lc_check_version_count';

  constructor(
    private appService: AppService,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient,
  ) { }

  check(): void {
    if (
      this.document.location.href.indexOf('127.0.0.1') >= 0 ||
      this.document.location.href.indexOf('localhost') >= 0 ||
      this.document.location.href.indexOf('192.168.0') >= 0
    ) {
      console.debug('Running locally - not checking for version.');
      this.appService.setBuildDateTime('local build just now');
      return;
    }

    this.http.get(
      'assets/' + version.toString().replace(/\./g, '_') + '.version?r=' + Date.now(),
      {
        responseType: 'text'
      }
    )
    .subscribe((response: string) => {
      if ( // response should be a date in ISO format...and nothing else.
        (response || '').indexOf('T') === 10 &&
        (response || '').indexOf('Z') === response.length - 1
      ) {
        console.debug('Running on latest version: ' + version);
        StorageUtils.removeItem('local', this.localCheckVersionCountKey);
        this.appService.setBuildDateTime(DateUtils.getLocalISOString(response, false));
      } else { // if it isn't a datetime we probably got redirected...
        const joinWith = window.location.href.indexOf('?') >= 0 ? '&' : '?';
        window.location.href = window.location.href + joinWith + 'r=' + Date.now();
      }
    }, (error: any) => {
      const count = parseInt(StorageUtils.getItem('local', this.localCheckVersionCountKey) || '0');
      if (count > 2) {
        // StorageUtils.removeItem('local', this.localCheckVersionCountKey);
        this.appService.setBuildDateTime('not running on latest version');
        this.appService.showToast({
          severity: 'error',
          summary: 'Not running on latest version',
          detail: 'A browser cache issue has prevented the app from updating to the latest version. Try clearing the browser history and force refresh this page.',
        });
      } else {
        StorageUtils.setItem('local', this.localCheckVersionCountKey, count + 1);
        console.warn('Not latest version. Refreshing page...');
        const joinWith = window.location.href.indexOf('?') >= 0 ? '&' : '?';
        window.location.href = window.location.href + joinWith + 'r=' + Date.now();
      }
    });
  }

}
