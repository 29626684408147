import { FieldUtils } from '../utils/field.utils';
import { Field, SolutionField } from './field';
import { FlowBase } from './flow/flow-base.model';
import { FlowVariable } from './flow/flow-variable.model';
import { VariableToolbox } from './variable-toolbox/variable-toolbox.model';



export class SolutionNotify extends FlowBase {

  notifyGuidId: string;
  fields: Field[];

  $variableToolbox: VariableToolbox;

  getFieldValue(fieldName: string, variables?: FlowVariable[], texts?: Map<number, string>): string {
    let field = this.getField(fieldName);
    if (field) {
      return field.getValue(variables, texts);
    } else {
      return '';
    }
  }

  getFieldVariable(fieldName: string, variables: FlowVariable[]): FlowVariable {
    let field = this.getField(fieldName);
    if (field) {
      return (field as SolutionField).getVariable(variables);
    } else {
      return null;
    }
  }

  getField(fieldName: string): Field {
    let field = (this.fields || []).find((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    return FieldUtils.castField(field);
  }

}
