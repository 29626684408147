export enum VariableSetType {
  Set = 0,
  Add = 1,
  Remove = 2,

  ValueSet = 10,
  ValueAdd = 11,
  ValueRemove = 12,

  ObjectSet = 20,

  ListAdd = 30,
  ListRemove = 31,
  ListRemoveAll = 32,

}
