import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { WebCenterType, WebCenterTypeMember, WorkspaceSolutionType, WorkspaceSolutionTypeMember } from 'app/center-v2/shared/models';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { CenterType, SolutionType, SolutionTypeMember } from 'app/shared/models';
import { SessionService } from 'app/shared/services/app';



@Component({
  selector: 'lc-select-solution-type-member-dialog',
  templateUrl: './select-solution-type-member.dialog.html',
  styleUrls: ['./select-solution-type-member.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSolutionTypeMemberDialog extends BaseDialog {

  data: {
    solutionType: WorkspaceSolutionType | SolutionType;
    selectedMember: WorkspaceSolutionTypeMember | SolutionTypeMember;
    webCenterTypes: WebCenterType[];
  }

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {

  }

  confirm() {
    const webCenterType = (this.data?.webCenterTypes || []).find(x => x.typeGuidId === this.data.selectedMember.subMembers[this.data.selectedMember.subLevels].fromTypeGuidId);
    if (!webCenterType) { console.error('Couldn\'t find the corresponding webCenterType.'); return; }

    const webCenterTypeMember = (webCenterType?.members || []).find(x => x.guidId === this.data.selectedMember.centerTypeMemberGuidId);
    if (!webCenterTypeMember) { console.error('Couldn\'t find the corresponding webCenterTypeMember.'); return; }

    this.data.selectedMember.fieldType = webCenterTypeMember.$fieldType;
    this.data.selectedMember.fieldOptions = webCenterTypeMember.$fieldOptions;

    this.close({
      memberTypeGuidId: this.data.selectedMember.subMembers[this.data.selectedMember.subLevels].fromTypeGuidId,
      selectedMember: this.data.selectedMember,
    });
  }

}
