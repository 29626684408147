import { FieldUtils } from '../../utils/field.utils';
import { Field } from '../field/field.model';




export class FlowBase {

  guidId: string;
  name: string;
  objectType: string;
  flowCoreGuidId: string;
  tick: number;
  webDateTime: string;
  webDateTimeTick: number;
  multiFlowCore: boolean;
  typeGuidId: string;
  isNew: boolean;
  isDeleted: boolean;

  fields: Field[];

  constructor(item?: any) {
    Object.assign(this, item);

    this.fields = FieldUtils.castFields(this.fields || []);
  }

  getField(fieldName: string): Field {
    const field = (this.fields || []).find((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    return FieldUtils.castField(field);
  }

  getFieldGuidId(fieldName: string) {
    const field = (this.getField(fieldName) || {}) as Field;
    return field.fieldGuidId;
  }

  getFieldValue(fieldName: string, variables?: any[], texts?: Map<number, string>): any {
    let field = this.getField(fieldName);
    if (field) {
      return field.getValue(variables, texts);
    } else {
      return '';
    }
  }

}
