import { DesignStyle } from './design-style.model';
import { Design } from './design.model';

export class DesignGetResponse {
  design: Design;
  designStyles: DesignStyle[];

  constructor(item?: any) {
    if (item) {
      this.design = new Design(item.design);
      this.designStyles = (item.designStyles || []).map((ds: DesignStyle) => {
        return new DesignStyle(ds);
      });
    }
  }
}
