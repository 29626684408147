import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private urlSuffixPlaceholder = 'api/support';

  constructor(
    private apiService: ApiStudioService,
  ) {

  }

  sendMessage(message: string): Observable<boolean> {
    return this.apiService.post(
      this.urlSuffixPlaceholder,
      { message: message }
    );
  }

}
