import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class TranslateHttpLoader implements TranslateLoader {

  constructor(private http: HttpClient, public prefix: string = '/assets/l10n/', public suffix: string = '.json') {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
    .pipe(
      catchError((error: any) => {
        return of({})
      })
    );
  }

}