import { RunEngine } from '../device';
import { Tag } from '../tag.model';
import { SolutionEnvironment } from './solution-environment.model';
import { Solution } from './solution.model';

export class DeviceSolutionSet {

  guidId: string;
  name: string;
  standard: boolean;
  status: number;
  comments: string;
  compiled: boolean; // old
  runCompiled: boolean;
  solutionEnvironmentGuidId: string; // old
  solutionEnvironment: SolutionEnvironment;
  version: string;
  versionDateTime: string;
  testDateTime: string;
  pilotDateTime: string;
  productionDateTime: string;
  signedProduction: any;

  deviceSolution: Solution;
  deviceRunEngine: RunEngine;
  primaryEnrollmentProfile: any;
  cloudDeviceSolutionSet: any;
  integrationSets: any[]; // old
  tags: Tag[];

  constructor(item?: Partial<DeviceSolutionSet>) {
    Object.assign(this, item);
  }

}
