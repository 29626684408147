import { FlowBase } from './flow-base.model';


export enum FlowPointType {
  In = 0,
  Out = 1,
  Exit = 2,
}

export class FlowPoint extends FlowBase {

  pointType: FlowPointType;
  portGuidId: string;

}
