<div class="form-field"
  (click)="onTouched()">
  <label *ngIf="label"
    class="label"
    [innerHTML]="label">
  </label>

  <div *ngIf="!editMode"
    class="value">
    <div [innerHTML]="value != null ? getValueLabel() : noValueHtml">
    </div>
  </div>

  <div *ngIf="editMode">
    <div class="flex-row">
      <div class="flex-1">
        <div class="label">{{ 'Hours' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="hours"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>

      <div class="flex-1">
        <div class="label">{{ 'Minutes' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="minutes"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>

      <div class="flex-1">
        <div class="label">{{ 'Seconds' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="seconds"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>
    </div>
  </div>
</div>
