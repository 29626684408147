import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Text } from '../../models/text.model';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class TextService {

  private urlSuffixPlaceholder = 'api/text/{what}';

  textByTextId: Map<number, string>;
  textIdByText: Map<string, number>;

  constructor(private apiService: ApiStudioService) {
    this.textByTextId = new Map();
    this.textIdByText = new Map();
  }

  list(): Observable<Text[]> {
    return this.apiService.post<Text[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      { }
    )
    .pipe(
      map((response: any) => {
        if (response) {
          this.storeTextsInDicts(response.texts);
          return response.texts || [];
        } else {
          return null;
        }
      })
    );
  }

  storeTextsInDicts(texts: Text[]): void {
    this.textByTextId = new Map();
    this.textIdByText = new Map();
    for (const text of texts || []) {
      this.textByTextId.set(text.id, text.text);
      this.textIdByText.set(text.text, text.id);
    }
  }

  add(text: string): Observable<Text> {
    return this.apiService.post<Text>(
      this.urlSuffixPlaceholder.replace('{what}', 'add'),
    {
      text: text
    })
    .pipe(
      map((response: any) => {
        if (response) {
          this.textByTextId.set(response.text.id, response.text.text);
          this.textIdByText.set(response.text.text, response.text.id);
          return response.text;
        } else {
          return null;
        }
      })
    );
  }

  listLanguages(enrollmentGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'language/list'),
      {
        enrollmentGuidId: enrollmentGuidId,
      }
    );
  }

}
