

export enum SolutionTypeEnum {

  Bool = 'fd367eb5-2ae9-4f9a-b1d1-6ba419508cdd',
  DateTime = 'ffee17e5-6604-48ab-825d-a718d6accfe6',
  Decimal = 'f76e703f-a84f-4d79-8da9-808dd9c94f56',
  Double = 'df2c3f14-ecb2-4b87-adcb-4bad61a9e88a',
  Float = 'bfa87271-3534-47e5-82e2-a3fcb657c76c',
  Gps = 'aba43877-3851-48c0-8d6e-e48b5549d5ea',
  Guid = 'b06de26e-bd62-48a6-a8c5-c2ac270f3715',
  Image = '689e597b-72d5-4cef-89f8-8f815283372f',
  Int = 'e05912ea-d3ca-4475-b4ce-b4b775c33100',
  Json = '7aa2bedd-0061-4d21-9ce9-059712ceee18',
  LogicObject = '023a0c16-c1d1-48af-9658-26db50a49b5b',
  Long = '4b1179b5-f942-4ca4-be2f-af85866278b8',
  ManagedSet = '77ea39d9-d6da-49ca-9488-3f4016917d4c',
  Number = '61822203-b143-4fd5-b445-1f7d8869b5e3',
  String = 'f7266270-033b-4424-97cd-6502ef8b7f70',

  FeatureString = '04cc6121-a6b4-4d11-8a2f-3c83f33bfc1b', // special one

  Value = '0d6e6f29-c3a3-4926-aecc-f7a1932ca5e1', // "decoded" at runtime to string / int / float depending on the actual value that it holds...

}

export const SystemSolutionTypes = [
  SolutionTypeEnum.Bool.toString(),
  SolutionTypeEnum.DateTime.toString(),
  SolutionTypeEnum.Decimal.toString(),
  SolutionTypeEnum.Double.toString(),
  SolutionTypeEnum.Float.toString(),
  SolutionTypeEnum.Gps.toString(),
  SolutionTypeEnum.Guid.toString(),
  SolutionTypeEnum.Image.toString(),
  SolutionTypeEnum.Int.toString(),
  SolutionTypeEnum.Json.toString(),
  SolutionTypeEnum.LogicObject.toString(),
  SolutionTypeEnum.Long.toString(),
  SolutionTypeEnum.ManagedSet.toString(),
  SolutionTypeEnum.Number.toString(),
  SolutionTypeEnum.String.toString(),
  SolutionTypeEnum.Value.toString(),
];

export const SystemComplexSolutionTypes = [
  SolutionTypeEnum.Image.toString(),
  SolutionTypeEnum.LogicObject.toString(),
  SolutionTypeEnum.ManagedSet.toString(),
];
