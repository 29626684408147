import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AppService } from './shared/services';



@Injectable()
export class AppErrorHandler implements ErrorHandler {

  private appService: AppService;
  private isShowingError: boolean;

  constructor(
    private injector: Injector,
  ) {
    this.appService = this.injector.get(AppService);
  }

  handleError(error) {
    if (
      !this.isShowingError &&
      error &&
      error.status !== 401 && // Unauthorized
      error.status !== 403 && // Forbidden
      error.status !== 404 && // Not Found (can easily happen when looking for a unexisting .po file)
      (error.message || '').indexOf('ViewDestroyedError: Attempt to use a destroyed view: detectChanges') < 0 && // issue in the ngx-loading package...waiting for it to be updated.
      (error.message || '').indexOf('ExpressionChangedAfterItHasBeenCheckedError') < 0 && // this only happens in dev angular build - I'm sure
      (error.message || '').indexOf('Loading chunk') < 0 // also ignore loading chunk errors as they're handled in app.component NavigationError event
    ) {
      this.isShowingError = true;

      // this.appService.showDefaultErrorToast(error);
      console.error(error);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackException({
          exception: error.originalError || error
        });
      }

      const eventId = Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({ eventId });

      setTimeout(() => {
        this.isShowingError = false;
      }, 30 * 1000);
    } else if (error) {
      console.warn(error);
    }
  }
}
