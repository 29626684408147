import { PlatformResource } from './platform-resource.model';

export class PlatformFolder {

  description: string;
  platformResources: PlatformResource[];

  constructor(item?: Partial<PlatformFolder>) {
    Object.assign(this, item);

    this.platformResources = (this.platformResources || []).map(x => new PlatformResource(x));
  }
}
