import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {

  }

  /*apiError(error: any): void {
    if (error && (error.errorCode || error.detail)) {
      const errorSummary = error.status === 400 ?
        this.translateService.instant('API.BAD_REQUEST') :
        error.title;
      const errorDetail = error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode ?
        this.translateService.instant(error.errorCode) :
        error.detail;

      this.messageService.clear();
      this.messageService.add({
        detail: errorDetail,
        key: 'global',
        severity: 'error',
        summary: errorSummary,
        life: this.getDefaultDuration(errorDetail),
      });
    }
  }*/

  error(summary: string, detail: string, life?: number): void {
    this.messageService.clear();

    this.messageService.add({
      detail: detail,
      // key: 'global',
      severity: 'error',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      closable: life === 0,
      sticky: life === 0,
    });
  }

  warn(summary: string, detail: string, life?: number): void {
    this.messageService.add({
      detail: detail,
      // key: 'global',
      severity: 'warn',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      closable: life === 0,
      sticky: life === 0,
    });
  }

  info(summary: string, detail: string, life?: number): void {
    this.messageService.add({
      detail: detail,
      // key: 'global',
      severity: 'info',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      closable: life === 0,
      sticky: life === 0,
    });
  }

  success(summary: string, detail: string, life?: number): void {
    this.messageService.add({
      detail: detail,
      // key: 'global',
      severity: 'success',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      closable: life === 0,
      sticky: life === 0,
    });
  }

  private getDefaultDuration(text: string): number {
    // Calculate the amount of time needed to read the notification
    const wpm = 180; // readable words per minute
    const wordLength = 5;  // standardized number of chars in calculable word
    const words = (text || '').length / wordLength;
    const wordsTime = ((words / wpm) * 60) * 1000;

    const delay = 1000; // milliseconds before user starts reading the notification
    const bonus = 1000; // extra time

    return delay + wordsTime + bonus;
  }
}
