import { Injectable } from '@angular/core';
import { SelectItemGroup } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Flow, FlowAffectedResponse, FlowModule, FlowStudioMode, FlowVariableRequest, NewModuleRequest } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class FlowService {

  private urlSuffixPlaceholder = 'api/flow/{what}';

  constructor(
    private apiService: ApiStudioService,
  ) {

  }

  getCompiled(flowCoreGuidId: string): Observable<Flow> {
    return this.apiService.post<Flow>(
      this.urlSuffixPlaceholder.replace('{what}', 'compiled'),
    {
      flowCoreGuidId: flowCoreGuidId
    })
    .pipe(
      map((flow: Flow) => {
        return new Flow(flow);
      })
    );
  }

  listAsSIG(): Observable<SelectItemGroup[]> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
    { })
    .pipe(
      map((response: any) => {
        return [{
          label: 'Flow',
          items: response.flows.map((flow: Flow) => {
            return { label: flow.name, value: flow.guidId };
          })
        }];
      })
    );
  }

  new(name: string, flowStudioMode: FlowStudioMode): Observable<Flow> {
    return this.apiService.post<Flow>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      {
        name: name,
        flowStudioMode: flowStudioMode
      }
    )
    .pipe(
      map((response: any) => {
        if (response && response.flow) {
          return new Flow(response.flow);
        }
        return null;
      })
    );
  }

  newModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'newmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

  flowVariable(flowVariableRequest: FlowVariableRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'flowvariable'),
      flowVariableRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

}
