import { FlowVariable } from '../flow/flow-variable.model';
import { VariableToolboxGroupItem } from './variable-toolbox-group-item.model';
import { VariableToolboxGroup } from './variable-toolbox-group.model';
import { DictString } from 'app/shared/models';
import { VariableToolboxGroupItemType } from './variable-toolbox-group-item-type.enum';


export class VariableToolbox {

  guidId: string;
  toolboxName: string;
  groups: VariableToolboxGroup[];
  stateVariables: FlowVariable[];

  $skipEditorRefresh: boolean;

  private stateVariablesMap: DictString<FlowVariable>;
  private toolboxGroupItemsMap: DictString<VariableToolboxGroupItem>;

  constructor(item: any) {
    Object.assign(this, item);

    this.stateVariablesMap = {};
    this.toolboxGroupItemsMap = {};

    this.stateVariables = (this.stateVariables || []).map((v: FlowVariable) => {
      const fv = new FlowVariable(v);
      this.stateVariablesMap[v.guidId] = fv;
      if (v.originalStateVariableGuidId) {
        this.stateVariablesMap[v.originalStateVariableGuidId] = fv;
      }
      return fv;
    });

    this.groups = this.groups || [];
    for (const group of this.groups) {
      for (const groupItem of group.groupItems || []) {
        this.toolboxGroupItemsMap[groupItem.guidId] = groupItem;

        if (groupItem.originalStateVariableGuidId) {
          this.toolboxGroupItemsMap[groupItem.originalStateVariableGuidId] = groupItem;
        }
      }
    }
  }

  getStateVariable(guidId: string): FlowVariable {
    return this.stateVariablesMap[guidId];
  }

  getToolboxGroupItem(guidId: string): VariableToolboxGroupItem {
    return this.toolboxGroupItemsMap[guidId];
  }

  removeVariable(guidId: string) {
    for (const group of this.groups) {
      const index = (group.groupItems || [])
      .findIndex((i: VariableToolboxGroupItem) => {
        return i.guidId === guidId;
      });
      if (index >= 0) {
        group.groupItems.splice(index, 1);
      }
    }
  }

}
