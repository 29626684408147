import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api';


export enum WarehouseLocationArticleType {
  None     = 0x0,
  Set      = 0x1,
  Pick     = 0x10,
  Buffer   = 0x100,
  Blocked  = 0x1000,
  Transfer = 0x10000,
}

@Component({
  selector: 'lc-form-field-warehouse-location-article-type',
  templateUrl: 'form-field-warehouse-location-article-type.component.html',
  styleUrls: ['./form-field-warehouse-location-article-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldWarehouseLocationArticleTypeComponent),
    multi: true
  }],
})
export class FormFieldWarehouseLocationArticleTypeComponent implements ControlValueAccessor, OnInit {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;


  onChange: (x: any) => {};
  onTouched: () => {};

  multiSelectValue: number[];
  options: SelectItem[];
  optionLabel: string;
  readOnlyValue: string;
  value: number;


  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.options = EnumUtils.toSelectItemArray(WarehouseLocationArticleType);
    this.options.splice(0, 1);
  }


  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;
      this.readOnlyValue = this.getReadOnlyValue(this.value);
      this.multiSelectValue = this.getMultiSelectValue(this.value);

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {

  }

  private getReadOnlyValue(value: number): string {
    const multiSelectValue = [];
    if (value) {
      for (const item of EnumUtils.toStringArray(WarehouseLocationArticleType)) {
        if (item as any === WarehouseLocationArticleType.None) continue;

        var enumValue = WarehouseLocationArticleType[WarehouseLocationArticleType[item]] as any;
        if ((value & enumValue) === enumValue) {
          multiSelectValue.push(WarehouseLocationArticleType[item]);
        }
      }
      return multiSelectValue.length ? multiSelectValue.join(', ') : value.toString();
    } else {
      return WarehouseLocationArticleType[WarehouseLocationArticleType.None];
    }
  }

  private getMultiSelectValue(value: number): number[] {
    const multiSelectValue = [];
    if (value) {
      for (const item of EnumUtils.toStringArray(WarehouseLocationArticleType)) {
        var enumValue = WarehouseLocationArticleType[WarehouseLocationArticleType[item]] as any;
        if ((value & enumValue) === enumValue) {
          multiSelectValue.push(enumValue);
        }
      }
      return multiSelectValue;
    } else {
      return undefined;
    }
  }

  setValueFromMultiSelectFlagsValue(selectedValues: number[]) {
    this.value = undefined;
    for (const selectedValue of selectedValues || []) {
      this.value |= selectedValue;
    }

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

}
