import { FieldUtils } from 'app/studio/utils';
import { Field } from '../field/field.model';


export class Design {
  guidId: string;
  name: string;
  tick: number;
  isNew: boolean;
  isDirty: boolean;
  newFlowCoreGuidId: string;
  fields: Field[];
  originalGuidId: string;

  constructor(item: any) {
    Object.assign(this, item);

    this.fields = FieldUtils.castFields(this.fields);
  }

  getField(fieldName: string): Field {
    let field = (this.fields || []).find((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    return FieldUtils.castField(field);
  }

  getFieldValue(fieldName: string): Field {
    let field = this.getField(fieldName);
    return field ? field.getValue() : undefined;
  }
}
