import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebObject, WebResponse } from 'app/center-v2/shared/models';
import { GenericService } from 'app/center-v2/shared/services';
import { ProductionPlannerPlanDayArticle } from 'app/center-v2/template-solutions/happy-tokyo/models';
import { ProductionPlannerService } from 'app/center-v2/template-solutions/happy-tokyo/services';
import { TypeEnum } from 'app/shared/models';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { GridUtils, JsonUtils } from 'app/shared/utils';
import { DateTimeIndexUtils } from 'app/shared/utils/date-time-index.utils';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormFieldComponent } from '../../form-field/form-field.component';


@Component({
  selector: 'lc-grid-celleditor-planner-day-article',
  templateUrl: 'grid-celleditor-planner-day-article.component.html',
  styleUrls: ['grid-celleditor-planner-day-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorPlannerDayArticle implements ICellEditorAngularComp, AfterViewInit {

  @ViewChildren(FormFieldComponent) formFieldComponents: QueryList<FormFieldComponent>;
  @ViewChild(OverlayPanel) overlayPanel: OverlayPanel;

  params: any;

  quantityValueChanged: Subject<void>;
  daySegmentValueChanged: Subject<any>;

  colDate: string;
  originalComment: string;
  originalMinPlannedQuantity: number;
  originalQuantity: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private notificationService: NotificationService,
    private productionPlannerService: ProductionPlannerService,
    private translateService: TranslateService,
  ) {
    this.quantityValueChanged = new Subject<any>();
    this.quantityValueChanged
    .pipe(
      debounceTime(500)
    )
    .subscribe(() => { this.onQuantityValueChanged(); });

    this.daySegmentValueChanged = new Subject<any>();
    this.daySegmentValueChanged
    .pipe(
      debounceTime(500)
    )
    .subscribe((daySegmentValue: any) => { this.onDaySegmentValueChanged(daySegmentValue); });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formFieldComponents?.forEach((formFieldComponent: FormFieldComponent) => {
        if (formFieldComponent) {
          formFieldComponent.disableKeysPropagation();

          if (this.params.colDef.cellEditorFramework === GridCellEditorPlannerDayArticle) {
            formFieldComponent.tryToFocusInputElement();
          }

          if (this.params.type === 'dropdown') {
            formFieldComponent.showPicker();
            this.cdr.markForCheck();
          } else if (this.params.type === 'date') {
            formFieldComponent.showPicker();
            this.cdr.markForCheck();
          }
        }
      });
    }, 100);
  }

  agInit(params): void {
    this.params = params;

    this.colDate = this.params.colDef.field.split('.')[0];

    this.params.daySegments = GridUtils.getParamValue(this.params, this.params.daySegments);
    this.params.disabled = GridUtils.getParamValue(this.params, this.params.disabled);
    this.params.options = GridUtils.getParamValue(this.params, this.params.options);
    this.params.type = GridUtils.getParamValue(this.params, this.params.type);

    this.originalQuantity = this.params.value?.quantity;

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams?.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  getPopupPosition(): 'over' | 'under' | undefined {
    return 'over';
  }

  onQuantityValueChanged() {
    if (this.params.value?.quantity !== this.originalQuantity) {
      this.params.newValue = this.params.value;
      this.params.oldValue = this.originalQuantity;

      this.params.setValue(this.params.newValue);

      this.params.value.$isDirty = true;
      this.params.value.changed = true;
      console.log(this.params.value);

      if (this.params?.quantityValueChanged) {
        this.params.quantityValueChanged(this.params);
      }
    }
    this.originalQuantity = this.params.value?.quantity;
  }

  onDaySegmentValueChanged(daySegmentValue: any) {
    if (daySegmentValue.oldValue !== daySegmentValue.newValue) {
      this.params.oldValue = JSON.parse(JSON.stringify(this.params.value));
      if (this.params.isPercentage) {
        this.params.newValue = this.params.value.daySegments[daySegmentValue.guidId].members.templateSegmentPercentage = daySegmentValue.newValue;
      } else {
        this.params.newValue = this.params.value.daySegments[daySegmentValue.guidId].members.segmentQuantity = daySegmentValue.newValue;
      }

      let fieldSplit = this.params.colDef.field.split('.');
      const fieldKey = fieldSplit[fieldSplit.length - 1];
      const fieldPath = fieldSplit.slice(0, fieldSplit.length - 1).join('.');
      const fieldParent = fieldPath ? JsonUtils.deepFind(this.params.data, fieldPath) : this.params.data;
      fieldParent[fieldKey] = this.params.value;

      this.params.value.$isDirty = true;
      this.params.value.daySegments[daySegmentValue.guidId].members.$isDirty = true;
      this.params.value.daySegments[daySegmentValue.guidId].members.changed = true;

      if (this.params?.daySegmentValueChanged) {
        this.params.daySegmentValueChanged(this.params, daySegmentValue);
      }
    }

    if (this.params.stopEditing) {
      this.params.stopEditing();
    }

    this.cdr.markForCheck();
  }

  syncTemplatePlanDayArticleQuantity() {
    this.params.data[this.colDate].templatePlanDayArticle.members.quantity = this.params.data[this.colDate].quantity;

    this.productionPlannerService.updatePlanDayArticles([{
      planDayArticleGuidId: this.params.data[this.colDate].planDayArticleGuidId,
      setQuantity: false,
      setComment: false,
      setDaySegments: false,
      syncTemplatePlanDayArticle: true,
    }])
    .subscribe((response: WebResponse) => {
      this.notificationService.success(
        this.translateService.instant('Success'),
        this.translateService.instant('Changes saved successfully.'),
      );

      this.cdr.markForCheck();
    });
  }

  setPlanDayArticleToTemplate() {
    this.productionPlannerService.setToTemplate(this.params.data[this.colDate].planDayArticleGuidId)
    .subscribe((response: WebResponse) => {
      this.notificationService.success(
        this.translateService.instant('Success'),
        this.translateService.instant('Changes saved successfully.'),
      );

      this.cdr.markForCheck();
    });
  }

  overlayPanelShow() {
    this.originalMinPlannedQuantity = this.params.data[this.colDate].minPlannedQuantity;
    this.originalComment = this.params.data[this.colDate].comment;
  }

  overlayPanelHide() {
    if (
      this.originalMinPlannedQuantity !== this.params.data[this.colDate].minPlannedQuantity ||
      this.originalComment !== this.params.data[this.colDate].comment
    ) {
      this.productionPlannerService.updatePlanDayArticle(
        this.params.data[this.colDate].planDayArticleGuidId,
        false,
        undefined,
        this.originalMinPlannedQuantity !== this.params.data[this.colDate].minPlannedQuantity,
        this.params.data[this.colDate].minPlannedQuantity,
        this.originalComment !== this.params.data[this.colDate].comment,
        this.params.data[this.colDate].comment,
        false,
      )
      .subscribe((response: WebResponse) => {
        this.notificationService.success(
          this.translateService.instant('Success'),
          this.translateService.instant('Changes saved successfully.'),
        );

        this.cdr.markForCheck();
      });
    }
  }

  showPlannedSourceDayArticlesInfo(ev: Event) {
    const openPanels = document.querySelectorAll('.p-overlaypanel');
    openPanels.forEach((el: Element) => el.remove());
    this.cdr.markForCheck();

    setTimeout(() => {
      this.overlayPanel.show(ev);
      this.cdr.markForCheck();
    }, 10);

    if (!this.params.data[this.colDate].plannedSourceDayArticles) {
      this.genericService.relationV2(new WebObject({ guidId: this.params.data[this.colDate].planDayArticleGuidId, typeGuidId: TypeEnum.ProductionPlannerPlanDayArticle }), 'plannedSourceDayArticles')
      .subscribe((response: WebResponse) => {
        this.params.data[this.colDate].plannedSourceDayArticles = (response.getRelation('plannedSourceDayArticles') as ProductionPlannerPlanDayArticle[] || [])
          .map((psda: ProductionPlannerPlanDayArticle) => {
            psda.members.$dayDate = DateTimeIndexUtils.decodeDayIndexToDate(psda.members.dayIndex);
            return psda.members;
          })
          .sort((a, b) => {
            return a.dayIndex - b.dayIndex;
          });

        this.cdr.markForCheck();
      });
    }
  }

}
