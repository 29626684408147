import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { DateTimeIndexUtils } from '../utils/date-time-index.utils';


@Pipe({
  name: 'lcTime',
  standalone: true,
})
export class TimePipe implements PipeTransform {

  transform(dt: Date | string | number, showSeconds?: boolean): string {
    if (!dt) return '';
    if (dt instanceof Date && isNaN(dt.getTime())) return '';

    if (dt instanceof Date) {
      return format(dt, 'HH:mm' + (showSeconds ? ':ss' : ''));
    } else if (typeof dt === 'string') {
      return format(new Date(dt), 'HH:mm' + (showSeconds ? ':ss' : ''));
    } else {
      let timeString = DateTimeIndexUtils.decodeSecondsIndexToTimeString(dt);
      if (!showSeconds) timeString = timeString.slice(0, 5);
      return timeString;
    }
  }

}
