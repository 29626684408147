<div class="flex-row cell-wrapper"
  [class.error]="!doesWeekDayDaySegmentsTotal100()">
  <lc-form-field *ngFor="let ds of params?.daySegments || []; let i = index"
    class="cell-field flex-1"
    [disabled]="params.disabled"
    [editMode]="true"
    (keydown.tab)="$event.stopPropagation()"
    [maxNumber]="100"
    [minNumber]="0"
    [ngModel]="params?.isPercentage ? params.value[ds.guidId] : params.value[ds.guidId].members?.segmentQuantity"
    (ngModelChange)="daySegmentPercentageChanged.next({ guidId: ds.guidId, oldValue: params.value[ds.guidId], newValue: $event, index: i })"
    [placeholder]="params?.isPercentage ? 100 / params.daySegments.length : ''"
    [pTooltip]="ds.members?.name"
    [type]="'number'">
  </lc-form-field>
</div>
