import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';
import { CenterTypeTreeModule } from 'app/shared/components/center-type-tree/center-type-tree.module';
import { FormFieldModule } from 'app/shared/components/form-field/form-field.module';
import { DialogModule } from 'primeng/dialog';
import { SelectCenterTypeMemberDialog } from './select-center-type-member.dialog';



@NgModule({
  declarations: [
    SelectCenterTypeMemberDialog,
  ],
  exports: [
    SelectCenterTypeMemberDialog,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    CenterTypeTreeModule,
    DialogModule,
    FormFieldModule,
  ],
  providers: [],
})
export class SelectCenterTypeMemberDialogModule { }
