import { ServerPort2 } from '../server-port2.model';
import { VariableToolbox } from '../variable-toolbox';
import { FlowBase } from './flow-base.model';
import { FlowConnection } from './flow-connection.model';
import { FlowFieldsObject } from './flow-fields-object.model';
import { FlowModule } from './flow-module.model';
import { FlowPoint } from './flow-point.model';

export class FlowAffectedResponse {

  affectedStudioObjects: FlowBase[];
  affectedToolboxes: VariableToolbox[];
  connection: FlowConnection;
  sourceObject: FlowModule | FlowPoint | FlowFieldsObject | ServerPort2;
  updated: boolean;
  updatedReasonCode: number;
  updatedReason: string;

  constructor(item?: any) {
    Object.assign(this, item);

    this.affectedStudioObjects = (this.affectedStudioObjects || []).map((x: FlowBase) => {
      return new FlowBase(x);
    });

    this.affectedToolboxes = (this.affectedToolboxes || []).map((x: VariableToolbox) => {
      return new VariableToolbox(x);
    });

    this.connection = this.connection ?
      new FlowConnection(this.connection, this.affectedStudioObjects as any) :
      undefined;
  }

}
