import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { RefreshMessage } from 'app/shared/models/refresh-message.model';
import { SessionService } from 'app/shared/services/app';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Permission, UserSession } from '../../models';
import { PermissionUtils } from '../../utils';


@Component({
  template: '<div></div>'
})
export abstract class BaseComponentV2 implements OnDestroy {

  private refreshSubject = new Subject<RefreshMessage>();

  baseSubscriptions: Subscription[];
  isDestroyed: boolean;
  permissions: Permission[];
  session: UserSession;
  subscriptions: Subscription[];

  constructor(
    protected cdr: ChangeDetectorRef,
    protected sessionService?: SessionService,
  ) {
    this.baseSubscriptions = [];
    this.subscriptions = [];

    this.baseSubscriptions.push(
      this.refreshSubject.pipe(
        debounceTime(100)
      ).subscribe(() => {
        this.refresh();
      })
    );

    this.updateThisSession(false);
  }

  ngOnDestroy() {
    this.unsubscribeAll(true);
    this.isDestroyed = true;
  }

  updateThisSession(andRefresh?: boolean) {
    this.sessionService.get()
    .subscribe((session: UserSession) => {
      this.session = session;

      if (this.session && this.session.permissionProfile) {
        this.permissions = this.session.permissionProfile.permissions;
      } else {
        this.permissions = [];
      }

      if (andRefresh) {
        this.refreshSubject.next(null);
      }

      this.cdr.markForCheck();
    });
  }

  addBaseSubscriptions() {

  }

  protected refresh(options?: any) {
    // override in child classes
  }

  unsubscribeAll(clearBaseSubscriptions = false) {
    if (clearBaseSubscriptions && this.baseSubscriptions) {
      for (const sub of this.baseSubscriptions) {
        if (sub) sub.unsubscribe();
      };
      this.baseSubscriptions.length = 0;
    }

    if (this.subscriptions) {
      for (const sub of this.subscriptions) {
        if (sub) sub.unsubscribe();
      };
      this.subscriptions.length = 0;
    }
  }

  hasReadAccess(path: string): boolean {
    return PermissionUtils.hasReadAccess(this.permissions, path) ||
           PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  hasWriteAccess(path: string): boolean {
    return PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  getPermission(path: string): Permission {
    return PermissionUtils.getPermission(this.permissions, path);
  }

  getPermissionEnvironmentGuidId(path: string): string {
    const permission = PermissionUtils.getPermission(this.permissions, path);
    return permission ? permission.environmentGuidId : null;
  }

}

