import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowModule, NewModuleRequest } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';



@Injectable({
  providedIn: 'root'
})
export class SolutionRunLogicService {

  private urlSuffixPlaceholder = 'api/runlogic/{what}';

  constructor(private apiService: ApiStudioService) { }

  newStartModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'loop/newstartmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

  newNextModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'loop/newnextmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

  newBreakModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'loop/newbreakmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

}
