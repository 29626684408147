import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowVariable } from '../../models';
import { ApiStudioService } from '../api/api-studio.service';



@Injectable({
  providedIn: 'root'
})
export class VariableService {

  private urlSuffixPlaceholder = 'api/variable/{what}';

  constructor(private apiService: ApiStudioService) { }

  new(
    name: string, parentFlowCoreGuidId: string, activeObjectGuidId?: string, fieldGuidId?: string
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'new'),
      {
        name: name,
        parentFlowCoreGuidId: parentFlowCoreGuidId,
        activeObjectGuidId: activeObjectGuidId,
        fieldGuidId: fieldGuidId,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        if (far && far.sourceObject) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowVariable(far.sourceObject);
        }
        return far;
      })
    );
  }

  rename(
    stateVariableGuidId: string,
    name: string,
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'rename'),
      {
        name: name,
        stateVariableGuidId: stateVariableGuidId,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        if (far && far.sourceObject) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowVariable(far.sourceObject);
        }
        return far;
      })
    );
  }

  changeType(
    stateVariableGuidId: string,
    solutionTypeGuidId: string,
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'changetype'),
      {
        solutionTypeGuidId: solutionTypeGuidId,
        stateVariableGuidId: stateVariableGuidId,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        if (far && far.sourceObject) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowVariable(far.sourceObject);
        }
        return far;
      })
    );
  }

  delete(
    stateVariableGuidId: string
  ): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'delete'),
      {
        stateVariableGuidId: stateVariableGuidId,
      }
    ).pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
        }
        return far;
      })
    );
  }

}
