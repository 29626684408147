import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';



@Component({
  selector: 'lc-form-field-time-span-hours',
  templateUrl: 'form-field-time-span-hours.component.html',
  styleUrls: ['./form-field-time-span-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldTimeSpanHoursComponent),
    multi: true
  }],
})
export class FormFieldTimeSpanHoursComponent implements ControlValueAccessor, OnInit {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;


  onChange: (x: any) => {};
  onTouched: () => {};

  value: number;
  years: number;
  months: number;
  days: number;
  hours: number;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }


  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.breakdownValues();

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {

  }

  breakdownValues() {
    let remain: number;

    remain = this.value || 0;
    this.years = ~~(remain / (365 * 24));

    remain = remain - (this.years * 365 * 24);
    this.months = ~~(remain / (30 * 24));

    remain = remain - (this.months * (30 * 24));
    this.days = ~~(remain / (1 * 24));

    remain = remain - (this.days * (1 * 24));
    this.hours = ~~(remain);

    this.cdr.markForCheck();
  }

  setValueFromInputs() {
    this.years = this.years >= 0 ? this.years : 0;
    this.months = this.months >= 0 ? this.months : 0;
    this.days = this.days >= 0 ? this.days : 0;
    this.hours = this.hours >= 0 ? this.hours : 0;

    this.value = (this.years * 365 * 24) + (this.months * 30 * 24) + (this.days * 1 * 24) + (this.hours);

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  getValueLabel(): string {
    if (this.value != null) {
      return `Years: ${this.years}, Months: ${this.months}, Days: ${this.days}, Hours: ${this.hours}`;
    } else {
      return undefined;
    }
  }

}
