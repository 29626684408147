import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { CenterType } from 'app/shared/models';
import { SessionService } from 'app/shared/services/app';



@Component({
  selector: 'lc-select-center-type-member-dialog',
  templateUrl: './select-center-type-member.dialog.html',
  styleUrls: ['./select-center-type-member.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCenterTypeMemberDialog extends BaseDialog {

  webCenterTypes: CenterType[];

  webCenterTypeGuidId: string;

  filterSolutionType: WorkspaceSolutionType | any;

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.filterSolutionType = undefined;

    this.webCenterTypeGuidId = data?.webCenterTypeGuidId;
    this.webCenterTypes = data?.webCenterTypes;

    this.filterSolutionType = new WorkspaceSolutionType({
      baseSolutionTypeGuidId: this.webCenterTypeGuidId,
      selectedMember: data?.selectedMember
    } as any);
  }

  centerTypeChanged() {
    if (this.webCenterTypeGuidId) {
      this.filterSolutionType = new WorkspaceSolutionType({
        baseSolutionTypeGuidId: this.webCenterTypeGuidId,
        selectedMember: {
          guidId: undefined,
        }
      } as any);
    }
  }

  confirm() {
    this.close({
      memberTypeGuidId: this.filterSolutionType.selectedMember.subMembers[this.filterSolutionType.selectedMember.subLevels].fromTypeGuidId,
      selectedMember: this.filterSolutionType.selectedMember,
    });
  }

}
