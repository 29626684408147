import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { SelectItem } from 'primeng/api';



@Component({
  selector: 'new-solution-feature-dialog',
  templateUrl: './new-solution-feature.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSolutionFeatureDialog extends BaseDialog {

  defaultValueItems: SelectItem[];

  name: string;
  featureName: string;
  defaultValue: boolean;
  defaultComplexValue: string;

  solutionProfileGuidId: string;


  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);

    this.defaultValueItems = [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ];
  }

  init(data: any) {
    this.name = undefined;
    this.featureName = undefined;
    this.defaultValue = undefined;
    this.defaultComplexValue = undefined;

    this.solutionProfileGuidId = data.solutionProfileGuidId;
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.close({
        guidId: GuidUtils.new(),
        name: this.name,
        featureName: this.featureName,
        defaultValue: this.defaultValue,
        defaultComplexValue: this.defaultComplexValue,

        solutionProfileGuidId: this.solutionProfileGuidId,
      });
      form.reset();
    }
  }

}
