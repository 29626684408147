import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { FormFieldGeoJsonGeometryComponent } from './form-field-geojsongeometry.component';


@NgModule({
  declarations: [
    FormFieldGeoJsonGeometryComponent,
  ],
  exports: [
    FormFieldGeoJsonGeometryComponent,
  ],
  imports: [
    AppSharedV2Module,
  ],
})
export class FormFieldGeoJsonGeometryModule { }
