import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {

  transform(text: string, search): string {
    if (search && text != null) {
      search = search.replace(/\*/g, ''); // remove wildcards?...
      const pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      const regex = new RegExp(pattern, 'gi');

      return text.toString().replace(regex, (match) => { return `<em>${match}</em>`; });
    } else {
      return text;
    }
  }

}