import { Injectable } from '@angular/core';
import { SelectItemGroup } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiStudioService } from '../api/api-studio.service';


@Injectable({
  providedIn: 'root'
})
export class DeviceControlService {

  private urlSuffixPlaceholder = 'api/devicecontrol/{what}';

  constructor(private apiService: ApiStudioService) { }

  listAsSIG(): Observable<SelectItemGroup[]> {
    return this.apiService.post<SelectItemGroup[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      { }
    ).pipe(
      map((response: any) => {
        if (response && response.deviceControlGroups) {
          return this.buildSIGResponse(response.deviceControlGroups);
        } else {
          return [];
        }
      })
    );
  }

  private buildSIGResponse(groups: any[]): SelectItemGroup[] {
    const groupedOptions = [];

    for (const cg of groups) {
      const group = {
        value: cg.guidId,
        label: cg.name,
        icon: cg.icon,
        items: []
      };
      for (const dc of cg.deviceControls) {
        group.items.push({
          value: dc.guidId,
          label: dc.name,
          icon: dc.icon
        });
      }

      if (group.items.length) {
        groupedOptions.push(group);
      }
    }

    return groupedOptions;
  }

}
