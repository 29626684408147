import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api';


enum DaysOfWeek {
  None = 0, // All
  Sunday = 1 << 0,
  Monday = 1 << 1,
  Tuesday = 1 << 2,
  Wednesday = 1 << 3,
  Thursday = 1 << 4,
  Friday = 1 << 5,
  Saturday = 1 << 6,
}

@Component({
  selector: 'lc-form-field-days-of-week',
  templateUrl: 'form-field-days-of-week.component.html',
  styleUrls: ['./form-field-days-of-week.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldDaysOfWeekComponent),
    multi: true
  }],
})
export class FormFieldDaysOfWeekComponent implements ControlValueAccessor, OnInit {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;

  onChange: (x: any) => {};
  onTouched: () => {};

  multiSelectValue: number[];
  options: SelectItem[];
  optionLabel: string;
  value: number;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.options = EnumUtils.toSelectItemArray(DaysOfWeek);
    this.options.splice(0, 1);
  }


  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.multiSelectValue = this.getMultiSelectValue(this.value);

      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {

  }

  private getMultiSelectValue(value: number): number[] {
    const multiSelectValue = [];
    if (value) {
      if ((value & DaysOfWeek.Sunday) === DaysOfWeek.Sunday) {
        multiSelectValue.push(DaysOfWeek.Sunday);
      }
      if ((value & DaysOfWeek.Monday) === DaysOfWeek.Monday) {
        multiSelectValue.push(DaysOfWeek.Monday);
      }
      if ((value & DaysOfWeek.Tuesday) === DaysOfWeek.Tuesday) {
        multiSelectValue.push(DaysOfWeek.Tuesday);
      }
      if ((value & DaysOfWeek.Wednesday) === DaysOfWeek.Wednesday) {
        multiSelectValue.push(DaysOfWeek.Wednesday);
      }
      if ((value & DaysOfWeek.Thursday) === DaysOfWeek.Thursday) {
        multiSelectValue.push(DaysOfWeek.Thursday);
      }
      if ((value & DaysOfWeek.Friday) === DaysOfWeek.Friday) {
        multiSelectValue.push(DaysOfWeek.Friday);
      }
      if ((value & DaysOfWeek.Saturday) === DaysOfWeek.Saturday) {
        multiSelectValue.push(DaysOfWeek.Saturday);
      }
      return multiSelectValue;
    } else {
      return undefined;
    }
  }

  setValueFromMultiSelectFlagsValue(selectedValues: number[]) {
    this.value = undefined;
    for (const selectedValue of selectedValues || []) {
      this.value |= selectedValue;
    }

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

}
