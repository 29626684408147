import { SolutionType, SolutionTypeMember } from 'app/shared/models';
import { GuidUtils } from 'app/shared/utils';
import { Field, FieldType } from './field.model';



export class SolutionTypeField extends Field {

  baseSolutionTypeGuidId: string;
  valueSolutionTypeGuidId: string;
  valueSubMemberGuidId: string;

  constructor(field?: Field) {
    super(field);
    this.webFieldType = FieldType.SolutionType;
  }


  getValue(solutionTypes?: SolutionType[]): string {
    const solutionType = (solutionTypes || []).find((st: SolutionType) => {
      return st.guidId === this.valueSolutionTypeGuidId;
    });
    if (solutionType) {
      return solutionType.name;
    }
    return '';
  }

  getSubMemberValue(solutionTypes: SolutionType[]): string {
    if (this.valueSubMemberGuidId && this.valueSubMemberGuidId !== GuidUtils.emptyGuid()) {
      const solutionType = (solutionTypes || []).find((st: SolutionType) => {
        return st.guidId === this.valueSolutionTypeGuidId;
      });
      if (solutionType) {
        const member = (solutionType.members || []).find((m: SolutionTypeMember) => {
          return m.guidId === this.valueSubMemberGuidId;
        });
        if (member) {
          return member.displayName;
        }
      }
    }
    return ' ';
  }

}
