<p-dialog
  styleClass="edit-work-schedule-day-dialog"
  [header]="'Edit WorkScheduleDay' | translate"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <div *ngIf="data?.workScheduleDay"
    class="flex-col gap-2">
    <div class="form-field flex-col">
      <label class="label">
        {{ 'Start Time' | translate }}
      </label>

      <p-calendar
        appendTo="body"
        styleClass="calendar"
        hourFormat="24"
        [(ngModel)]="dayStartTime"
        [timeOnly]="true">
      </p-calendar>
    </div>

    <div class="form-field flex-col">
      <label class="label">
        {{ 'End Time' | translate }}
      </label>

      <p-calendar
        appendTo="body"
        styleClass="calendar"
        hourFormat="24"
        [(ngModel)]="dayEndTime"
        [timeOnly]="true">
      </p-calendar>
    </div>
  </div>

  <p-footer class="flex-row middle">
    <div class="flex-1"></div>

    <lc-button
      (click)="delete()"
      [label]="'Delete' | translate"
      tabindex="-1"
      type="danger">
    </lc-button>

    <lc-button
      (click)="dismiss()"
      [label]="'Cancel' | translate"
      tabindex="0"
      type="secondary">
    </lc-button>

    <lc-button
      (click)="confirm()"
      [disabled]="!data?.workScheduleDay?.dayStartTime || !data?.workScheduleDay?.dayEndTime"
      [label]="'Confirm' | translate"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>

<lc-confirm-dialog></lc-confirm-dialog>
