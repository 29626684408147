import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SmartImage } from '../../core/smart-image-admin/smart-image-admin-navigator-smartimage/models/smart-image.model';
import { SmartImageData } from '../models/smart-image-data.model';



@Injectable({
  providedIn: 'root'
})
export class SmartImageService {

  private urlSuffixPlaceholder = 'centerv2/smartimage/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  compile(smartImageGuidId: string): Observable<void> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'compile'),
      {
        smartImageGuidId: smartImageGuidId,
      }
    )
  }

  listGroup(smartImageGuidId: string): Observable<SmartImage[]> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'listgroup'),
      {
        smartImageGuidId: smartImageGuidId,
      }
    ).pipe(
      map((response: any) => {
        const result = [];
        for (const item of response?.web2Objects || []) {
          result.push(new SmartImage(item));
        }
        return result;
      })
    );
  }

  getSmartImageData(sourceGuidId: string, smartImageGuidId: string, dataTemplateSolutionGuidIds?: string[]): Observable<SmartImageData> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'data/smartimagedata'),
      {
        sourceGuidId: sourceGuidId,
        smartImageGuidId: smartImageGuidId,
        dataTemplateSolutionGuidIds: dataTemplateSolutionGuidIds,
      }
    ).pipe(
      map((response: any) => {
        return response?.smartImageData;
      })
    );
  }

}
