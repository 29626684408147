<div class="wrapper flex-col">
  <div *ngIf="filterStringFilter"
    class="flex-row"
    style="margin-bottom: .5rem;">
    <div class="item flex-1 flex-row middle">
      <div class="label">
        {{ label || ('Filter' | translate) }}
      </div>

      <div class="label bold">
        {{ baseTypeName }}
      </div>

      <div class="flex-1"></div>

      <div *ngIf="!flatFilterStringFilterGroups"
        class="loading">
        {{ 'Loading...' | translate }}
      </div>

      <lc-button *ngIf="flatFilterStringFilterGroups?.length && !locked"
        class="button-delete"
        (click)="clearFilter()"
        [label]="'Clear' | translate"
        type="clear">
      </lc-button>
    </div>
  </div>

  <div *ngFor="let filterGroup of flatFilterStringFilterGroups || []; let i = index;"
    style="position: relative; margin-bottom: .5rem;">

    <div class="flex-row item-wrapper"
      [class.floating]="dragIndex === i"
      [style]="dragIndex < i && dragIndex < onHoverIndex && onHoverIndex >= i ?
        'transform: translateY(calc(calc(56px + .5rem) * -1));' :
        dragIndex > i && dragIndex > onHoverIndex && onHoverIndex <= i ?
        'transform: translateY(calc(calc(56px + .5rem) * 1));' :
        ''
      "
      dragHandle=".move-handler"
      (onDragStart)="dragIndex = i"
      (onDragEnd)="dragIndex = undefined; onHoverIndex = undefined;"
      pDraggable="drag-item-scope">
      <div class="group-indicator"
        [class.start]="filterGroup.groupIndicatorStart"
        [class.middle]="filterGroup.groupIndicatorMiddle"
        [class.end]="filterGroup.groupIndicatorEnd">
      </div>

      <div [ngClass]="filterGroup.operator === 0 ? 'group-no-operator' : 'group-operator'">
        <lc-button class="button-operator"
          (click)="changeOperator(filterGroup)"
          [disabled]="filterGroup.operator === 0 || locked"
          [label]="filterGroup.operator === 1 ? ('and' | translate) : filterGroup.operator === 2 ? ('or' | translate) : ''"
          type="clear">
        </lc-button>
      </div>

      <div class="item flex-1 flex-row">
        <lc-button class="button-label"
          [disabled]="locked"
          (click)="editFilterCondition(filterGroup)"
          [pTooltip]="filterGroup.displayName"
          [label]="filterGroup.displayName"
          type="clear">
        </lc-button>

        <lc-form-field style="margin-right: .5rem"
          appendTo="body"
          [disabled]="locked"
          [editMode]="true"
          [options]="filterGroup.operatorItems"
          [(ngModel)]="filterGroup.filter.operator"
          (ngModelChange)="updateInternalModel()"
          type="dropdown">
        </lc-form-field>

        <lc-form-field class="flex-1"
          appendTo="body"
          [disabled]="disableFilterValues"
          [editMode]="true"
          [(ngModel)]="filterGroup.filter.value"
          (ngModelChange)="updateInternalModel()"
          [options]="filterGroup.filter?.member?.fieldOptions || []"
          [placeholder]="'value' | translate"
          [type]="filterGroup.fieldType">
        </lc-form-field>

        <i class="move-handler fas fa-arrows-alt"
          [class.active]="flatFilterStringFilterGroups?.length > 1">
        </i>

        <lc-button *ngIf="!locked"
          class="button-delete"
          (click)="removeFilterCondition(i)"
          icon="fas fa-times"
          type="clear">
        </lc-button>
      </div>
    </div>

    <div class="drop-zone"
      [class.active]="dragIndex != null"
      [class.hovering]="dropZoneMap[i]"
      (onDrop)="drop(dragIndex, i, $event)"
      (onDragEnter)="dropZoneMap[i] = true; onHoverIndex = i;"
      (onDragLeave)="dropZoneMap[i] = false"
      pDroppable="drag-item-scope">
    </div>
  </div>

  <div class="flex-row">
    <div class="group-indicator"></div>

    <div class="group-no-operator"></div>

    <lc-button *ngIf="solutionType || (webCenterTypes?.length && !locked)"
      class="button-add flex-1"
      (click)="addFilterCondition()"
      icon="fas fa-plus"
      [label]="'Add filter condition' | translate"
      [type]="buttonType">
    </lc-button>
  </div>

  <!-- <ngx-loading [show]="isBusy"></ngx-loading> -->
</div>

<lc-select-center-type-member-dialog></lc-select-center-type-member-dialog>
<lc-select-solution-type-member-dialog></lc-select-solution-type-member-dialog>