import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {

  private dragStartSubject: Subject<any>;
  private dragEndSubject: Subject<any>;
  private dropSubject: Subject<any>;
  private sourceItem: any;

  constructor() {
    this.dragStartSubject = new Subject();
    this.dragEndSubject = new Subject();
    this.dropSubject = new Subject();
  }

  dragStart(item: any) {
    this.sourceItem = item;

    this.dragStartSubject.next({
      source: this.sourceItem,
      target: null,
    });
  }

  dragEnd(item: any) {
    this.dragEndSubject.next({
      source: item,
      target: null,
    });
  }

  dropOnto(item: any) {
    if (this.sourceItem) {
      this.dropSubject.next({
        source: this.sourceItem,
        target: item,
      });
    }
    this.sourceItem = undefined;
  }

  dragStartListener(): Observable<any> {
    return this.dragStartSubject.asObservable();
  }

  dragEndListener(): Observable<any> {
    return this.dragEndSubject.asObservable();
  }

  dropListener(): Observable<any> {
    return this.dropSubject.asObservable();
  }
}