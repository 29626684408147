import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { PipesModule } from './pipes/pipes.module';


@NgModule({
  exports: [
    CardModule,
    CommonModule,
    DateFnsModule,
    FormsModule,
    PipesModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
  ],
  imports: [
    CardModule,
    CommonModule,
    DateFnsModule,
    FormsModule,
    PipesModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
  ],
})
export class AppSharedV2Module { }
