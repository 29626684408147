import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';



@Injectable({
  providedIn: 'root'
})
export class ApiCenterV2Service {

  private baseUrl: string;

  constructor(private apiService: ApiService) {
    this.setBaseUrl();
  }

  setBaseUrl(baseUrl?: string) {
    this.baseUrl = baseUrl || environment.apiCenterV2Url;
  }

  get<T>(endpoint: string): Observable<T> {
    let baseUrl = this.baseUrl;
    if (endpoint.indexOf('http://') === 0 || endpoint.indexOf('https://') === 0) {
      baseUrl = endpoint.substring(0, endpoint.slice(8).indexOf('/'));
      endpoint = endpoint.substring(endpoint.slice(8).indexOf('/') + 1);
    } else if (endpoint.indexOf('/') === 0) {
      endpoint = endpoint.substring(1);
    }
    return this.apiService.get(baseUrl, endpoint);
  }

  post<T>(endpoint: string, body: any, options?: any, dontHandleUnknownError?: boolean): Observable<T> {
    let baseUrl = this.baseUrl;
    if (endpoint.indexOf('http://') === 0 || endpoint.indexOf('https://') === 0) {
      baseUrl = endpoint.substring(0, endpoint.slice(8).indexOf('/'));
      endpoint = endpoint.substring(endpoint.slice(8).indexOf('/') + 1);
    } else if (endpoint.indexOf('/') === 0) {
      endpoint = endpoint.substring(1);
    }
    return this.apiService.post(baseUrl, endpoint, body, options, dontHandleUnknownError);
  }

}
