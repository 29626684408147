<div class="form-field"
  (click)="onTouched()">
  <label *ngIf="label"
    class="label"
    [innerHTML]="label">
  </label>

  <div *ngIf="!editMode"
    class="value">
    <div class="flex-row middle">
      <div *ngIf="iconLeft" [ngClass]="iconLeft"></div>

      <div [innerHTML]="value != null ? value : noValueHtml">
      </div>

      <div *ngIf="iconRight" [ngClass]="iconRight"></div>
    </div>
  </div>

  <div *ngIf="editMode">
    <p-dropdown
      [appendTo]="appendTo"
      styleClass="dropdown"
      panelStyleClass="dropdown-panel"
      [autoDisplayFirst]="false"
      [disabled]="disabled"
      [filter]="options?.length > 7"
      [optionLabel]="optionLabel"
      [options]="options"
      [placeholder]="!options ? ('Loading...' | translate) : placeholder"
      [(ngModel)]="value"
      (ngModelChange)="onChange(value)"
      [required]="required">
      <ng-template let-item pTemplate="selectedItem">
        <div class="flex-row middle">
          <div *ngIf="item.icon" [ngClass]="item.icon"></div>

          <div class="dropdown-label">
            {{ optionLabel ? (item.value ? item.value[optionLabel] : item[optionLabel]) : item.label }}
          </div>
        </div>
      </ng-template>

      <ng-template let-item pTemplate="item">
        <div class="flex-row middle"
          style="min-height: 2rem"
          [style.padding-left]="dropdownGroup ? '1rem': '0'">
          <div *ngIf="item.icon" [ngClass]="item.icon"></div>

          <div [class.ellipsis]="item.title || item.description">
            {{ optionLabel ? (item.value ? item.value[optionLabel] : item[optionLabel]) : item.label }}
          </div>
        </div>

        <div class="description"
          [innerHTML]="item.title || item.description || ''">
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
