import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Permission, UserSession } from '../../models';
import { AppService } from '../../services';
import { PermissionUtils } from '../../utils';


@Component({
  template: '<div></div>'
})
export abstract class BaseComponent implements OnDestroy {

  subscriptions: Subscription[];

  session: UserSession;
  permissions: Permission[];

  constructor(
    protected appService?: AppService,
  ) {
    this.subscriptions = [];

    this.updateThisSession();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  updateThisSession() {
    if (this.appService && this.appService.getSession) {
      this.session = this.appService.getSession();
      if (this.session && this.session.permissionProfile) {
        this.permissions = this.session.permissionProfile.permissions;
      } else {
        this.permissions = [];
      }
    }
  }

  hasReadAccess(path: string): boolean {
    return PermissionUtils.hasReadAccess(this.permissions, path) ||
           PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  hasWriteAccess(path: string): boolean {
    return PermissionUtils.hasWriteAccess(this.permissions, path);
  }

  getPermission(path: string): Permission {
    return PermissionUtils.getPermission(this.permissions, path);
  }

  getPermissionEnvironmentGuidId(path: string): string {
    const permission = PermissionUtils.getPermission(this.permissions, path);
    return permission ? permission.environmentGuidId : null;
  }

  unsubscribeAll() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });
      this.subscriptions.length = 0;
    }
  }
}

