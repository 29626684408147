export enum WebViewRelativeDateFilterType {
  Unknown = 0,
  Today = 1,
  CurrentWeek = 2,
  CurrentMonth = 3,
  CurrentYear = 4,
  LastXDays = 5,
  LastXWeeks = 6,
  LastXMonths = 7,
  LastXYears = 8,
}
