import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FilterStringFilter } from 'app/center-v2/shared/models';
import { GridUtils, JsonUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api';
import { FormFieldComponent } from '../../form-field/form-field.component';

@Component({
  selector: 'lc-grid-celleditor-filter-string-editor',
  templateUrl: 'grid-celleditor-filter-string-editor.component.html',
  styleUrls: ['grid-celleditor-filter-string-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorFilterStringEditor implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild(FormFieldComponent, { static: true }) formFieldComponent: FormFieldComponent;

  params: any;
  preValue: 'no' | 'yes' | 'on-condition';
  preValueItems: SelectItem[];

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.preValueItems = [
      { value: 'no', label: this.translateService.instant('No'), },
      { value: 'yes', label: this.translateService.instant('Yes'), },
      { value: 'on-condition', label: this.translateService.instant('On condition...'), },
    ];
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.formFieldComponent) return;

      this.formFieldComponent.disableKeysPropagation((event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.stopPropagation();

          this.params.api.tabToNextCell();
        }
      });

      this.formFieldComponent.tryToFocusInputElement();

      if (this.preValue === 'on-condition') return;

      this.formFieldComponent.showPicker();
      this.cdr.markForCheck();
    }, 10);
  }

  agInit(params): void {
    this.params = params;

    this.params.webCenterTypeGuidId = GridUtils.getParamValue(this.params, this.params.webCenterTypeGuidId);
    this.params.webCenterTypes = GridUtils.getParamValue(this.params, this.params.webCenterTypes);
    // this.params.value = this.params.value || new FilterStringFilter();

    this.preValue = this.params.value?.filterGroups?.length ? 'on-condition'
    : !!this.params.value ? 'yes'
    : 'no';

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams?.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  getPopupPosition(): 'over' | 'under' | undefined {
    return 'over';
  }

  preValueChanged(preValue: 'no' | 'yes' | 'on-condition') {
    if (preValue === 'no') {
      this.params.value = null;
    } else if (preValue === 'yes') {
      this.params.value = new FilterStringFilter();
    }
  }

}
