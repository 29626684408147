import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../../../shared/services/api/api.service';




@Injectable({
  providedIn: 'root'
})
export class ApiStudioService {

  private readonly studioVersion = 2;

  solutionGuidId: string;
  
  constructor(private apiService: ApiService) { }

  get<T>(endpoint: string): Observable<T> {
    return this.apiService.get(environment.apiStudioUrl, endpoint);
  }

  post<T>(endpoint: string, body: any): Observable<T> {
    if (this.solutionGuidId) {
      body = Object.assign(body, {
        solutionGuidId: this.solutionGuidId,
        studioVersion: this.studioVersion,
      });
    }
    return this.apiService.post(environment.apiStudioUrl, endpoint, body);
  }

}
