import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ButtonComponent } from './button.component';




@NgModule({
  declarations: [
    ButtonComponent,
  ],
  exports: [
    ButtonComponent,
  ],
  imports: [
    ButtonModule,
  ],
})
export class ButtonComponentModule { }
