  import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Dialog } from 'primeng/dialog';
import { Tree } from 'primeng/tree';
import { of } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { WorkspaceWidgetService } from './center-v2/shared/services';
import { WebSkin } from './center/shared/models';
import { BaseComponentV2 } from './shared/components/base/base-v2.component';
import { BaseDialog, InfoDialog } from './shared/dialogs';
import { AppService, CheckVersionService, LanguageTextsService, PluginLoaderService } from './shared/services';
import { SessionService } from './shared/services/app';
import { WebSkinUtils } from './shared/utils';
import { PrimeNGConfig } from 'primeng/api';


// HACK to fix https://github.com/primefaces/primeng/issues/8237 (and other older issue)
// TODO remove when this is fixed by primeng
Tree.prototype.isNodeLeaf = (node) => node.leaf;

// REMOVE ON FINAL primeng 9 (I HOPE)
Dialog.prototype.appendContainer = function() {
  if (this.appendTo) {
      if (this.appendTo === 'body')
        document.body.appendChild(this.wrapper); // document.body.appendChild(this.container);
      else {
        if (this.isElement(this.appendTo))
          this.appendTo.appendChild(this.wrapper);
        else if (this.appendTo.el && this.appendTo.el.nativeElement)
          this.appendTo.el.nativeElement.appendChild(this.wrapper);
      }
  }
}
Dialog.prototype.restoreAppend = function() {
  if (this.wrapper && this.appendTo) {
      this.el.nativeElement.appendChild(this.wrapper);
  }
}
Dialog.prototype.close = function(event: Event) {
  this.maskVisible = false;
  this.visible = false;
  this.visibleChange.emit(false);
  event.preventDefault();
}
Dialog.prototype.enableModality = function() {
  if (this.closable && this.dismissableMask) {
      this.maskClickListener = this.renderer.listen(this.wrapper, 'dblclick', (event: any) => {
          if (!this.container.isSameNode(event.target) && !this.container.contains(event.target)) {
              this.close(event);
          }
      });
  }

  if (this.modal) {
    if (document.body.classList) {
      document.body.classList.add('p-overflow-hidden');
    } else {
      document.body.className += ' ' + 'p-overflow-hidden';
    }
  }
}
Object.defineProperty(Dialog.prototype, 'visible', {
  set: function visible(value: boolean) {
    this._visible = value;
    this.maskVisible = value;
  }
});

function maskFormatValidator(masks: string | string[]) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (Array.isArray(masks)) {
      if (
        !(masks as string[]).find(
          (mask) => mask.length === control.value.length
        )
      ) {
        return { invalidLength: true };
      }
    } else {
      if (control.value.length !== masks.length) {
        return { invalidLength: true };
      }
    }

    return null;
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponentV2 implements OnInit {

  readonly sizeMD = 900;

  @ViewChild(InfoDialog, { static: true}) infoDialog: BaseDialog;

  currentUrl: string;
  isBusy: boolean;
  webSkin: WebSkin;
  windowWidth: number;

  constructor (
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private checkVersionService: CheckVersionService,
    private pluginLoaderService: PluginLoaderService,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    sessionService: SessionService,
    private titleService: Title,
    private translateService: TranslateService,
    private textService: LanguageTextsService,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    super(cdr, sessionService);

    setTimeout(() => {
      this.resize();
    }, 1);

    this.subscriptions.push(
      this.appService.listenToBusyChanges()
      .subscribe((isBusy: boolean) => {
        this.isBusy = isBusy;
        this.cdr.markForCheck();
      }),
      this.appService.listenToWebSkinChanges()
      .subscribe((webSkinGuidId: string) => {
        const webSkin = (WebSkinUtils.db || []).find((item: WebSkin) => {
          return item.guidId === webSkinGuidId;
        });

        this.webSkin = webSkin || WebSkinUtils.getWebSkinForCurrentUrl(window.location.href) || WebSkinUtils.DEFAULT_SKIN;
        this.applyWebSkinAndSetThemes();
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  protected resize(event?: Event) {
    this.windowWidth = window.innerWidth;

    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.checkVersionService.check();

    // document.onmouseover = function() {
    //   (window as any).innerDocClick = true; // User's mouse is inside the page.
    // }

    // document.onmouseleave = function() {
    //   (window as any).innerDocClick = false; // User's mouse has left the page.
    // }

    // window.addEventListener('popstate', (event) => {
    //   // The popstate event is fired each time when the current history entry changes.
    //   if (!(window as any).innerDocClick) {
    //     setTimeout(() => {
    //       this.navigationHistoryService.goBack();
    //     }, 10);
    //   }
    // }, false);

    this.primengConfig.setTranslation({
      firstDayOfWeek: 1,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su','Mo','Tu','We','Th','Fr','Sa'],
      monthNames: [
        this.translateService.instant('January'),
        this.translateService.instant('February'),
        this.translateService.instant('March'),
        this.translateService.instant('April'),
        this.translateService.instant('May'),
        this.translateService.instant('June'),
        this.translateService.instant('July'),
        this.translateService.instant('August'),
        this.translateService.instant('September'),
        this.translateService.instant('October'),
        this.translateService.instant('November'),
        this.translateService.instant('December'),
      ],
      monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'yy-mm-dd',
      weekHeader: 'Wk'
    });

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof RouteConfigLoadStart) { // lazy loaded route started
          this.appService.setBusy(true);
        } else if (event instanceof RouteConfigLoadEnd) { // lazy loaded route ended
          this.appService.setBusy(false);
        } else if (event instanceof NavigationStart) {
          this.currentUrl = event.url;

          this.loadPluginsIfNeeded(event.url);
        } else if (event instanceof NavigationEnd) {
          if (environment.production) {
            (window as any).ga('set', 'page', event.urlAfterRedirects);
            (window as any).ga('send', 'pageview');
          }

          this.currentUrl = event.urlAfterRedirects;

          this.updateThisSession(true);
        } else if (event instanceof NavigationError) {
          if (event.error && event.error.message && event.error.message.indexOf('Loading chunk') >= 0) {
            this.handleLoadingChunkError(event);
          }
        }
        this.cdr.markForCheck();
      })
    );

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en');
    this.textService.setDefaultLang('en');
  }

  protected refresh() {
    (this.webSkin ? of(this.webSkin) : this.sessionService.getWebSkin())
    .subscribe((webSkin: WebSkin) => {
      this.webSkin = webSkin;

      this.applyWebSkinAndSetThemes();
    });
  }

  private applyWebSkinAndSetThemes() {
    const workspace = this.workspaceWidgetService.getWorkspace();
    if (workspace) {
      this.titleService.setTitle('[LC] ' + workspace.name);
    } else {
      this.titleService.setTitle(this.webSkin.appTitle);
    }

    this.setThemes();

    this.textService.use(this.webSkin.language);
    this.translateService.use(this.webSkin.language).subscribe(() => {
      const languageJson = JSON.parse(workspace?.languageJson || '{}');
      for (const langKey of Object.keys(this.translateService.translations)) {
        if (!languageJson[langKey]) continue;

        Object.assign(this.translateService.translations[langKey], languageJson[langKey]);
      }
      this.cdr.markForCheck();
    });
  }

  private setThemes() {
    const htmlElement = document.documentElement;
    const url = this.currentUrl || '';
    const primeNgThemeName = 'nova';
    htmlElement.className = `theme-${primeNgThemeName} theme-${this.webSkin.theme}`;

    this.cdr.markForCheck();
  }

  private loadPluginsIfNeeded(url: string) {
    // USED ONLY ON V1
    if (
      url.indexOf('/center/forecast') === 0 ||
      url.indexOf('/center/gps-devices') === 0 ||
      url.indexOf('/center/preem-orders') === 0 ||
      url.indexOf('/center/tanks') === 0 ||
      url.indexOf('/center/tasks') === 0 ||
      url.indexOf('/center/topfuel-orders') === 0
    ) {
      this.pluginLoaderService.load('leaflet').subscribe();
    }
  }

  private handleLoadingChunkError(ne: NavigationError) {
    this.checkVersionService.check();

    this.appService.setBusy(false);
    this.infoDialog.show({
      title: 'Network Error',
      message: 'Sorry we were unable to load the page you were trying to visit.',
      buttonText: 'Retry',
    });
    this.infoDialog.onClose = () => {
      this.router.navigateByUrl(ne.url)
    };
    this.cdr.markForCheck();
  }

}
