import { Pipe, PipeTransform } from '@angular/core';
import { JsonUtils } from '../utils';

@Pipe({
  name: 'lcDeepFind',
  standalone: true,
})
export class DeepFindPipe implements PipeTransform {

  transform(obj: any, path: string): string {
    return JsonUtils.deepFind(obj, path);
  }
}
