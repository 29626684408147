import { GuidUtils } from 'app/shared/utils/guid.utils';
import { TypeTreeRelationEnum } from '../models/type-tree-relation.enum';
import { TypeEnum } from '../models/type.enum';


export class TypeUtils {
  static readonly STRUCTURAL_TYPE_GUIDS = [
    TypeEnum.Site,
    TypeEnum.Group,
    TypeEnum.User,
    TypeEnum.Address,
    TypeEnum.Permission,
  ];

  static readonly ROOTOBJECT_TYPE_GUIDS = [
    GuidUtils.emptyGuid(),
    TypeEnum.Group,
    TypeEnum.GpsDevice,
    TypeEnum.Permission,
    TypeEnum.ProductGroup,
    TypeEnum.Tank,
    TypeEnum.TopFuelTask,
    TypeEnum.User,
  ];

  static readonly GENERICGROUP_TYPE_GUIDS = [
    GuidUtils.emptyGuid(),
    TypeEnum.TopFuelTask,
    TypeEnum.ProductGroup,
  ];

  static readonly NON_GENERIC_TYPE_GUIDS = [
    TypeEnum.Site,
    TypeEnum.Group,
    TypeEnum.User,
    TypeEnum.Address,
    TypeEnum.Permission,
    TypeEnum.GpsDevice,
    TypeEnum.Tank,
    ...TypeUtils.GENERICGROUP_TYPE_GUIDS,
  ];

  static readonly VISIBLE_TYPE_GUIDS = [
    TypeEnum.Site,
    TypeEnum.Group,
    TypeEnum.User,
    TypeEnum.GpsDevice,
    TypeEnum.Tank,
    TypeEnum.ProductGroup,
    TypeEnum.Product,
  ];

  static getTypeTreeRelationGuidIdFromTypeGuidIds(parentNodeTypeGuidId: string, childNodetypeGuidId: string): string {
    let typeTreeRelationGuidId: string = undefined;
    switch (parentNodeTypeGuidId + childNodetypeGuidId) {
      case TypeEnum.Site + TypeEnum.Site:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SiteSite;
        break;
      case TypeEnum.Site + TypeEnum.User:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SiteUser;
        break;
      case TypeEnum.Site + TypeEnum.Group:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SiteGroup;
        break;
      case TypeEnum.Site + TypeEnum.Device:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SiteDevice;
        break;
      case TypeEnum.Site + TypeEnum.Tank:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SiteTank;
        break;
      case TypeEnum.Site + TypeEnum.Address:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SiteAddress;
        break;
      case TypeEnum.Site + TypeEnum.TopFuelTask:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TaskSite;
        break;


      case TypeEnum.User + TypeEnum.Address:
        typeTreeRelationGuidId = TypeTreeRelationEnum.UserAddress;
        break;
      case TypeEnum.User + TypeEnum.Permission:
        typeTreeRelationGuidId = TypeTreeRelationEnum.UserPermission;
        break;
      case TypeEnum.User + TypeEnum.Group:
        typeTreeRelationGuidId = TypeTreeRelationEnum.UserGroup;
        break;


      case TypeEnum.Group + TypeEnum.Permission:
        typeTreeRelationGuidId = TypeTreeRelationEnum.GroupPermission;
        break;


      case TypeEnum.Tank + TypeEnum.User:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TankOrderUser;
        break;
      case TypeEnum.Tank + TypeEnum.Address:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TankAddress;
        break;
      case TypeEnum.Tank + TypeEnum.TopFuelTask:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TaskTank;
        break;
      case TypeEnum.Tank + TypeEnum.TankModel:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TankTankModel;
        break;
      case TypeEnum.Tank + TypeEnum.Product:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TankProduct;
        break;
      case TypeEnum.Tank + TypeEnum.TopFuelService:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TankTopFuelService;
        break;



      case GuidUtils.emptyGuid() + TypeEnum.Group:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootGroup;
        break;
      case GuidUtils.emptyGuid() + TypeEnum.User:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootUser;
        break;
      case GuidUtils.emptyGuid() + TypeEnum.Permission:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootPermission;
        break;
      case GuidUtils.emptyGuid() + TypeEnum.GpsDevice:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootGpsDevice;
        break;
      case GuidUtils.emptyGuid() + TypeEnum.ProductGroup:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootProductGroup;
        break;
      case GuidUtils.emptyGuid() + TypeEnum.SolutionProfile:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootSolutionProfile;
        break;
      case GuidUtils.emptyGuid() + TypeEnum.TopFuelForecast:
        typeTreeRelationGuidId = TypeTreeRelationEnum.RootTopFuelForecast;
        break;


      case TypeEnum.ProductGroup + TypeEnum.Product:
        typeTreeRelationGuidId = TypeTreeRelationEnum.ProductGroupProduct;
        break;


      case TypeEnum.SolutionProfile + TypeEnum.SolutionSet:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SolutionProfileSolutionSet;
        break;

      case TypeEnum.SolutionSet + TypeEnum.RunEngine:
        typeTreeRelationGuidId = TypeTreeRelationEnum.SolutionSetRunEngine;
        break;

      case TypeEnum.TopFuelTask + TypeEnum.Site:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TaskSite;
        break;
      case TypeEnum.TopFuelTask + TypeEnum.Tank:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TaskTank;
        break;
      case TypeEnum.TopFuelTask + TypeEnum.User:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TaskUser;
        break;

      case TypeEnum.TopFuelTankForecast + TypeEnum.TopFuelForecast:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TopFuelTankForecastTopFuelForecast;
        break;

      case TypeEnum.TopFuelForecast + TypeEnum.Tank:
        typeTreeRelationGuidId = TypeTreeRelationEnum.TopFuelForecastTank;
        break;

      default:
        typeTreeRelationGuidId = GuidUtils.emptyGuid();
        break;
    }
    return typeTreeRelationGuidId;
  }

  static getIcon(typeGuidId: string): string {
    let iconStr: string = undefined;
    switch (typeGuidId) {
      case TypeEnum.Site:
        iconStr = 'fas fa-home';
        break;
      case TypeEnum.Group:
        iconStr = 'fas fa-object-group';
        break;
      case TypeEnum.User:
        iconStr = 'fas fa-user-circle';
        break;
      case TypeEnum.Device:
        iconStr = 'fas fa-tablet';
        break;
      case TypeEnum.GpsDevice:
        iconStr = 'fas fa-map-marker';
        break;
      case TypeEnum.Tank:
        iconStr = 'fas fa-battery-half';
        break;
      case TypeEnum.Address:
        iconStr = 'fas fa-address-card-o';
        break;
    }

    if (!iconStr) {
      if (TypeUtils.GENERICGROUP_TYPE_GUIDS.indexOf(typeGuidId) >= 0) {
        iconStr = 'fas fa-folder';
      } else if (TypeUtils.NON_GENERIC_TYPE_GUIDS.indexOf(typeGuidId) < 0) {
        iconStr = 'fas fa-file-o';
      }
    }
    return iconStr;
  }

  static getNewLabel(typeGuidId: string): string {
    let label: string = typeGuidId;
    switch (typeGuidId) {
      case TypeEnum.Site:
        label = '<New Site>';
        break;
      case TypeEnum.Group:
        label = '<New Group>';
        break;
      case TypeEnum.User:
        label = '<New User>';
        break;
      case TypeEnum.Device:
        label = '<New Device>';
        break;
      case TypeEnum.GpsDevice:
        label = '<New GPS Device>';
        break;
      case TypeEnum.Tank:
        label = '<New Tank>';
        break;
      case TypeEnum.Address:
        label = '<New Address>';
        break;
      case TypeEnum.Permission:
        label = '<New Permission>';
        break;
      case TypeEnum.ProductGroup:
        label = '<New ProductGroup>';
        break;
      case TypeEnum.Product:
        label = '<New Product>';
        break;
    }
    return label;
  }

}
