import { NgModule } from '@angular/core';
import { CleanGuidIdPipe } from './cleanguidid.pipe';
import { DateTimePipe } from './date-time.pipe';
import { DatePipe } from './date.pipe';
import { DeepFindPipe } from './deepfind.pipe';
import { HighlightPipe } from './highlight.pipe';
import { LocalISOStringPipe } from './localisostring.pipe';
import { TimePipe } from './time.pipe';
import { HasMoreThanOneLeafSitePipe } from './has-more-than-one-leaf-site.pipe';
import { CurrencyV2Pipe, DecimalV2Pipe, PercentV2Pipe } from './number.pipe';




@NgModule({
  exports: [
    CurrencyV2Pipe,
    DecimalV2Pipe,
    PercentV2Pipe,

    CleanGuidIdPipe,
    DatePipe,
    DateTimePipe,
    DeepFindPipe,
    HasMoreThanOneLeafSitePipe,
    HighlightPipe,
    LocalISOStringPipe,
    TimePipe,
  ],
  imports: [
    CurrencyV2Pipe,
    DecimalV2Pipe,
    PercentV2Pipe,

    CleanGuidIdPipe,
    DatePipe,
    DateTimePipe,
    DeepFindPipe,
    HasMoreThanOneLeafSitePipe,
    HighlightPipe,
    LocalISOStringPipe,
    TimePipe,
  ],
})
export class PipesModule { }
