import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WebCenterType, Workspace, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { WorkspaceService, WorkspaceWidgetService } from 'app/center-v2/shared/services';
import { BaseComponentV2 } from 'app/shared/components/base/base-v2.component';
import { BaseDialog } from 'app/shared/dialogs';
import { CenterType, SolutionType, SolutionTypeMember } from 'app/shared/models';
import { SolutionProfileService } from 'app/shared/services';
import { SessionService } from 'app/shared/services/app';
import { FilterStringFilterGroupOperator } from '../../models/filter-string/filter-string-filter-group-operator.enum';
import { FilterStringFilterGroup } from '../../models/filter-string/filter-string-filter-group.model';
import { FilterStringFilter } from '../../models/filter-string/filter-string-filter.model';
import { FlatUiFilterStringFilterGroup } from '../../models/filter-string/flat-ui-filter-string-filter-group.model';
import { ObjectMemberValueFilter } from '../../models/filter-string/object-member-value-filter.model';
import { SelectCenterTypeMemberDialog } from './dialogs/select-center-type-member/select-center-type-member.dialog';
import { SelectSolutionTypeMemberDialog } from './dialogs/select-solution-type-member/select-solution-type-member.dialog';


@Component({
  selector: 'lc-filter-string-editor',
  templateUrl: './filter-string-editor.component.html',
  styleUrls: ['./filter-string-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterStringEditorComponent extends BaseComponentV2 {

  @ViewChild(SelectCenterTypeMemberDialog) selectCenterTypeMemberDialog: BaseDialog;
  @ViewChild(SelectSolutionTypeMemberDialog) selectSolutionTypeMemberDialog: BaseDialog;

  @Input() buttonType: string = 'primary';
  @Input() disableFilterValues: boolean;
  @Input() label: string;
  @Input() locked: boolean;
  @Input() solutionType: SolutionType | WorkspaceSolutionType;
  @Input() webCenterTypes: CenterType[] | WebCenterType[];
  @Input() set filterStringFilter(value: FilterStringFilter) {
    this._filterStringFilter = value;

    setTimeout(() => {
      this.refresh();
    }, 1);
  }
  get filterStringFilter(): FilterStringFilter {
    return this._filterStringFilter;
  }
  private _filterStringFilter: FilterStringFilter;
  @Input() typeGuidId: string;
  @Output() filterStringFilterChange = new EventEmitter<FilterStringFilter>();

  baseTypeName: string;
  dragIndex: number;
  dropZoneMap: any;
  isBusy: boolean;
  isRefreshing: boolean;
  onHoverIndex: number;

  flatFilterStringFilterGroups: FlatUiFilterStringFilterGroup[];

  workspace: Workspace;

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
    private workspaceService: WorkspaceService,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    super(cdr, sessionService);

    this.dropZoneMap = {};
  }

  refresh() {
    this._filterStringFilter = this.filterStringFilter || new FilterStringFilter({ isGroup: true });

    if (this.isRefreshing) return;

    this.isRefreshing = true;
    this.workspace = this.workspaceWidgetService.getWorkspace();

    if (this.solutionType) {
      this.baseTypeName = this.solutionType.name;
      this.typeGuidId = this.solutionType.baseSolutionTypeGuidId;

      this.convertToFlatUiStructure();

      this.isRefreshing = false;
      this.cdr.markForCheck();
    } else if (this.webCenterTypes?.length) {
      this.baseTypeName = (this.webCenterTypes as WebCenterType[]).find((ct: WebCenterType) => {
        return ct.typeGuidId === this.typeGuidId;
      })?.name;

      this.convertToFlatUiStructure();

      this.isRefreshing = false;
      this.cdr.markForCheck();
    } else {
      this.workspaceService.listCenterTypes(this.workspace.guidId)
      .subscribe((webCenterTypes: WebCenterType[]) => {
        this.webCenterTypes = webCenterTypes || [];

        this.baseTypeName = this.webCenterTypes.find((ct: WebCenterType) => {
          return ct.typeGuidId === this.typeGuidId;
        })?.name;

        this.convertToFlatUiStructure();

        this.isRefreshing = false;
        this.cdr.markForCheck();
      });
    }
  }

  private convertFromFlatUiStructure() {
    this.filterStringFilter.filterGroups.length = 0;

    const complexFilter = (this.flatFilterStringFilterGroups || []).some((fg: FlatUiFilterStringFilterGroup) => {
      return fg.operator === FilterStringFilterGroupOperator.And;
    }) && (this.flatFilterStringFilterGroups || []).some((fg: FlatUiFilterStringFilterGroup) => {
      return fg.operator === FilterStringFilterGroupOperator.Or;
    });
    if (complexFilter) {
      const firstOperator = this.flatFilterStringFilterGroups.length > 1 ? this.flatFilterStringFilterGroups[1].operator : FilterStringFilterGroupOperator.None;
      const groupOperator = firstOperator === FilterStringFilterGroupOperator.And ? FilterStringFilterGroupOperator.Or : FilterStringFilterGroupOperator.And;
      let currentFilterGroup = new FilterStringFilterGroup({ operator: groupOperator });
      this.filterStringFilter.filterGroups = [currentFilterGroup];
      for (const flatFilterGroup of this.flatFilterStringFilterGroups || []) {
        if (flatFilterGroup.operator !== FilterStringFilterGroupOperator.None && flatFilterGroup.operator !== firstOperator) {
          currentFilterGroup = new FilterStringFilterGroup({ operator: groupOperator });
          this.filterStringFilter.filterGroups.push(currentFilterGroup);
        }

        currentFilterGroup.filter.isGroup = true;
        const filterGroup = FlatUiFilterStringFilterGroup.toFilterStringFilterGroup(flatFilterGroup);
        filterGroup.operator = firstOperator;
        currentFilterGroup.filter.filterGroups.push(filterGroup);
      }
    } else {
      for (const flatFilterGroup of this.flatFilterStringFilterGroups || []) {
        const filterGroup = FlatUiFilterStringFilterGroup.toFilterStringFilterGroup(flatFilterGroup);
        this.filterStringFilter.filterGroups.push(filterGroup);
      }
    }

    this.filterStringFilterChange.emit(this.filterStringFilter.filterGroups.length ? this.filterStringFilter : null);
  }

  private convertToFlatUiStructure() {
    let firstFilterGroup = true;
    this.flatFilterStringFilterGroups = [];
    for (const filterGroup of this.filterStringFilter?.filterGroups || []) {
      if (filterGroup.filter?.isGroup) {
        let firstChildFilterGroup = true;
        for (const childFilterGroup of filterGroup.filter?.filterGroups || []) {
          if (firstFilterGroup && firstChildFilterGroup) {
            childFilterGroup.operator = FilterStringFilterGroupOperator.None;
          } else if (!firstFilterGroup && firstChildFilterGroup) {
            childFilterGroup.operator = filterGroup.operator;
          }

          const flatFilterCondition = FlatUiFilterStringFilterGroup.fromFilterStringFilterGroup(childFilterGroup);
          this.flatFilterStringFilterGroups.push(flatFilterCondition);

          firstChildFilterGroup = false;
        }
      } else {
        if (firstFilterGroup) {
          filterGroup.operator = FilterStringFilterGroupOperator.None;
        } else {
          filterGroup.operator = filterGroup.operator === FilterStringFilterGroupOperator.None ? FilterStringFilterGroupOperator.And : filterGroup.operator;
        }

        const flatFilterCondition = FlatUiFilterStringFilterGroup.fromFilterStringFilterGroup(filterGroup);
        this.flatFilterStringFilterGroups.push(flatFilterCondition);
      }
      firstFilterGroup = false;
    }

    this.cdr.markForCheck();
  }

  clearFilter() {
    this.filterStringFilter.filterGroups.length = 0;
    this.flatFilterStringFilterGroups = [];

    this.filterStringFilterChange.emit(this.filterStringFilter.filterGroups.length ? this.filterStringFilter : null);
    this.cdr.markForCheck();
  }

  addFilterCondition() {
    let dialog;
    if (this.solutionType) {
      dialog = this.selectSolutionTypeMemberDialog;
      this.selectSolutionTypeMemberDialog.show({
        solutionType: this.solutionType,
        selectedMember: undefined,
        webCenterTypes: this.webCenterTypes,
      });
    } else {
      dialog = this.selectCenterTypeMemberDialog;
      this.selectCenterTypeMemberDialog.show({
        webCenterTypeGuidId: this.typeGuidId,
        webCenterTypes: this.webCenterTypes,
      });
    }

    dialog.onClose = (response: any) => {
      if (!response) return;

      this.isBusy = true;
      this.cdr.markForCheck();

      setTimeout(() => {
        // since this is only for filtering, we don't want any option disabled
        for (const option of (response.selectedMember as SolutionTypeMember).fieldOptions || []) {
          delete option.disabled;
        }

        this.flatFilterStringFilterGroups.push(
          FlatUiFilterStringFilterGroup.fromFilterStringFilterGroup({
            filter: new ObjectMemberValueFilter({
              memberTypeGuidId: response.memberTypeGuidId,
              member: response.selectedMember,
            }),
          } as any)
        );

        this.updateOperatorsAndGroupIndicators();
      });
    }
  }

  editFilterCondition(filterGroup: FlatUiFilterStringFilterGroup) {
    let dialog;
    if (this.solutionType) {
      dialog = this.selectSolutionTypeMemberDialog;
      this.selectSolutionTypeMemberDialog.show({
        solutionType: this.solutionType,
        selectedMember: (filterGroup.filter as ObjectMemberValueFilter).member,
        webCenterTypes: this.webCenterTypes,
      });
    } else {
      dialog = this.selectCenterTypeMemberDialog;
      this.selectCenterTypeMemberDialog.show({
        webCenterTypeGuidId: this.typeGuidId,
        webCenterTypes: this.webCenterTypes,
        selectedMember: (filterGroup.filter as ObjectMemberValueFilter).member,
      });
    }

    dialog.onClose = (response: any) => {
      if (!response) return;

      this.isBusy = true;
      this.cdr.markForCheck();

      setTimeout(() => {
        // since this is only for filtering, we don't want any option disabled
        for (const option of (response.selectedMember as SolutionTypeMember).fieldOptions || []) {
          delete option.disabled;
        }

        Object.assign(
          filterGroup,
          FlatUiFilterStringFilterGroup.fromFilterStringFilterGroup({
            filter: new ObjectMemberValueFilter({
              memberTypeGuidId: response.memberTypeGuidId,
              member: response.selectedMember,
            }),
          } as any)
        );

        this.updateOperatorsAndGroupIndicators();
      });
    }
  }

  removeFilterCondition(index: number) {
    this.isBusy = true;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.flatFilterStringFilterGroups.splice(index, 1);

      this.updateOperatorsAndGroupIndicators();
    });
  }

  changeOperator(filterGroup: FilterStringFilterGroup) {
    this.isBusy = true;
    this.cdr.markForCheck();

    setTimeout(() => {
      filterGroup.operator = filterGroup.operator === 1 ? 2 : 1;

      this.updateOperatorsAndGroupIndicators();
    });
  }

  drop(dragIndex: number, dropIndex: number, ev: any) {
    this.isBusy = true;
    this.cdr.markForCheck();

    setTimeout(() => {
      const dragItems = this.flatFilterStringFilterGroups.splice(dragIndex, 1);
      this.flatFilterStringFilterGroups.splice(dropIndex, 0, dragItems[0]);

      this.dropZoneMap = {};
      this.dragIndex = undefined;
      this.onHoverIndex = undefined;

      this.updateOperatorsAndGroupIndicators();
    });
  }

  private updateOperatorsAndGroupIndicators() {
    for (let i = 0; i < this.flatFilterStringFilterGroups.length; i++) {
      const filterGroup = this.flatFilterStringFilterGroups[i];
      if (i === 0) {
        filterGroup.operator = FilterStringFilterGroupOperator.None;
      } else {
        filterGroup.operator = filterGroup.operator === FilterStringFilterGroupOperator.None ? FilterStringFilterGroupOperator.And : filterGroup.operator;
      }
    }

    const complexFilter = (this.flatFilterStringFilterGroups || []).some((fg: FlatUiFilterStringFilterGroup) => {
      return fg.operator === FilterStringFilterGroupOperator.And;
    }) && (this.flatFilterStringFilterGroups || []).some((fg: FlatUiFilterStringFilterGroup) => {
      return fg.operator === FilterStringFilterGroupOperator.Or;
    });

    const firstOperator = this.flatFilterStringFilterGroups.length > 1 ? this.flatFilterStringFilterGroups[1].operator : FilterStringFilterGroupOperator.None;
    for (let i = 0; i < this.flatFilterStringFilterGroups.length; i++) {
      const filterGroup = this.flatFilterStringFilterGroups[i];
      filterGroup.groupIndicatorStart = complexFilter && (i === 0 || filterGroup.operator !== firstOperator);
      filterGroup.groupIndicatorEnd = complexFilter && (i === this.flatFilterStringFilterGroups.length - 1 || this.flatFilterStringFilterGroups[i + 1].operator !== firstOperator);
      filterGroup.groupIndicatorMiddle = complexFilter && !filterGroup.groupIndicatorStart && !filterGroup.groupIndicatorEnd;
    }

    this.updateInternalModel();
    this.cdr.markForCheck();
  }

  updateInternalModel() {
    setTimeout(() => {
      this.convertFromFlatUiStructure();
      console.log(JSON.stringify(this.filterStringFilter, null, 2));

      this.isBusy = false;
      this.cdr.markForCheck();
    }, 10);
  }
}
