import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowAffectedResponse, FlowModule, NewModuleRequest, SolutionNotify, VariableToolbox } from '../../models';
import { FieldUtils } from '../../utils';
import { ApiStudioService } from '../api/api-studio.service';



@Injectable({
  providedIn: 'root'
})
export class SolutionNotifyService {

  private urlSuffixPlaceholder = 'api/notify/{what}';

  constructor(private apiService: ApiStudioService) { }

  get(notifyGuidId: string): Observable<SolutionNotify> {
    return this.apiService.post<SolutionNotify>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
    {
      notifyGuidId: notifyGuidId,
    })
    .pipe(
      map((response: any) => {
        const sn = new SolutionNotify(response.notify);
        sn.fields = FieldUtils.castFields(sn.fields);
        sn.$variableToolbox = new VariableToolbox(response.variableToolbox);
        return sn;
      })
    );
  }

  newModule(newRequest: NewModuleRequest): Observable<FlowAffectedResponse> {
    return this.apiService.post<FlowAffectedResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'newmodule'),
      newRequest
    )
    .pipe(
      map((far: FlowAffectedResponse) => {
        if (far) {
          far = new FlowAffectedResponse(far);
          far.sourceObject = new FlowModule(far.sourceObject);
        }
        return far;
      })
    );
  }

}
